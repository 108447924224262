import { ScheduleTracker } from '../../Services/Object_Classes/Machine/Machine';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { OrdersComponent } from '../raw/PO/orders/orders.component';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { PackingList_PO_List, PackingList, PackingList_PO_Part_List } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AddPackingComponent } from '../invoice/add-packing/add-packing.component';
import { PackingListDB_controller } from 'src/app/Services/DB_Controller/PackingListDB_controller';
import { GenerateOldLabelComponent } from '../invoice/generate-old-label/generate-old-label.component';
import { GenerateQRCodeDialogComponent } from '../invoice/generate-qrcode-dialog/generate-qrcode-dialog.component';
import { UpdatePackingComponent } from '../invoice/update-packing/update-packing.component';

@Component({
  selector: 'app-purchaseOrder',
  templateUrl: './manufacturing.component.html',
  styleUrls: ['./manufacturing.component.css']
})
export class ManufacturingComponent implements OnInit {
  POno: string;
  partNo: string;
  POquantity: number;
  CustomerDB_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  email: string;
  JOList: PartTracker[] = [];

  POlist: PurchaseOrder[] = [];
  ClonePOList: PurchaseOrder[] = [];
  IncompletePOList: PurchaseOrder[] = [];
  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  //Pagination Customer
  lengthcus = 0;
  pageSizecus  = 10;
  pageSizeOptionscus : number[] = [10, 25, 50];
  pageIndexcus  = 0;
  offsetcus  = this.pageSizecus  * this.pageIndexcus ;
  searchcus : string;
  sorteducus  = {
    active: '',
    direction: ''
  };
  Customer: Customer[] = [];
  CloneCustomer: Customer[] = [];
  @ViewChild('topPaginatorcus', { read: MatPaginator, static: false }) topPaginatorcus : MatPaginator;
  @ViewChild('bottomPaginatorcus', { read: MatPaginator, static: false }) bottomPaginatorcus : MatPaginator;


  //Pagination Machine job
  lengthJob = 0;
  pageSizeJob  = 10;
  pageSizeOptionsJob : number[] = [10, 25, 50];
  pageIndexJob  = 0;
  offsetJob  = this.pageSizeJob  * this.pageIndexJob ;
  searchJob : string;
  sorteduJob  = {
    active: '',
    direction: ''
  };
  Job: ScheduleTracker[] = [];
  CloneJob: ScheduleTracker[] = [];
  @ViewChild('topPaginatorJob', { read: MatPaginator, static: false }) topPaginatorJob : MatPaginator;
  @ViewChild('bottomPaginatorJob', { read: MatPaginator, static: false }) bottomPaginatorJob : MatPaginator;


  PODB_controller: PODB_controller = new PODB_controller(this.db);
  machineDB_controller = new MachineDB_controller(this.db);

  machinelist = []
  role:string;
  acccessRight:any[]=[];
  poStatus: string = "";

  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private angularFireAuth: AngularFireAuth,
    private excelHelper: ExcelHelperService,
    private firestore: AngularFirestore,
    private userinfoSrv:UserInfoService,

  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });
    this.userinfoSrv.currentAccessRights.subscribe(access=>{
      this.acccessRight = access
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });
  }
  disableDelete = true;

  check(PO: PurchaseOrder){
    const result = PO.PO_Part_List.find(p=>p.Added_TO_Packing===true)
    if(result ){
      return true;
    }
    else{
      return false;
    }
  }
  ngOnInit() {
    this.setup();
  }

  refresh(){
    this.setup();
  }
  
  setup(){
    this.spinner.show();
    this.PODB_controller.getJOList().then(data => {
      this.JOList = data;

      this.JOList.sort((a,b)=>{
        return new Date(a.EndDate).getTime() < new Date(b.EndDate).getTime()? -1 : new Date(a.EndDate).getTime() > new Date(b.EndDate).getTime() ? 1 :0
      });

      this.spinner.hide();
    });
  }

  initcus(){
    this.CustomerDB_controller.getCustomerList().then(data => {
      this.Customer = data;
      this.lengthcus = this.Customer.length;
      this.CloneCustomer = this.Customer.slice();
      this.limitListcus();
      this.spinner.hide();
    });
  }

  initJob(){

    this.machineDB_controller.getMachineListForCompletedJob().then(data=>{
      this.Job = data;
      this.lengthJob = this.Job.length;
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      this.spinner.hide();
    })
  }

  deletePO(POnumber) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this PO? This will affect the schedule';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PODB_controller.delete_PO(POnumber, this.machinelist);
        this.setup();
      }
    })

  }

  filterStatus(){
    if(this.search)
      this.DS();
    else
      this.ClonePOList = this.POlist.slice();

    this.ClonePOList =  this.ClonePOList.filter(u =>{
      for (const part of u.PO_Part_List) {
          if((part.PO_Status.includes(this.poStatus)))
            return true;
      }
      return false
    });
    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage()
    this.bottomPaginator.firstPage()

  }

  fs(){
    this.ClonePOList =  this.ClonePOList.filter(u =>{
      for (const part of u.PO_Part_List) {
          if((part.PO_Status.includes(this.poStatus)))
            return true;
      }
      return false
    });
  }

  DynamicSearchPO(partNum: string): void {

    this.search = partNum;
    this.ClonePOList = this.POlist.filter(u =>{
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No && part.PO_Part_Name)
        if((part.PO_Part_No.toLowerCase().includes(this.search.toLowerCase())|| (part.PO_Part_Name.toLowerCase().includes(this.search.toLowerCase()))))
            return true;
      }
      return flag

    });
    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage()
    this.bottomPaginator.firstPage()
  }
  DS() {
    this.ClonePOList = this.POlist.filter(u =>{
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No && part.PO_Part_Name)
        if((part.PO_Part_No.toLowerCase().includes(this.search.toLowerCase())
        || (part.PO_Part_Name.toLowerCase().includes(this.search.toLowerCase()))))
            return true;
      }
      return flag

    });
    this.length = this.ClonePOList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.ClonePOList = this.POlist.slice();
    }
    this.fs();
    this.sortPOList();
    this.limitList();
  }
  limitList() {
    this.ClonePOList = this.ClonePOList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortPOList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePOList = this.POlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      return;
    }

    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
         default: return 0;
      }
    });
    this.limitList();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewOrder(po){
    this.dialog.open(OrdersComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  DynamicSearchRawcus(cusname: string): void {
    this.searchcus = cusname;
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.Customer_Name).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
    this.sortRawListcus();
    this.limitListcus();
    this.topPaginatorcus.firstPage()
    this.bottomPaginatorcus.firstPage()
  }
  DScus() {
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.Customer_Name).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
  }

  paginatorcus(pageEvent: PageEvent) {
    this.pageSizecus = pageEvent.pageSize;
    this.pageIndexcus = pageEvent.pageIndex;
    this.offsetcus = this.pageSizecus * this.pageIndexcus;
    if (this.topPaginatorcus.pageIndex < this.pageIndexcus) {
      this.topPaginatorcus.nextPage();
    } else if (this.topPaginatorcus.pageIndex > this.pageIndexcus) {
      this.topPaginatorcus.previousPage();
    }
    if (this.bottomPaginatorcus.pageIndex < this.pageIndexcus) {
      this.bottomPaginatorcus.nextPage();
    } else if (this.bottomPaginatorcus.pageIndex > this.pageIndexcus) {
      this.bottomPaginatorcus.previousPage();
    }
    if (this.searchcus) {
      this.DScus();
    } else {
      this.CloneCustomer = this.Customer.slice();
    }
    this.sortRawListcus();
    this.limitListcus();
  }
  limitListcus() {
    this.CloneCustomer = this.CloneCustomer.slice(this.offsetcus, (this.offsetcus + this.pageSizecus));
  }
  sortRawListcus() {
    if (!this.sorteducus.active || this.sorteducus.direction === '') {
      return;
    }
    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.Customer_Name, b.Customer_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
  }
  sortDatacus(sort: Sort) {
    this.sorteducus = sort;
    this.CloneCustomer = this.Customer.slice();
    if (this.searchcus) {
      this.DScus();
    }
    if (!sort.active || sort.direction === '' && !this.searchcus) {
      this.CloneCustomer = this.Customer.slice();
      this.limitListcus();
      return;
    }

    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.Customer_Name, b.Customer_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
    this.limitListcus();
  }

  deletecus(cus : Customer){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this Customer? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.delete_Customer(cus.Customer_ID);
        this.setup();
      }
    })
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();

    for (const po of this.POlist) {
      const info ={
        "PO":po.PO_No || "-",
        "Part List": po.PO_Part_List.map(a=>a.PO_Part_No).join('  |  '),
        "Part List Name": po.PO_Part_List.map(a=>a.PO_Part_Name).join(' |  '),
        "Production Quantity": po.PO_Part_List.map(a=>a.PO_Part_Qty).join(' |  '),
        "PO Quantity": po.PO_Part_List.map(a=>a.POQuantity).join(' |  '),
        "Reference": po.PO_Part_List.map(a=>a.Reference).join(' |  '),
        "POS": po.PO_Part_List.map(a=>a.POS).join(' |  '),
        "Status": po.PO_Part_List.map(a=>a.PO_Status).join(' |  '),
        "Created Date":po.Created_Date
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();

  }

  exportToExcelCustomer() {
    const exportInformation = [];
    this.spinner.show();
    const cuss = this.Customer.sort((a,b)=>{
      return (a.Customer_Name < b.Customer_Name ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const cus of cuss) {
      const info ={
        "Client name":cus.Customer_Name || "-",
        "Address":cus.Address || "-",
        "Contact":cus.Contact || "-",
        "Email":cus.Email || "-",
        "Person In Charge":cus.Person_In_Charge || "-",
        "Created Date":cus.Created_Date || "-",
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client'+new Date().getTime());
    this.spinner.hide();

  }

  msToTime(end,start) {
    let duration = parseFloat(end)-parseFloat(start);
    if(duration > 0){
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hrs = (hours < 10) ? "0" + hours : hours;
      let min = (minutes < 10) ? "0" + minutes : minutes;
      let sec = (seconds < 10) ? "0" + seconds : seconds;
      return hrs + ":" + min + ":" + sec;
    }
    return 0;

  }

  hoursDisplay(hr){
    if(hr > 0){
      let min = hr - parseFloat(hr.toString().split('.')[0])
      min = min * 60
      let sec = min - parseFloat(min.toString().split('.')[0])
      sec = sec * 60 ;
      let hrs1 = (hr < 10) ? "0" + hr.toString().split('.')[0] : hr.toString().split('.')[0];
      let min1 = (min < 10) ? "0" + min.toString().split('.')[0] : min.toString().split('.')[0];
      let sec1 = (sec < 10) ? "0" + sec.toString().split('.')[0] : sec.toString().split('.')[0];
      return hrs1 + ":" + min1 + ":" + sec1;
    }
    return 0;
  }

  DynamicSearchJob(job: string): void {
    this.searchJob = job;
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.PartName).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_Part_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.CycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
      );
    this.lengthJob = this.CloneJob.length;
    this.sortJob();
    this.limitListJob();
    this.topPaginatorJob.firstPage()
    this.bottomPaginatorJob.firstPage()
  }
  DSJob() {
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.PartName).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Machine_Schedule_Part_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.CycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
      );
      this.lengthJob = this.CloneJob.length;

  }

  paginatorJob(pageEvent: PageEvent) {
    this.pageSizeJob = pageEvent.pageSize;
    this.pageIndexJob = pageEvent.pageIndex;
    this.offsetJob = this.pageSizeJob * this.pageIndexJob;
    if (this.topPaginatorJob.pageIndex < this.pageIndexJob) {
      this.topPaginatorJob.nextPage();
    } else if (this.topPaginatorJob.pageIndex > this.pageIndexJob) {
      this.topPaginatorJob.previousPage();
    }
    if (this.bottomPaginatorJob.pageIndex < this.pageIndexJob) {
      this.bottomPaginatorJob.nextPage();
    } else if (this.bottomPaginatorJob.pageIndex > this.pageIndexJob) {
      this.bottomPaginatorJob.previousPage();
    }
    if (this.searchJob) {
      this.DSJob();
    } else {
      this.CloneJob = this.Job.slice();
    }
    this.sortJob();
    this.limitListJob();
  }
  limitListJob() {
    this.CloneJob = this.CloneJob.slice(this.offsetJob, (this.offsetJob + this.pageSizeJob));
  }
  sortJob() {
    if (!this.sorteduJob.active || this.sorteduJob.direction === '') {
      return;
    }
    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'PredefinedCycle': return this.compare(a.CycleTime, b.CycleTime, isAsc);
        case 'startTime': return this.compareDate(a.DateFormatStart, b.DateFormatStart, isAsc);
        case 'endTime': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        case 'presetTime': return this.compare(parseFloat(a.DatePresettingEnd)-parseFloat(a.DatePresettingStart),parseFloat(b.DatePresettingEnd)-parseFloat(b.DatePresettingStart), isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
        default: return 0;
      }
    });
  }
  sortDataJob(sort: Sort) {
    this.sorteduJob = sort;
    this.CloneJob = this.Job.slice();
    if (this.searchJob) {
      this.DSJob();
    }
    if (!sort.active || sort.direction === '' && !this.searchJob) {
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      return;
    }

    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.Machine_Schedule_PO_No, b.Machine_Schedule_PO_No, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.Machine_Schedule_Part_No, b.Machine_Schedule_Part_No, isAsc);
        case 'partName': return this.compare(a.PartName, b.PartName, isAsc);
        case 'PredefinedCycle': return this.compare(a.CycleTime, b.CycleTime, isAsc);
        case 'startTime': return this.compareDate(a.DateFormatStart, b.DateFormatStart, isAsc);
        case 'endTime': return this.compareDate(a.DateFormatEnd, b.DateFormatEnd, isAsc);
        case 'presetTime': return this.compare(parseFloat(a.DatePresettingEnd)-parseFloat(a.DatePresettingStart),parseFloat(b.DatePresettingEnd)-parseFloat(b.DatePresettingStart), isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
         default: return 0;
      }
    });
    this.limitListJob();
  }

  exportToExcelJob() {
    const exportInformation = [];
    this.spinner.show();
    const jo = this.Job.sort((a,b)=>{
      return (Number(a.Machine_Number.match(/(\d+)/g)[0]) - Number((b.Machine_Number.match(/(\d+)/g)[0])));})
    for (const j of jo) {
      const info ={
        "Purchase Order":j.Machine_Schedule_PO_No || "-",
        "Machine":j.Machine_Number || "-",
        "Part No":j.Machine_Schedule_Part_No || "-",
        "Part Name":j.PartName || "-",
        "Cycle Time":j.CycleTime || "-",
        "Start Time":j.DateFormatStart || "-",
        "End Time":j.DateFormatEnd || "-",
        "Presetting Time": this.msToTime(j.DatePresettingEnd,j.DatePresettingStart) || "-",
        "Downtime Time":this.hoursDisplay(j.TotalDownTime) || "-",

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client'+new Date().getTime());
    this.spinner.hide();
  }

  viewDrawing(url){
    window.open(url, '_blank');
  }

  validate(s){
    return typeof(s) === 'string'? true:false;
  }

  //Pagination
  lengthPL = 0;
  pageSizePL = 10;
  pageSizeOptionsPL: number[] = [10, 25, 50];
  pageIndexPL = 0;
  offsetPL = this.pageSizePL * this.pageIndexPL;
  searchPL: string;
  sorteduPL = {
    active: '',
    direction: ''
  };

  ClonePackingPOList: PackingList_PO_List[] = [];
  ClonePackingList: PackingList[] = [];

  @ViewChild('topPaginatorPL', { read: MatPaginator, static: false }) topPaginatorPL: MatPaginator;
  @ViewChild('bottomPaginatorPL', { read: MatPaginator, static: false }) bottomPaginatorPL: MatPaginator;

  packingFormat: any = /^PKG\d{3}-\d{6}/;
  tempPackingNo: string = "testing";
  packing_controller = new PackingListDB_controller(this.db,this.firestore);
  packingPartList: PackingList_PO_Part_List[] = [];
  packingPOList: PackingList_PO_List[] = [];
  packingList: PackingList[] = [];

  addPacking() {
    this.generatePackingNumber();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.tempPackingNo;
    this.dialog.open(AddPackingComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    });
  }
  generatePackingNumber() {
    if (this.packingList.length > 0) {
      const clone = this.packingList.filter(p => p.PackingList_No.includes('PKG'));
      let result = null
      if (clone.length > 0) {
        result = clone[clone.length - 1].PackingList_No.match(this.packingFormat);
      }
      let mid3entries = "000";
      let last6entries = "000000";
      if (result) {
        let last6digits = parseInt(result[0].substring(7, 13));
        if (last6digits < 999999) {
          last6digits = last6digits + 1;
          if (last6digits < 10) {
            last6entries = "00000" + last6digits.toString();
          }
          else if (last6digits < 100) {
            last6entries = "0000" + last6digits.toString();
          }
          else if (last6digits < 1000) {
            last6entries = "000" + last6digits.toString();
          }
          else if (last6digits < 10000) {
            last6entries = "00" + last6digits.toString();
          }
          else if (last6digits < 100000) {
            last6entries = "0" + last6digits.toString();
          }
          else if (last6digits < 1000000) {
            last6entries = last6digits.toString();
          }

          let mid3digits = parseInt(result[0].substring(3, 6));
          if (last6digits == 999999) {
            if (mid3digits < 999) {
              mid3digits = mid3digits + 1;
              if (mid3digits < 10) {
                mid3entries = "00" + mid3digits.toString();
              }
              else if (mid3digits < 100) {
                mid3entries = "0" + mid3digits.toString();
              }
              else if (mid3digits < 1000) {
                mid3entries = mid3digits.toString();
              }
            }
          }
        }
        this.tempPackingNo = "PKG" + mid3entries + "-" + last6entries;
      }
      else {
        this.tempPackingNo = "PKG000-000000";
      }
    }
    else {
      this.tempPackingNo = "PKG000-000000";
    }
  }

  DynamicSearchPL(searchValue: string): void {
    this.searchPL = searchValue;

    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.searchPL.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.searchPL.toLowerCase()))
          return true;
      }
      return flag
    });


    this.lengthPL = this.ClonePackingList.length;
    this.sortPackingList();
    this.limitPackingList();
    this.topPaginatorPL.firstPage()
    this.bottomPaginatorPL.firstPage()
  }

  DS1() {
    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.searchPL.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.searchPL.toLowerCase()))
          return true;
      }
      return flag
    });

    this.lengthPL = this.ClonePackingList.length;
  }

  paginator1(pageEvent: PageEvent) {
    this.pageSizePL = pageEvent.pageSize;
    this.pageIndexPL = pageEvent.pageIndex;
    this.offsetPL = this.pageSizePL * this.pageIndexPL;
    if (this.topPaginatorPL.pageIndex < this.pageIndexPL) {
      this.topPaginatorPL.nextPage();
    } else if (this.topPaginatorPL.pageIndex > this.pageIndexPL) {
      this.topPaginatorPL.previousPage();
    }
    if (this.bottomPaginatorPL.pageIndex < this.pageIndexPL) {
      this.bottomPaginatorPL.nextPage();
    } else if (this.bottomPaginatorPL.pageIndex > this.pageIndexPL) {
      this.bottomPaginatorPL.previousPage();
    }
    if (this.searchPL) {
      this.DS1();
    } else {
      this.ClonePackingList = this.packingList.slice();
    }
    this.sortPackingList();
    this.limitPackingList();
  }

  limitPackingList() {
    this.ClonePackingList = this.ClonePackingList.slice(this.offsetPL, (this.offsetPL + this.pageSizePL));
  }

  sortPackingList() {
    if (!this.sorteduPL.active || this.sorteduPL.direction === '') {
      return;
    }
    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sorteduPL.direction === 'asc';
      switch (this.sorteduPL.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.customerID, b.customerID, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
  }

  sortPackingData(sort: Sort) {
    this.sorteduPL = sort;
    this.ClonePackingList = this.packingList.slice();
    if (this.searchPL) {
      this.DS1();
    }
    if (!sort.active || sort.direction === '' && !this.searchPL) {
      this.ClonePackingList = this.packingList.slice();
      this.limitPackingList();
      return;
    }

    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sorteduPL.direction === 'asc';
      switch (this.sorteduPL.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.Customer, b.Customer, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
    this.limitPackingList();
  }

  exportToExcelPL() {
    const exportInformation = [];
    this.spinner.show();

    for (const p of this.packingList) {
      const info ={
        "PackingList No":p.PackingList_No || "-",
        "Customer":p.Customer || "-",
        "Destination":p.Destination || "-",
        "Shipping Date":p.Shipping_Date || "-",
        "Created By":p.Created_By,
        "Created Date":p.Created_Date,
        "Purchase Order List":p.PackingList_PO_List.map(a=>a.PO_No +' : '+ a.PackingList_PO_Part_List.map(b=>b.Part_No).join(',')).join('   |   '),

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();

  }

  GenerateLabel() {
    this.dialog.open(GenerateQRCodeDialogComponent, {
      width: '80%',
      height: '80%'
    });

  }

  GenerateOldLabel() {
    this.dialog.open(GenerateOldLabelComponent, {
      width: '80%',
      height: 'auto'
    });

  }

  deletePacking(packing: PackingList) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Delete this Packing?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.packing_controller.delete_PackingList(packing);
        this.setup();
      }
    })

  }

  viewPackingDetail(packingNumber) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      seePacking: packingNumber,
    };
    this.dialog.open(UpdatePackingComponent, dialogConfig).afterClosed().subscribe(result => {
      setTimeout(() => {
        this.setup();
      }, 1000);
    });
  }

  getColorPanel(po:PartTracker){
    if(po.EndDate.getTime() - new Date().getTime() <= 0){
      return 'error';
    }
    else if(po.EndDate.getTime() - new Date().getTime() <= 604800000){
      return 'warning';
    }
    else{
      return 'running';
    }
  }

  StartProcess(machine: PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to start this job?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        let updates1 = {};
        updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = "Processing";
        this.db.database.ref('Purchase Order/').update(updates1);
        this.setup();
      }
    });
  }

  EndProcess(machine: PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to end this job?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        let updates1 = {};
        updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = "QC";
        this.db.database.ref('Purchase Order/').update(updates1);

        let qcRecord= {};
        qcRecord['QC Date'] = new Date().toISOString();
        qcRecord['Part Name'] = machine.PO_Part_Name;
        qcRecord['Part No'] = machine.PO_Part_No;
        qcRecord['PO Qty'] = machine.POQuantity;
        qcRecord['Due Date'] = machine.EndDate.toISOString();
        qcRecord['Status'] = "Incomplete";

        if(parseInt(machine.POQuantity.toString()) <= 25){
          qcRecord['Inspect Qty'] = 2;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 26 && parseInt(machine.POQuantity.toString()) <= 50){
          qcRecord['Inspect Qty'] = 3;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 51 && parseInt(machine.POQuantity.toString()) <= 90){
          qcRecord['Inspect Qty'] = 4;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 91 && parseInt(machine.POQuantity.toString()) <= 150){
          qcRecord['Inspect Qty'] = 5;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 151 && parseInt(machine.POQuantity.toString()) <= 280){
          qcRecord['Inspect Qty'] = 6;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 281 && parseInt(machine.POQuantity.toString()) <= 500){
          qcRecord['Inspect Qty'] = 7;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 501 && parseInt(machine.POQuantity.toString()) <= 1200){
          qcRecord['Inspect Qty'] = 8;
        }
        else if(parseInt(machine.POQuantity.toString()) >= 1201){
          qcRecord['Inspect Qty'] = 9;
        }

        await this.db.database.ref('QC/' + machine.SO_No + '/' + machine.JO_No).set(qcRecord);
        this.setup();
      }
    });
  }
}

