import { Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { Currency } from 'src/app/Shared/Currency';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {

  Customer = new Customer();
  currencyList = Currency;
  emailFlag= false;
  emailFlag2= false;
  emailFlag3= false;
  phoneFlag= false;
  phoneFlag2= false;
  phoneFlag3= false;
  isEditted= true;

  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<AddCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      if(data){
        this.Customer.Customer_ID = data._Customer_ID;
        this.Customer.Customer_Name = data._Customer_Name;
        this.Customer.Person_In_Charge = data._Person_In_Charge;
        this.Customer.Address = data._Address;
        this.Customer.Address2 = data._Address2;
        this.Customer.Contact = data._Contact;
        this.Customer.Contact2 = data._Contact2;
        this.Customer.Contact3 = data._Contact3;
        this.Customer.Email = data._Email;
        this.Customer.Email2 = data._Email2;
        this.Customer.Email3 = data._Email3;
        this.Customer.Last_Updated = data._Last_Updated;
        this.Customer.Created_Date = data._Created_Date;
        this.Customer.Updated_By = data._Updated_By;
        this.Customer.Created_By = data._Created_By;
        this.isEditted = false;
      }
    }

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.Customer);
  }

  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  emailChange2(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag2 = true;
    }else{
      this.emailFlag2 = false;
    }
  }

  emailChange3(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag3 = true;
    }else{
      this.emailFlag3 = false;
    }
  }

  phoneChange(event,model:NgModel){
    if ((!(/^\d+$/.test(model.value)))){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else if (model.value.length < 8 || model.value.length > 13){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else{
      this.phoneFlag = false;
    }
  }

  phoneChange2(event,model:NgModel){
    if ((!(/^\d+$/.test(model.value)))){
      model.control.setErrors({ invalid: true });
      this.phoneFlag2 = true;
    }else if (model.value.length < 8 || model.value.length > 13){
      model.control.setErrors({ invalid: true });
      this.phoneFlag2 = true;
    }else{
      this.phoneFlag2 = false;
    }
  }

  phoneChange3(event,model:NgModel){
    if ((!(/^\d+$/.test(model.value)))){
      model.control.setErrors({ invalid: true });
      this.phoneFlag3 = true;
    }else if (model.value.length < 8 || model.value.length > 13){
      model.control.setErrors({ invalid: true });
      this.phoneFlag3 = true;
    }else{
      this.phoneFlag3 = false;
    }
  }
}
