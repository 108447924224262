import { POStock } from './../Object_Classes/Part/Part';
import { AngularFireStorage } from '@angular/fire/storage';
import { RawMaterialInfo } from './../Object_Classes/RawMaterial/RawMaterial';
import { AngularFireDatabase } from '@angular/fire/database';
import { Part } from '../Object_Classes/Part/Part';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';


export class PartDB_controller {
  constructor(private db: AngularFireDatabase,private storage:AngularFireStorage, private firestore: AngularFirestore) {
  }
  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getPartList(): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');
    var snapshotPO = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let part = new Part();
        part.Part_No = childSnapshot.key;
        part.Part_Description = childSnapshot.child('Part Description').val();
        part.PhotoURL = childSnapshot.child('PhotoURL').val();
        part.DrawingURL = childSnapshot.child('DrawingURL').val();
        part.UnfoldURL = childSnapshot.child('UnfoldURL').val();
        part.Outsource = childSnapshot.child('Outsource').val();
        part.Drawing_Number = childSnapshot.child('Drawing Number').val();
        part.Customer_License = childSnapshot.child('Customer License').val();
        part.Cycle_Time = childSnapshot.child('Cycle Time').val();
        part.DoP_Update = new Date(childSnapshot.child('Date of Price Update').val());
        part.DoR = new Date(childSnapshot.child('Date of Revised').val());
        part.Duty_Export = childSnapshot.child('Duty Export').val();
        part.FG_or_Semi = childSnapshot.child('FG_or_Semi').val();
        part.Machine_Tonnage = childSnapshot.child('Machine Tonnage').val();
        part.Available_Machine = childSnapshot.child('Available Machine').val();
        part.Number_Cavity = childSnapshot.child('Number of Cavity').val();
        part.Part_Name = childSnapshot.child('Part Name').val();
        part.Process = childSnapshot.child('Process').val();
        part.Part_Price = childSnapshot.child('PPL CUS').val();
        part.Part_Runner_Weight = childSnapshot.child('Part Runner Weight').val();
        part.Part_Weight = childSnapshot.child('Part Weight').val();
        part.Project_Start_Date = new Date(childSnapshot.child('Project Start Date').val());
        part.Project_Status = childSnapshot.child('Project Status').val();
        part.Remarks = childSnapshot.child('Remarks').val();
        part.Stock_Quantity = parseFloat(childSnapshot.child('Stock Quantity').val());
        part.Traffic_Code = childSnapshot.child('Traffic Code').val();
        part.Traffic_Code_Name = childSnapshot.child('Traffic Code Name').val();
        part.Color = childSnapshot.child('Customer Preference/Color').val();
        part.Customer_Name = childSnapshot.child('Customer Preference/Customer Name').val();
        part.Material = childSnapshot.child('Customer Preference/Material').val();
        part.PO_No = childSnapshot.child('Customer Preference/PO No').val();
        part.Mould_Family = childSnapshot.child('Mould/Mould Family Info').val();
        part.Mould_Info = childSnapshot.child('Mould/Mould Information').val();
        part.Mould_Material = childSnapshot.child('Mould/Mould Macterial Used').val();
        part.Mould_Maker_Name = childSnapshot.child('Mould/Mould Maker Name').val();
        part.Mould_Rack_Location = childSnapshot.child('Mould/Mould Rack Location').val();
        part.Mould_Price = childSnapshot.child('PPL MOLD').val();
        part.Mould_Reference_No = childSnapshot.child('Mould/Mould Referance No').val();
        part.Mould_Size = childSnapshot.child('Mould/Mould Size').val();
        part.Box_Size = childSnapshot.child('Packaging/Box/Box Size').val();
        part.Box_Quantity = childSnapshot.child('Packaging/Box/Quantity in Box').val();
        part.Polybag_Size = childSnapshot.child('Packaging/Poly Bag/Bag Size').val();
        part.Polybag_Quantity = childSnapshot.child('Packaging/Poly Bag/Quantity in Bag').val();
        part.Sec_Pro_1 = childSnapshot.child('Secondary Process/Sec_Pro1').val();
        part.Sec_Pro_1_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro1_CycleTime').val();
        part.Sec_Pro_2 = childSnapshot.child('Secondary Process/Sec_Pro2').val();
        part.Sec_Pro_2_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro2_CycleTime').val();
        part.Model = childSnapshot.child('Model').val();
        part.PPL_CUS = childSnapshot.child('PPL CUS').val();
        part.PPL_MOLD = childSnapshot.child('PPL MOLD').val();
        part.supplier1ID = childSnapshot.child('Supplier1ID').val();
        part.supplier4ID = childSnapshot.child('Supplier4ID').val();
        part.supplier5ID = childSnapshot.child('Supplier5ID').val();
        part.supplier6ID = childSnapshot.child('Supplier6ID').val();
        part.Usage1 = childSnapshot.child('Usage1').val();
        part.Usage2 = childSnapshot.child('Usage2').val();
        part.Sec_Cycle_Time = childSnapshot.child('SEC Cycle time').val();
        part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
        part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();
        part.Customer_Address = childSnapshot.child('Customer Address').val();
        part.customerID = childSnapshot.child('CustomerID').val();
        part.RevNO = childSnapshot.child('RevNO').val();
        let stock = 0;

        childSnapshot.child('Stock').forEach(e=>{
          part.Stock.push({
            key: e.key,
            val: e.val()
          })
          stock += parseFloat(e.val());
          this.db.database.ref('QC/'+e.key.split('@@')[0]).once('value').then(qc=>{
            if(qc.exists()){
              let temp =[]
              qc.forEach(child=>{
                if(child.key === part.Part_No){
                  if(child.child('Weighting').val()['RackingNo']){
                    if(temp.length > 0){
                      const exist = temp.findIndex(t=> t.WarehouseLocation === child.child('WarehouseLocation').val() &&  t.RackingNo === child.child('RackingNo').val());
                      if(exist === -1){
                        part.rackingNumber += 'Warehouse Location:' +child.child('Weighting').val()['WarehouseLocation'] +' Rack:' +child.child('Weighting').val()['RackingNo']+'<br>';
                        temp.push({
                          WarehouseLocation:child.child('Weighting').val()['WarehouseLocation'],
                          RackingNo:child.child('Weighting').val()['RackingNo'],
                        })
                      }
                    }else{
                      part.rackingNumber += 'Warehouse Location:' +child.child('Weighting').val()['WarehouseLocation'] +' Rack:' +child.child('Weighting').val()['RackingNo']+'<br>';
                      temp.push({
                        WarehouseLocation:child.child('Weighting').val()['WarehouseLocation'],
                        RackingNo:child.child('Weighting').val()['RackingNo'],
                      })
                    }
                  }

                }
              })
            }
          })
        })
        if(part.Stock_Quantity !== stock){
          if(!isNaN(stock)){
            this.db.database.ref('Part/'+part.Part_No + '/Stock Quantity').set(stock);
            part.Stock_Quantity = stock;
          }

        }

        snapshotPO.forEach(e=>{
          e.child("Part List").forEach(e2=>{
            if(part.Part_No ===e2.child('Part No').val() && e2.child('Status').val() !== 'Shipped'){

              if(parseFloat(e2.child("PO Quantity").val()) > 0)
                part.Reserved_Stock += parseFloat(e2.child("PO Quantity").val());

              const i = {
                id : '',
                poNumber : e.key,
                value : e2.child("PO Quantity").val(),
              }

              if(i.value > 0)
                part.ReservedInfo_NotQC.push(i);

              e2.child("PO_Stock").forEach(reserve=>{
                const info ={
                  id:reserve.key,
                  rackingNo:reserve.key.split('@@')[2] || '',
                  warehouseLocation:reserve.key.split('@@')[3] || '',
                  value: parseFloat(reserve.val()),
                  poNumber: e.key
                }
                part.ReservedInfo.push(info);
              });
            }
          });
        });

        /*if (part.customerID)
          this.getCustomer(part, part.customerID)
        if (part.supplier1ID)
          this.getSupplier(part, part.supplier1ID, 1);

        if (part.supplier4ID)
          this.getSupplier(part, part.supplier4ID, 4);

        if (part.supplier5ID)
          this.getSupplier(part, part.supplier5ID, 5);

        if (part.supplier6ID)
          this.getSupplier(part, part.supplier6ID, 6);

        this.getMaterial(part.Inner_BoxSize).then(data=>{
          part.InnerBox = data;
        }).catch(error=>{
          part.InnerBox = new RawMaterialInfo();
        })
        this.getMaterial(part.Polybag_Size).then(data=>{
          part.PolyBag = data;
        }).catch(error=>{
          part.PolyBag = new RawMaterialInfo();
        })
        this.getMaterial(part.Box_Size).then(data=>{
          part.CartonBox = data;
        }).catch(error=>{
          part.CartonBox = new RawMaterialInfo();
        })
        part.MaterialBatch = [];
        part.Pigment = [];*/

        const rawMaterialID = [];
        childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
          const info = {
            ID: childSnapshot2.child('ID').val(),
            Name: childSnapshot2.child('Name').val(),
            Material: childSnapshot2.child('Material').val(),
            Raw_Type: childSnapshot2.child('Type').val(),
            UOM: childSnapshot2.child('UOM').val(),
            Amount: childSnapshot2.child('Amount').val()
          }

          this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
            if (snap.exists()) {
              const raw = new RawMaterialInfo();
              raw.Material_ID = snap.key;
              raw.Material_Name = snap.child('_Material_Name').val();
              raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
              raw.In_Stock = snap.child('_In_Stock').val();
              raw.Unit = info.UOM;
              //raw.Unit = snap.child('_Unit').val();
              raw.Unit_Price = snap.child('_Unit_Price').val(); 
              raw.Updated_By = snap.child('_Updated_By').val();
              raw.Created_By = snap.child('_Created_By').val();
              raw.Created_Date = new Date(snap.child('_Created_Date').val());
              raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
              raw.Part_Material_Name = info.Name;
              raw.Raw_Amount = info.Amount;
              raw.Raw_Type = info.Raw_Type;
              rawMaterialID.push(raw);
            }
          });

          /*if(info.supplierID){
            this.db.database.ref('Supplier').child(info.supplierID).once('value').then(snapshot=>{
              if(snapshot.exists()){
                info.supplierCurrency = snapshot.child('_Currency').val();
                info.supplier = snapshot.child('_Supplier_Name').val();
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                        raw.Material_ID = snap.key;
                        raw.Material_Name = snap.child('_Material_Name').val();
                        raw.Material_Color = snap.child('_Material_Color').val();
                        raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                        raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                        raw.Material_Spen = snap.child('_Material_Spen').val();
                        raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                        raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Unit = snap.child('_Unit').val();
                        raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Updated_By = snap.child('_Updated_By').val();
                        raw.Created_By = snap.child('_Created_By').val();
                        raw.Created_Date = new Date(snap.child('_Created_Date').val());
                        raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                        raw.Part_Material_Name = info.Name;
                        raw.Customer_Material = info.CustomerMaterial;
                        raw.SPEN_Material = info.SpenMaterial;
                        raw.Customer_RAL = info.CustomerRal;
                        raw.supplierCurrency =info.supplierCurrency
                        raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                        raw.Mat_Ratio = info.Ratio;
                        raw.Supplier = info.supplier;
                        raw.supplierID = info.supplierID;
                        raw.Raw_Type = snap.child('_Raw_Type').val();
                        rawMaterialID.push(raw);
                      }
                });
              }
              else{
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                    raw.Material_ID = snap.key;
                    raw.Material_Name = snap.child('_Material_Name').val();
                    raw.Material_Color = snap.child('_Material_Color').val();
                    raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                    raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                    raw.Material_Spen = snap.child('_Material_Spen').val();
                    raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                    raw.In_Stock = snap.child('_In_Stock').val();
                    raw.Unit = snap.child('_Unit').val();
                    raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                    raw.Updated_By = snap.child('_Updated_By').val();
                    raw.Created_By = snap.child('_Created_By').val();
                    raw.Created_Date = new Date(snap.child('_Created_Date').val());
                    raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                    raw.Part_Material_Name = info.Name;
                    raw.Customer_Material = info.CustomerMaterial;
                    raw.SPEN_Material = info.SpenMaterial;
                    raw.Customer_RAL = info.CustomerRal;
                    raw.supplierCurrency =info.supplierCurrency
                    raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                    raw.Mat_Ratio = info.Ratio;
                    raw.Supplier = info.supplier;
                    raw.supplierID = info.supplierID;
                    raw.Raw_Type = snap.child('_Raw_Type').val();
                    rawMaterialID.push(raw);
                  }
                });
              }
            }).catch(error=>{
              info.supplier = null;
              this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                if (snap.exists()) {
                  const raw = new RawMaterialInfo();
                      raw.Material_ID = snap.key;
                      raw.Material_Name = snap.child('_Material_Name').val();
                      raw.Material_Color = snap.child('_Material_Color').val();
                      raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                      raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                      raw.Material_Spen = snap.child('_Material_Spen').val();
                      raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                      raw.In_Stock = snap.child('_In_Stock').val();
                      raw.Unit = snap.child('_Unit').val();
                      raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                      raw.Updated_By = snap.child('_Updated_By').val();
                      raw.Created_By = snap.child('_Created_By').val();
                      raw.Created_Date = new Date(snap.child('_Created_Date').val());
                      raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                      raw.Part_Material_Name = info.Name;
                      raw.Customer_Material = info.CustomerMaterial;
                      raw.SPEN_Material = info.SpenMaterial;
                      raw.Customer_RAL = info.CustomerRal;
                      raw.supplierCurrency =info.supplierCurrency
                      raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                      raw.Mat_Ratio = info.Ratio;
                      raw.Supplier = info.supplier;
                      raw.supplierID = info.supplierID;
                      raw.Raw_Type = snap.child('_Raw_Type').val();
                      rawMaterialID.push(raw);
                    }
              });
            })
          }*/
        });

        part.Raw_Material = rawMaterialID;
        PartList.push(part);
      });
    }

    return PartList;
  }

  async getNewPartList(): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');
    //var snapshotPO = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if (!childSnapshot.child("Raw Material").exists()){
          let part = new Part();
          part.Part_No = childSnapshot.key;
          part.PhotoURL = childSnapshot.child('PhotoURL').val();
          part.DrawingURL = childSnapshot.child('DrawingURL').val();
          part.UnfoldURL = childSnapshot.child('UnfoldURL').val();
          part.Part_Name = childSnapshot.child('Part Name').val();
          part.Process = childSnapshot.child('Process').val();
          PartList.push(part);
        }
      });
    }

    return PartList;
  }

  async add_or_update_Part(_newPart: Part) {
    var updates = {}
    _newPart.Part_No= _newPart.Part_No.trim();
    await this.db.database.ref('Part/'+_newPart.Part_No).set(null).then(async r => {
      const partinfo = { };

      if(_newPart.Stock_Quantity){
        partinfo['Stock Quantity'] = _newPart.Stock_Quantity;
      }
      else{
        partinfo['Stock Quantity'] = 0;
      }

      /*partinfo['Customer License'] = _newPart.Customer_License || '-';
      partinfo['Cycle Time'] = _newPart.Cycle_Time || '-';
      partinfo['Duty Export'] = _newPart.Duty_Export || '-';
      partinfo['Machine Tonnage'] = _newPart.Machine_Tonnage || '-';
      partinfo['Available Machine'] = _newPart.Available_Machine || '-';
      partinfo['Number of Cavity'] = _newPart.Number_Cavity || '-';*/
      partinfo['Part Name'] = _newPart.Part_Name || '-';
      partinfo['Process'] = _newPart.Process || [];
      partinfo['Outsource'] = _newPart.Outsource || 'N';
      partinfo['Drawing Number'] = _newPart.Drawing_Number || '-';
      /*partinfo['Part Description'] = _newPart.Part_Description || '-';
      partinfo['Part Runner Weight'] = _newPart.Part_Runner_Weight || '-';
      partinfo['Part Weight'] = _newPart.Part_Weight || '-';
      partinfo['Project Start Date'] = _newPart.Project_Start_Date || '-';
      partinfo['Remarks'] = _newPart.Remarks || '-';
      partinfo['Traffic Code'] = _newPart.Traffic_Code || '-';
      partinfo['Traffic Code Name'] = _newPart.Traffic_Code_Name || '-';
      partinfo['Quantity Inner Box'] = _newPart.Quantity_InnerBox || '-';
      partinfo['Inner Box Size'] = _newPart.Inner_BoxSize || '-';
      partinfo['Model'] = _newPart.Model || '-';
      partinfo['PPL CUS'] = _newPart.PPL_CUS || '-';
      partinfo['PPL MOLD'] = _newPart.PPL_MOLD || '-';
      partinfo['Supplier1'] = _newPart.Supplier1 || '-';
      partinfo['Supplier4'] = _newPart.Supplier4 || '-';
      partinfo['Supplier5'] = _newPart.Supplier5 || '-';
      partinfo['Supplier6'] = _newPart.Supplier6 || '-';
      partinfo['Supplier1ID'] = _newPart.supplier1ID || '-';
      partinfo['Supplier4ID'] = _newPart.supplier4ID || '-';
      partinfo['Supplier5ID'] = _newPart.supplier5ID || '-';
      partinfo['Supplier6ID'] = _newPart.supplier6ID || '-';
      partinfo['Usage1'] = _newPart.Usage1 || '-';
      partinfo['Usage2'] = _newPart.Usage2 || '-';
      partinfo['SEC Cycle time'] = _newPart.Sec_Cycle_Time || '-';
      partinfo['Customer Address'] = _newPart.Customer_Address || '-';
      partinfo['CustomerID'] = _newPart.customerID || '-';
      partinfo['RevNO'] = _newPart.RevNO || '-';*/

      await this.db.database.ref('Part/' + _newPart.Part_No).set(partinfo)

      for (const stock of _newPart.Stock) {
        await this.db.database.ref('Part/' + _newPart.Part_No + '/Stock/' + stock.key).set(stock.val)
      }

      /*const custinfo ={}

      custinfo['Customer Name'] = _newPart.Customer_Name || '-';
      custinfo['PO No'] = _newPart.PO_No || '-';
      await this.db.database.ref('Part/' + _newPart.Part_No +'/Customer Preference').set(custinfo)

      const mouldInfo ={}

      mouldInfo['Mould Family Info'] = _newPart.Mould_Family || '-';
      mouldInfo['Mould Information'] = _newPart.Mould_Info || '-';
      mouldInfo['Mould Macterial Used'] = _newPart.Mould_Material || '-';
      mouldInfo['Mould Maker Name'] = _newPart.Mould_Maker_Name || '-';
      mouldInfo['Mould Rack Location'] = _newPart.Mould_Rack_Location || '-';
      mouldInfo['Mould Referance No'] = _newPart.Mould_Reference_No || '-';
      mouldInfo['Mould Size'] = _newPart.Mould_Size || '-';

      await this.db.database.ref('Part/' + _newPart.Part_No +'/Mould').set(mouldInfo)

      const packInfo ={}

      packInfo['Box Size'] = _newPart.Box_Size || '-';
      packInfo['Quantity in Box'] = _newPart.Box_Quantity || '-';
      await this.db.database.ref('Part/' + _newPart.Part_No +'/Packaging/Box').set(packInfo)

      const polyInfo ={}

      polyInfo['Bag Size'] = _newPart.Polybag_Size || '-';
      polyInfo['Quantity in Bag'] = _newPart.Polybag_Quantity || '-';
      await this.db.database.ref('Part/' + _newPart.Part_No +'/Packaging/Poly Bag').set(polyInfo)


      const secInfo ={}

      secInfo['Sec_Pro1'] = _newPart.Sec_Pro_1 || '-';
      secInfo['Sec_Pro1_CycleTime'] = _newPart.Sec_Pro_1_Cycle_Time || '-';
      secInfo['Sec_Pro2'] = _newPart.Sec_Pro_2 || '-';
      secInfo['Sec_Pro2_CycleTime'] = _newPart.Sec_Pro_2_Cycle_Time || '-';

      await this.db.database.ref('Part/' + _newPart.Part_No +'/Secondary Process').set(secInfo);*/
      

      if(_newPart.Raw_Material != null){
        _newPart.Raw_Material.forEach(async (r, index) => {
          const value = {
            ID: r.Material_ID,
            Material: r.Material_Name,
            Name: r.Part_Material_Name,
            Amount: r.Raw_Amount,
            //UOM: r.Unit,
            //CustomerMaterial: r.Customer_Material,
            //SpenMaterial: r.SPEN_Material,
            //CustomerRal: r.Customer_RAL,
            Type: r.Raw_Type,
            //Ratio: r.Mat_Ratio,
            //Supplier: r.Supplier,
            //SupplierID: r.supplierID
          }
          
          await this.db.database.ref('Part/' + _newPart.Part_No+ '/Raw Material/raw' + index).set(value)
        });
      }

      const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
      const info = {
        date: new Date(),
        content:JSON.stringify(_newPart),
      };
      await this.firestore.collection('PartLog').doc(dateFormat).set({ Date: new Date() });
      await this.firestore.collection('PartLog').doc(dateFormat).collection('Part').add(info);

      if(_newPart.DrawingURL != null && _newPart.DrawingURL != ''){
        if (typeof (_newPart.DrawingURL) !== 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.DrawingURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(async url => {
                if (url) {
                  const photo = {}
                  _newPart.DrawingURL = url;
                  photo['DrawingURL'] = _newPart.DrawingURL;
                  await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
                }
              });
            })
          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        }
        else {
          const photo = {}
          photo['DrawingURL'] = _newPart.DrawingURL;
          await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
        }
      }

      if(_newPart.PhotoURL != null && _newPart.PhotoURL != ''){
        if (typeof (_newPart.PhotoURL) !== 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.PhotoURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(async url => {
                if (url) {
                  _newPart.PhotoURL = url;
                  const photo = {}
                  photo['PhotoURL'] = _newPart.PhotoURL;
                  await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
                }
              });
            }
            )
  
          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        } 
        else {
          const photo = {}
          photo['PhotoURL'] = _newPart.PhotoURL;
          await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
        }
      }

      if(_newPart.UnfoldURL != null && _newPart.UnfoldURL != ''){
        if (typeof (_newPart.UnfoldURL) !== 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.UnfoldURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(async url => {
                if (url) {
                  _newPart.UnfoldURL = url;
                  const photo = {}
                  photo['UnfoldURL'] = _newPart.UnfoldURL;
                  await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
                }
              });
            }
            )
  
          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        } 
        else {
          const photo = {}
          photo['PhotoURL'] = _newPart.PhotoURL;
          await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
        }
      }

      /*if((_newPart.DrawingURL != null && _newPart.DrawingURL != '') || (_newPart.PhotoURL != null && _newPart.PhotoURL != '') ){
        if (typeof (_newPart.DrawingURL) !== 'string' && typeof (_newPart.PhotoURL) !== 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.DrawingURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(url => {
                if (url) {
                  _newPart.DrawingURL = url;
                  if (_newPart.PhotoURL) {
                    n = Date.now();
                    filePath = `PartsImages/${n}`;
                    fileRef = this.storage.ref(filePath);
                    const task2 = this.storage.upload(`PartsImages/${n}`, _newPart.PhotoURL);
                    task2.snapshotChanges().pipe(finalize(() => {
                      var downloadURL2 = fileRef.getDownloadURL();
                      downloadURL2.subscribe(async url2 => {
                        _newPart.PhotoURL = url2;
                        const photo = {}
                        photo['DrawingURL'] = _newPart.DrawingURL;
                        photo['PhotoURL'] = _newPart.PhotoURL;
                        await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
  
                      })
                    }))
                    .subscribe(url => {
                      if (url) {
                        console.log(url);
                      }
                    });
                  }
                }
              });
            })
          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        }
        else if (typeof (_newPart.DrawingURL) !== 'string' && typeof (_newPart.PhotoURL) === 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.DrawingURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(async url => {
                if (url) {
                  _newPart.DrawingURL = url;
  
                  const photo = {}
                  photo['DrawingURL'] = _newPart.DrawingURL;
                  await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
  
                }
              });
            }
            )
  
          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        }
        else if (typeof (_newPart.DrawingURL) === 'string' && typeof (_newPart.PhotoURL) !== 'string') {
          var n = Date.now();
          var filePath = `PartsImages/${n}`;
          var fileRef = this.storage.ref(filePath);
          const task = this.storage.upload(`PartsImages/${n}`, _newPart.PhotoURL);
          task.snapshotChanges().pipe(
            finalize(() => {
              var downloadURL = fileRef.getDownloadURL();
              downloadURL.subscribe(async url => {
                if (url) {
                  _newPart.PhotoURL = url;
                  const photo = {}
                  photo['PhotoURL'] = _newPart.PhotoURL;
                  await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
                }
              });
            }
            )
  
          ).subscribe(url => {
            if (url) {
              console.log(url);
            }
          });
        } 
        else {
          const photo = {}
          photo['PhotoURL'] = _newPart.PhotoURL;
          photo['DrawingURL'] = _newPart.DrawingURL;
          await this.db.database.ref('Part/' + _newPart.Part_No +'/').update(photo);
        }
      }*/
    })
  }

  async search_Part(Part_No: string): Promise<Part> {
    let searched_part = new Part;
    var childSnapshot = await this.db.database.ref('Part/'+Part_No).once('value');

    if (childSnapshot.exists()) {

        var get_Part_No = childSnapshot.key;
        if (Part_No.match(get_Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Part_Description = childSnapshot.child('Part Description').val();
          searched_part.Customer_License = childSnapshot.child('Customer License').val();
          searched_part.Cycle_Time = childSnapshot.child('Cycle Time').val();
          searched_part.DoP_Update = new Date(childSnapshot.child('Date of Price Update').val());

          searched_part.DoR = new Date(childSnapshot.child('Date of Revised').val());

          searched_part.Duty_Export = childSnapshot.child('Duty Export').val();
          searched_part.FG_or_Semi = childSnapshot.child('FG_or_Semi').val();
          searched_part.Machine_Tonnage = childSnapshot.child('Machine Tonnage').val();
          searched_part.Available_Machine = childSnapshot.child('Available Machine').val();
          searched_part.Number_Cavity = childSnapshot.child('Number of Cavity').val();
          searched_part.Part_Name = childSnapshot.child('Part Name').val();
          searched_part.Part_Price = childSnapshot.child('PPL CUS').val();
          searched_part.Part_Runner_Weight = childSnapshot.child('Part Runner Weight').val();
          searched_part.Part_Weight = childSnapshot.child('Part Weight').val();
          searched_part.Project_Start_Date = new Date(childSnapshot.child('Project Start Date').val());

          searched_part.Project_Status = childSnapshot.child('Project Status').val();
          searched_part.Remarks = childSnapshot.child('Remarks').val();
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          searched_part.Traffic_Code = childSnapshot.child('Traffic Code').val();
          searched_part.Traffic_Code_Name = childSnapshot.child('Traffic Code Name').val();

          searched_part.Color = childSnapshot.child('Customer Preference/Color').val();
          searched_part.Customer_Name = childSnapshot.child('Customer Preference/Customer Name').val();
          searched_part.Material = childSnapshot.child('Customer Preference/Material').val();
          searched_part.PO_No = childSnapshot.child('Customer Preference/PO No').val();

          searched_part.Mould_Family = childSnapshot.child('Mould/Mould Family Info').val();
          searched_part.Mould_Info = childSnapshot.child('Mould/Mould Information').val();
          searched_part.Mould_Material = childSnapshot.child('Mould/Mould Macterial Used').val();
          searched_part.Mould_Maker_Name = childSnapshot.child('Mould/Mould Maker Name').val();
          searched_part.Mould_Rack_Location = childSnapshot.child('Mould/Mould Rack Location').val();
          searched_part.Mould_Price = childSnapshot.child('PPL MOLD').val();
          searched_part.Mould_Reference_No = childSnapshot.child('Mould/Mould Referance No').val();
          searched_part.Mould_Size = childSnapshot.child('Mould/Mould Size').val();

          searched_part.Box_Size = childSnapshot.child('Packaging/Box/Box Size').val();
          searched_part.Box_Quantity = childSnapshot.child('Packaging/Box/Quantity in Box').val();
          searched_part.Polybag_Size = childSnapshot.child('Packaging/Poly Bag/Bag Size').val();
          searched_part.Polybag_Quantity = childSnapshot.child('Packaging/Poly Bag/Quantity in Bag').val();

          searched_part.Sec_Pro_1 = childSnapshot.child('Secondary Process/Sec_Pro1').val();
          searched_part.Sec_Pro_1_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro1_CycleTime').val();
          searched_part.Sec_Pro_2 = childSnapshot.child('Secondary Process/Sec_Pro2').val();
          searched_part.Sec_Pro_2_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro2_CycleTime').val();

          searched_part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
          searched_part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();

          searched_part.Model = childSnapshot.child('Model').val();
          searched_part.PPL_CUS = childSnapshot.child('PPL CUS').val();
          searched_part.PPL_MOLD = childSnapshot.child('PPL MOLD').val();
          searched_part.supplier1ID = childSnapshot.child('Supplier1ID').val();
          searched_part.supplier4ID = childSnapshot.child('Supplier4ID').val();
          searched_part.supplier5ID = childSnapshot.child('Supplier5ID').val();
          searched_part.supplier6ID = childSnapshot.child('Supplier6ID').val();
          searched_part.Usage1 = childSnapshot.child('Usage1').val();
          searched_part.Usage2 = childSnapshot.child('Usage2').val();
          searched_part.PhotoURL = childSnapshot.child('PhotoURL').val();
          searched_part.Sec_Cycle_Time = childSnapshot.child('SEC Cycle time').val();
          searched_part.DrawingURL = childSnapshot.child('DrawingURL').val();
          searched_part.UnfoldURL = childSnapshot.child('UnfoldURL').val();
          searched_part.Outsource = childSnapshot.child('Outsource').val();
          searched_part.Drawing_Number = childSnapshot.child('Drawing Number').val();
          searched_part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
          searched_part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();
          searched_part.Customer_Address = childSnapshot.child('Customer Address').val();
          searched_part.customerID = childSnapshot.child('CustomerID').val();
          this.getCustomer(searched_part, searched_part.customerID);
          this.getSupplier(searched_part, searched_part.supplier1ID, 1);
          this.getSupplier(searched_part, searched_part.supplier4ID, 4);
          this.getSupplier(searched_part, searched_part.supplier5ID, 5);
          this.getSupplier(searched_part, searched_part.supplier6ID, 6);

          this.getMaterial(searched_part.Inner_BoxSize).then(data=>{
            searched_part.InnerBox = data;
          })
          this.getMaterial(searched_part.Polybag_Size).then(data=>{
            searched_part.PolyBag = data;
          })
          this.getMaterial(searched_part.Box_Size).then(data=>{
            searched_part.CartonBox = data;
          })
          searched_part.MaterialBatch = [];
          searched_part.Pigment = [];

          const rawMaterialID = [];
          childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
            const info = {
              ID: childSnapshot2.child('ID').val(),
              Name: childSnapshot2.child('Name').val(),
              Material: childSnapshot2.child('Material').val(),
              Raw_Type: childSnapshot2.child('Type').val(),
              UOM: childSnapshot2.child('UOM').val(),
              Amount: childSnapshot2.child('Amount').val()
            }
  
            this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
              if (snap.exists()) {
                const raw = new RawMaterialInfo();
                raw.Material_ID = snap.key;
                raw.Material_Name = snap.child('_Material_Name').val();
                raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                raw.In_Stock = snap.child('_In_Stock').val();
                raw.Unit = info.UOM;
                //raw.Unit = snap.child('_Unit').val();
                raw.Unit_Price = snap.child('_Unit_Price').val(); 
                raw.Updated_By = snap.child('_Updated_By').val();
                raw.Created_By = snap.child('_Created_By').val();
                raw.Created_Date = new Date(snap.child('_Created_Date').val());
                raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                raw.Part_Material_Name = info.Name;
                raw.Raw_Amount = info.Amount;
                raw.Raw_Type = info.Raw_Type;
                rawMaterialID.push(raw);
              }
            });

            /*if(info.supplierID){
              this.db.database.ref('Supplier').child(info.supplierID).once('value').then(snapshot=>{
                if(snapshot.exists()){
                  info.supplierCurrency = snapshot.child('_Currency').val();
                  info.supplier = snapshot.child('_Supplier_Name').val();
                  this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                    if (snap.exists()) {
                      const raw = new RawMaterialInfo();
                          raw.Material_ID = snap.key;
                          raw.Material_Name = snap.child('_Material_Name').val();
                          raw.Material_Color = snap.child('_Material_Color').val();
                          raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                          raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                          raw.Material_Spen = snap.child('_Material_Spen').val();
                          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                          raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Unit = snap.child('_Unit').val();
                          raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Updated_By = snap.child('_Updated_By').val();
                          raw.Created_By = snap.child('_Created_By').val();
                          raw.Created_Date = new Date(snap.child('_Created_Date').val());
                          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                          raw.Part_Material_Name = info.Name;
                          raw.Customer_Material = info.CustomerMaterial;
                          raw.SPEN_Material = info.SpenMaterial;
                          raw.Customer_RAL = info.CustomerRal;
                          raw.supplierCurrency =info.supplierCurrency
                          raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                          raw.Mat_Ratio = info.Ratio;
                          raw.Supplier = info.supplier;
                          raw.supplierID = info.supplierID;
                          raw.Raw_Type = snap.child('_Raw_Type').val();
                          rawMaterialID.push(raw);
                        }
                  });
                }else{
                  this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                    if (snap.exists()) {
                      const raw = new RawMaterialInfo();
                          raw.Material_ID = snap.key;
                          raw.Material_Name = snap.child('_Material_Name').val();
                          raw.Material_Color = snap.child('_Material_Color').val();
                          raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                          raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                          raw.Material_Spen = snap.child('_Material_Spen').val();
                          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                          raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Unit = snap.child('_Unit').val();
                          raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Updated_By = snap.child('_Updated_By').val();
                          raw.Created_By = snap.child('_Created_By').val();
                          raw.Created_Date = new Date(snap.child('_Created_Date').val());
                          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                          raw.Part_Material_Name = info.Name;
                          raw.Customer_Material = info.CustomerMaterial;
                          raw.SPEN_Material = info.SpenMaterial;
                          raw.Customer_RAL = info.CustomerRal;
                          raw.supplierCurrency =info.supplierCurrency
                          raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                          raw.Mat_Ratio = info.Ratio;
                          raw.Supplier = info.supplier;
                          raw.supplierID = info.supplierID;
                          raw.Raw_Type = snap.child('_Raw_Type').val();
                          rawMaterialID.push(raw);
                        }
                  });
                }
              }).catch(error=>{
                info.supplier = null;
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                        raw.Material_ID = snap.key;
                        raw.Material_Name = snap.child('_Material_Name').val();
                        raw.Material_Color = snap.child('_Material_Color').val();
                        raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                        raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                        raw.Material_Spen = snap.child('_Material_Spen').val();
                        raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                        raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Unit = snap.child('_Unit').val();
                        raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Updated_By = snap.child('_Updated_By').val();
                        raw.Created_By = snap.child('_Created_By').val();
                        raw.Created_Date = new Date(snap.child('_Created_Date').val());
                        raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                        raw.Part_Material_Name = info.Name;
                        raw.Customer_Material = info.CustomerMaterial;
                        raw.SPEN_Material = info.SpenMaterial;
                        raw.Customer_RAL = info.CustomerRal;
                        raw.supplierCurrency =info.supplierCurrency
                        raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                        raw.Mat_Ratio = info.Ratio;
                        raw.Supplier = info.supplier;
                        raw.supplierID = info.supplierID;
                        raw.Raw_Type = snap.child('_Raw_Type').val();
                        rawMaterialID.push(raw);
                      }
                });
              })
            }*/
          });
          searched_part.Raw_Material = rawMaterialID;
        }

    }


    return searched_part;
  }

  async search_Partial_Part(Part_No: string): Promise<Part[]> {
    let PartList: Part[] = [];
    var snapshot = await this.db.database.ref('Part').once('value');

    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let searched_part = new Part();
        var get_Part_No = childSnapshot.key;
        if (get_Part_No.includes(Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Part_Description = childSnapshot.child('Part Description').val();
          searched_part.Customer_License = childSnapshot.child('Customer License').val();
          searched_part.Cycle_Time = childSnapshot.child('Cycle Time').val();
          searched_part.DoP_Update = new Date(childSnapshot.child('Date of Price Update').val());

          searched_part.DoR = new Date(childSnapshot.child('Date of Revised').val());

          searched_part.Duty_Export = childSnapshot.child('Duty Export').val();
          searched_part.FG_or_Semi = childSnapshot.child('FG_or_Semi').val();
          searched_part.Machine_Tonnage = childSnapshot.child('Machine Tonnage').val();
          searched_part.Available_Machine = childSnapshot.child('Available Machine').val();
          searched_part.Number_Cavity = childSnapshot.child('Number of Cavity').val();
          searched_part.Part_Name = childSnapshot.child('Part Name').val();
          searched_part.Part_Price = childSnapshot.child('PPL CUS').val();

          searched_part.Part_Runner_Weight = childSnapshot.child('Part Runner Weight').val();
          searched_part.Part_Weight = childSnapshot.child('Part Weight').val();
          searched_part.Project_Start_Date = new Date(childSnapshot.child('Project Start Date').val());

          searched_part.Project_Status = childSnapshot.child('Project Status').val();
          searched_part.Remarks = childSnapshot.child('Remarks').val();
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          searched_part.Traffic_Code = childSnapshot.child('Traffic Code').val();
          searched_part.Traffic_Code_Name = childSnapshot.child('Traffic Code Name').val();

          searched_part.Color = childSnapshot.child('Customer Preference/Color').val();
          searched_part.Customer_Name = childSnapshot.child('Customer Preference/Customer Name').val();
          searched_part.Material = childSnapshot.child('Customer Preference/Material').val();
          searched_part.PO_No = childSnapshot.child('Customer Preference/PO No').val();

          searched_part.Mould_Family = childSnapshot.child('Mould/Mould Family Info').val();
          searched_part.Mould_Info = childSnapshot.child('Mould/Mould Information').val();
          searched_part.Mould_Material = childSnapshot.child('Mould/Mould Macterial Used').val();
          searched_part.Mould_Maker_Name = childSnapshot.child('Mould/Mould Maker Name').val();
          searched_part.Mould_Rack_Location = childSnapshot.child('Mould/Mould Rack Location').val();
          searched_part.Mould_Price = childSnapshot.child('PPL MOLD').val();

          searched_part.Mould_Reference_No = childSnapshot.child('Mould/Mould Referance No').val();
          searched_part.Mould_Size = childSnapshot.child('Mould/Mould Size').val();

          searched_part.Box_Size = childSnapshot.child('Packaging/Box/Box Size').val();
          searched_part.Box_Quantity = childSnapshot.child('Packaging/Box/Quantity in Box').val();
          searched_part.Polybag_Size = childSnapshot.child('Packaging/Poly Bag/Bag Size').val();
          searched_part.Polybag_Quantity = childSnapshot.child('Packaging/Poly Bag/Quantity in Bag').val();

          searched_part.Sec_Pro_1 = childSnapshot.child('Secondary Process/Sec_Pro1').val();
          searched_part.Sec_Pro_1_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro1_CycleTime').val();
          searched_part.Sec_Pro_2 = childSnapshot.child('Secondary Process/Sec_Pro2').val();
          searched_part.Sec_Pro_2_Cycle_Time = childSnapshot.child('Secondary Process/Sec_Pro2_CycleTime').val();
          searched_part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
          searched_part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();

          searched_part.Model = childSnapshot.child('Model').val();
          searched_part.PPL_CUS = childSnapshot.child('PPL CUS').val();
          searched_part.PPL_MOLD = childSnapshot.child('PPL MOLD').val();
          searched_part.supplier1ID = childSnapshot.child('Supplier1ID').val();
          searched_part.supplier4ID = childSnapshot.child('Supplier4ID').val();
          searched_part.supplier5ID = childSnapshot.child('Supplier5ID').val();
          searched_part.supplier6ID = childSnapshot.child('Supplier6ID').val();
          searched_part.Usage1 = childSnapshot.child('Usage1').val();
          searched_part.Usage2 = childSnapshot.child('Usage2').val();
          searched_part.PhotoURL = childSnapshot.child('PhotoURL').val();
          searched_part.UnfoldURL = childSnapshot.child('UnfoldURL').val();
          searched_part.Outsource = childSnapshot.child('Outsource').val();
          searched_part.Drawing_Number = childSnapshot.child('Drawing Number').val();
          searched_part.Sec_Cycle_Time = childSnapshot.child('SEC Cycle time').val();
          searched_part.DrawingURL = childSnapshot.child('DrawingURL').val();
          searched_part.Inner_BoxSize = childSnapshot.child('Inner Box Size').val();
          searched_part.Quantity_InnerBox = childSnapshot.child('Quantity Inner Box').val();
          searched_part.Customer_Address = childSnapshot.child('Customer Address').val();
          searched_part.customerID = childSnapshot.child('CustomerID').val();
          this.getCustomer(searched_part, searched_part.customerID);

          this.getSupplier(searched_part, searched_part.supplier1ID, 1);
          this.getSupplier(searched_part, searched_part.supplier4ID, 4);
          this.getSupplier(searched_part, searched_part.supplier5ID, 5);
          this.getSupplier(searched_part, searched_part.supplier6ID, 6);
          this.getMaterial(searched_part.Inner_BoxSize).then(data=>{
            searched_part.InnerBox = data;
          })
          this.getMaterial(searched_part.Polybag_Size).then(data=>{
            searched_part.PolyBag = data;
          })
          this.getMaterial(searched_part.Box_Size).then(data=>{
            searched_part.CartonBox = data;
          })
          searched_part.MaterialBatch = [];
          searched_part.Pigment = [];

          const rawMaterialID = [];
          childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
            const info = {
              ID: childSnapshot2.child('ID').val(),
              Name: childSnapshot2.child('Name').val(),
              CustomerMaterial: childSnapshot2.child('CustomerMaterial').val(),
              SpenMaterial: childSnapshot2.child('SpenMaterial').val(),
              CustomerRal: childSnapshot2.child('CustomerRal').val(),
              Ratio: childSnapshot2.child('Ratio').val(),
              supplier: childSnapshot2.child('Supplier').val(),
              supplierID: childSnapshot2.child('SupplierID').val(),
              supplierCurrency: '',
            }
            if(info.supplierID){
              this.db.database.ref('Supplier').child(info.supplierID).once('value').then(snapshot=>{
                if(snapshot.exists()){
                  info.supplierCurrency = snapshot.child('_Currency').val();
                  info.supplier = snapshot.child('_Supplier_Name').val();
                  this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                    if (snap.exists()) {
                      const raw = new RawMaterialInfo();
                          raw.Material_ID = snap.key;
                          raw.Material_Name = snap.child('_Material_Name').val();
                          raw.Material_Color = snap.child('_Material_Color').val();
                          raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                          raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                          raw.Material_Spen = snap.child('_Material_Spen').val();
                          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                          raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Unit = snap.child('_Unit').val();
                          raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Updated_By = snap.child('_Updated_By').val();
                          raw.Created_By = snap.child('_Created_By').val();
                          raw.Created_Date = new Date(snap.child('_Created_Date').val());
                          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                          raw.Part_Material_Name = info.Name;
                          raw.Customer_Material = info.CustomerMaterial;
                          raw.SPEN_Material = info.SpenMaterial;
                          raw.Customer_RAL = info.CustomerRal;
                          raw.supplierCurrency =info.supplierCurrency
                          raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                          raw.Mat_Ratio = info.Ratio;
                          raw.Supplier = info.supplier;
                          raw.supplierID = info.supplierID;
                          raw.Raw_Type = snap.child('_Raw_Type').val();
                          rawMaterialID.push(raw);
                        }
                  });
                }else{
                  this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                    if (snap.exists()) {
                      const raw = new RawMaterialInfo();
                          raw.Material_ID = snap.key;
                          raw.Material_Name = snap.child('_Material_Name').val();
                          raw.Material_Color = snap.child('_Material_Color').val();
                          raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                          raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                          raw.Material_Spen = snap.child('_Material_Spen').val();
                          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                          raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Unit = snap.child('_Unit').val();
                          raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                          raw.Updated_By = snap.child('_Updated_By').val();
                          raw.Created_By = snap.child('_Created_By').val();
                          raw.Created_Date = new Date(snap.child('_Created_Date').val());
                          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                          raw.Part_Material_Name = info.Name;
                          raw.Customer_Material = info.CustomerMaterial;
                          raw.SPEN_Material = info.SpenMaterial;
                          raw.Customer_RAL = info.CustomerRal;
                          raw.supplierCurrency =info.supplierCurrency
                          raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                          raw.Mat_Ratio = info.Ratio;
                          raw.Supplier = info.supplier;
                          raw.supplierID = info.supplierID;
                          raw.Raw_Type = snap.child('_Raw_Type').val();
                          rawMaterialID.push(raw);
                        }
                  });
                }
              }).catch(error=>{
                info.supplier = null;
                this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
                  if (snap.exists()) {
                    const raw = new RawMaterialInfo();
                        raw.Material_ID = snap.key;
                        raw.Material_Name = snap.child('_Material_Name').val();
                        raw.Material_Color = snap.child('_Material_Color').val();
                        raw.Material_Color_Code = snap.child('_Material_Color_Code').val();
                        raw.Material_Color_Ratio = snap.child('_Material_Color_Ratio').val();
                        raw.Material_Spen = snap.child('_Material_Spen').val();
                        raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
                        raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Unit = snap.child('_Unit').val();
                        raw.Unit_Price = snap.child('_Unit_Price').val(); raw.In_Stock = snap.child('_In_Stock').val();
                        raw.Updated_By = snap.child('_Updated_By').val();
                        raw.Created_By = snap.child('_Created_By').val();
                        raw.Created_Date = new Date(snap.child('_Created_Date').val());
                        raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
                        raw.Part_Material_Name = info.Name;
                        raw.Customer_Material = info.CustomerMaterial;
                        raw.SPEN_Material = info.SpenMaterial;
                        raw.Customer_RAL = info.CustomerRal;
                        raw.supplierCurrency =info.supplierCurrency
                        raw.Quantity_PerBag = snap.child('_Quantity_PerBag').val();
                        raw.Mat_Ratio = info.Ratio;
                        raw.Supplier = info.supplier;
                        raw.supplierID = info.supplierID;
                        raw.Raw_Type = snap.child('_Raw_Type').val();
                        rawMaterialID.push(raw);
                      }
                });
              })
            }
          });
          searched_part.Raw_Material = rawMaterialID;
          PartList.push(searched_part);
        }
      }));
    }

    return PartList;
  }

  delete_Part(part_no: string) {
    this.db.database.ref('/Part/' + part_no).set(null);
  }

  async getMaterial(id): Promise<RawMaterialInfo> {
    let searched_raw = new RawMaterialInfo();
    var snapshot = await this.db.database.ref('RawMaterial').child(id).once('value');
    if (snapshot.exists()) {
        searched_raw.Material_ID = snapshot.key;
        searched_raw.Material_Name = snapshot.child('_Material_Name').val();
        searched_raw.Material_Color = snapshot.child('_Material_Color').val();
        searched_raw.Material_Color_Code = snapshot.child('_Material_Color_Code').val();
        searched_raw.Material_Color_Ratio = snapshot.child('_Material_Color_Ratio').val();
        searched_raw.Material_Spen = snapshot.child('_Material_Spen').val();
        searched_raw.In_Stock = snapshot.child('_In_Stock').val();
        searched_raw.Quantity_Reserved = snapshot.child('_Quantity_Reserved').val();
        searched_raw.Unit = snapshot.child('_Unit').val();
        searched_raw.Quantity_PerBag = snapshot.child('_Quantity_PerBag').val();
        searched_raw.Unit_Price = snapshot.child('_Unit_Price').val();
        searched_raw.Updated_By = snapshot.child('_Updated_By').val();
        searched_raw.Created_By = snapshot.child('_Created_By').val();
        searched_raw.Created_Date = new Date(snapshot.child('_Created_Date').val());
        searched_raw.Last_Updated = new Date(snapshot.child('_Last_Updated').val());
        searched_raw.Raw_Type = snapshot.child('_Raw_Type').val();
        searched_raw.Carton_Weight = snapshot.child('_Carton_Weight').val();
    }
    return searched_raw;
  }

  async getSupplier(part:Part, supplierId, number): Promise<void>{
    var snapshot = await this.db.database.ref('Supplier').child(supplierId).once('value');
    if(snapshot.exists()){
      switch(number){
        case 1:part.Supplier1 = snapshot.child('_Supplier_Name').val();break;
        case 4:part.Supplier4 = snapshot.child('_Supplier_Name').val();break;
        case 5:part.Supplier5 = snapshot.child('_Supplier_Name').val();break;
        case 6:part.Supplier6 = snapshot.child('_Supplier_Name').val();break;
      }
    }
  }

  async getCustomer(part:Part, customerId): Promise<void>{
    var snapshot = await this.db.database.ref('Customer').child(customerId).once('value');
    if(snapshot.exists()){
      part.Customer_Name = snapshot.child('_Customer_Name').val();
      part.Customer_Address = snapshot.child('_Address').val();
    }
  }

  async search_PartWithStock(Part_No: string): Promise<Part> {
    let searched_part = new Part;
    var snapshot = await this.db.database.ref('Part').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( childSnapshot=>{
        var get_Part_No = childSnapshot.key;
        if (Part_No.match(get_Part_No)) {
          searched_part.Part_No = childSnapshot.key;
          searched_part.Stock_Quantity = childSnapshot.child('Stock Quantity').val();
          const postocks : POStock[] = [];
          childSnapshot.child('Stock').forEach(child=>{
            const stock = new POStock();
            stock.BoxNumber = child.key;
            stock.Quantity = child.val();
            postocks.push(stock)
          })
          searched_part.POStocks = postocks;
        }
      });
    }

    return searched_part;
  }


}
