export class Weighting {
  private _PO_No: string;
  private _Weight_Part_List: WeightPartList[];
  public Type: string

  get PO_No(): string {
    return this._PO_No;
  }
  set PO_No(value: string) {
    this._PO_No = value;
  }

  get Weight_Part_List(): WeightPartList[] {
    return this._Weight_Part_List;
  }
  set Weight_Part_List(value: WeightPartList[]) {
    this._Weight_Part_List = value;
  }
}

export class PointList {
  public Unit: string;
  public Standard: string;
  public Tolerance: string;
  public Tool: string;
  public SampleList: SampleList[];
}

export class SampleList {
  public Reading: string;
}

export class WeightPartList {
  private _Part_No: string;
  private _Part_Name: string;
  private _JO_No: string;
  private _SO_No: string;
  private _PO_Qty: number;
  private _Inspect_Qty: number;
  private _Accept_Qty: number;
  private _Reject_Qty: number;
  private _Reject_Remark: string;
  private _Weight_Date: Date;
  private _WeightingInfo: BoxInfo;
  public RejectedInfos: RejectedInfo[] = [];
  public WorkingArea:string;
  public InspectionType:string;
  public UpdateBy:string;
  public Status:string;
  public customerName:string;
  public rev:string;
  public InspectedBy:string;
  public Due_Date: Date;
  public PointList: PointList[];

  get SO_No(): string {
    return this._SO_No;
  }
  set SO_No(value: string) {
    this._SO_No = value;
  }

  get JO_No(): string {
    return this._JO_No;
  }
  set JO_No(value: string) {
    this._JO_No = value;
  }

  get Part_No(): string {
    return this._Part_No;
  }
  set Part_No(value: string) {
    this._Part_No = value;
  }

  get Part_Name(): string {
    return this._Part_Name;
  }
  set Part_Name(value: string) {
    this._Part_Name = value;
  }

  get WeightingInfo(): BoxInfo {
    return this._WeightingInfo;
  }
  set WeightingInfo(value: BoxInfo) {
    this._WeightingInfo = value;
  }

  get PO_Qty(): number {
    return this._PO_Qty;
  }
  set PO_Qty(value: number) {
    this._PO_Qty = value;
  }

  get Inspect_Qty(): number {
    return this._Inspect_Qty;
  }
  set Inspect_Qty(value: number) {
    this._Inspect_Qty = value;
  }

  get Accept_Qty(): number {
    return this._Accept_Qty;
  }
  set Accept_Qty(value: number) {
    this._Accept_Qty = value;
  }

  get Reject_Qty(): number {
    return this._Reject_Qty;
  }
  set Reject_Qty(value: number) {
    this._Reject_Qty = value;
  }

  get Reject_Remark(): string {
    return this._Reject_Remark;
  }
  set Reject_Remark(value: string) {
    this._Reject_Remark = value;
  }

  get Weight_Date(): Date {
    return this._Weight_Date;
  }
  set Weight_Date(value: Date) {
    this._Weight_Date = value;
  }
}

export class RejectedInfo {
  public Code:string;
  public Quantity:number;

}

export class TakenbyPO {
  name:string;
  quantity:number;
}

export class BoxInfo {
  private _Box_No: string;
  private _Updated_Date: Date;
  private _Status: string;
  private _Packaging_Quantity: string;
  public Category: string;
  public TakenbyPO:TakenbyPO[]= [];
  public WarehouseLocation: string;
  public MadeToOrder: string;
  public MakeToStock: string;
  public RackingNo: string;


  get Box_No(): string {
    return this._Box_No;
  }
  set Box_No(value: string) {
    this._Box_No = value;
  }

  set Status(value: string) {
    this._Status = value;
  }
  get Status(): string {
    return this._Status;
  }
  set Packaging_Quantity(value: string) {
    this._Packaging_Quantity = value;
  }

  get Packaging_Quantity(): string {
    return this._Packaging_Quantity;
  }

  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

}
