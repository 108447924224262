export class Staff {
  public StaffName = '';
  public Role = '';
  public Email = '';
  public StaffNo = '';
  public StaffID = null;
  public Access = [];
  public UpdatedBy = '';
  public CreatedBy = '';
  public UpdatedDate: Date;
  public CreatedDate: Date;
  public password = '';
  public Schedule_Track: ScheduleTracker[] = [];
}

export class ScheduleTracker {
  public ID: string;
  public PO_No: string;
  public Customer: string;
  public Billing_Address: string;
  public Delivery_Address: string;
  public Updated_By : string;
  public Updated_Date : Date;
  public Status : string;
  public Sequence : string;
}
