import { element } from 'protractor';
import { AngularFireDatabase } from '@angular/fire/database';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { ScheduleTracker, Staff } from '../Object_Classes/Staff/Staff';
import { ReplaySubject } from 'rxjs';

let firebase = require("firebase");
const firebaseConfig = {
  apiKey: "AIzaSyANHtPBUXmWzjX5_LMQL28xiQTHpO4MeYA",
  authDomain: "hl-best.firebaseapp.com",
  databaseURL: 'https://hl-best-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: "hl-best",
  storageBucket: "hl-best.appspot.com",
  messagingSenderId: "191563572699",
  appId: "1:191563572699:web:08497d26bca33e3c2933fe",
  measurementId: "G-RZP6GJFRXF"
};

export class StaffDB_controller {
  app;
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }

  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getStaffList(): Promise<Staff[]> {
    let StaffList: Staff[] = [];
    var snapshot = await this.db.database.ref('User').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const staff = new Staff();
        if(childSnapshot.key != "-"){          
          staff.StaffID = childSnapshot.key;
          staff.StaffName = childSnapshot.child('StaffName').val();
          staff.StaffNo = childSnapshot.child('StaffNo').val();
          staff.Email = childSnapshot.child('Email').val();
          staff.Role = childSnapshot.child('Role').val();
          staff.UpdatedBy = childSnapshot.child('UpdatedBy').val();
          staff.CreatedBy = childSnapshot.child('CreatedBy').val();
          staff.CreatedDate = new Date(childSnapshot.child('CreatedDate').val());
          staff.UpdatedDate = new Date(childSnapshot.child('UpdatedDate').val());
        }
        

        if (staff.Role.toUpperCase() !== 'ADMIN') {
          childSnapshot.child('Access').forEach(snapshot2 => {
            staff.Access.push(snapshot2.key);
          })
        }

        let schedule_TrackList: ScheduleTracker[] = [];
        
        if(childSnapshot.child("Schedule") != null){
          childSnapshot.child("Schedule").forEach(childSnapshot2 => {
            if(childSnapshot2.child('_Status').val() != 'Completed'){
              const scheduleTracker = new ScheduleTracker();
  
              scheduleTracker.ID = childSnapshot2.key;
              scheduleTracker.PO_No = childSnapshot2.child('_CR_No').val();
              scheduleTracker.Customer = childSnapshot2.child('_Customer').val();
              scheduleTracker.Delivery_Address = childSnapshot2.child('_Delivery_Address').val();
              scheduleTracker.Billing_Address = childSnapshot2.child('_Billing_Address').val();
              scheduleTracker.Updated_Date = new Date(childSnapshot2.child('_Updated_Date').val());
              scheduleTracker.Updated_By = childSnapshot2.child('_Updated_By').val();
              scheduleTracker.Status = childSnapshot2.child('_Status').val();
              scheduleTracker.Sequence = childSnapshot2.child('_Sequence').val();
              schedule_TrackList.push(scheduleTracker);
            }
          });
        }
        

        staff.Schedule_Track = schedule_TrackList;
        StaffList.push(staff);
      });
    }

    StaffList = StaffList.filter(s => s.StaffName).sort((a, b) => {
      return (a.StaffName < b.StaffName ? -1 : 1) * (true ? 1 : -1);
    })

    return StaffList;
  }

  async getStaffListWithPendingJob(): Promise<Staff[]> {
    let StaffList: Staff[] = [];
    var snapshot = await this.db.database.ref('User').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const staff = new Staff();
        if(childSnapshot.key != "-"){          
          staff.StaffID = childSnapshot.key;
          staff.StaffName = childSnapshot.child('StaffName').val();
          staff.StaffNo = childSnapshot.child('StaffNo').val();
          staff.Email = childSnapshot.child('Email').val();
          staff.Role = childSnapshot.child('Role').val();
          staff.UpdatedBy = childSnapshot.child('UpdatedBy').val();
          staff.CreatedBy = childSnapshot.child('CreatedBy').val();
          staff.CreatedDate = new Date(childSnapshot.child('CreatedDate').val());
          staff.UpdatedDate = new Date(childSnapshot.child('UpdatedDate').val());
        }

        if (staff.Role.toUpperCase() !== 'ADMIN') {
          childSnapshot.child('Access').forEach(snapshot2 => {
            staff.Access.push(snapshot2.key);
          })
        }

        let schedule_TrackList: ScheduleTracker[] = [];
        
        if(childSnapshot.child("Schedule") != null){
          childSnapshot.child("Schedule").forEach(childSnapshot2 => {
            if(childSnapshot2.child('_Status').val() != 'Completed'){
              const scheduleTracker = new ScheduleTracker();
  
              scheduleTracker.ID = childSnapshot2.key;
              scheduleTracker.PO_No = childSnapshot2.child('_CR_No').val();
              scheduleTracker.Customer = childSnapshot2.child('_Customer').val();
              scheduleTracker.Delivery_Address = childSnapshot2.child('_Delivery_Address').val();
              scheduleTracker.Billing_Address = childSnapshot2.child('_Billing_Address').val();
              scheduleTracker.Updated_Date = new Date(childSnapshot2.child('_Updated_Date').val());
              scheduleTracker.Updated_By = childSnapshot2.child('_Updated_By').val();
              scheduleTracker.Status = childSnapshot2.child('_Status').val();
              scheduleTracker.Sequence = childSnapshot2.child('_Sequence').val();
              schedule_TrackList.push(scheduleTracker);
            }
          });
        }

        staff.Schedule_Track = schedule_TrackList;
        StaffList.push(staff);
      });
    }

    StaffList = StaffList.filter(s => s.StaffName).sort((a, b) => {
      return (a.StaffName < b.StaffName ? -1 : 1) * (true ? 1 : -1);
    })

    return StaffList;
  }

  async getNonAdminStaffList(): Promise<Staff[]> {
    let StaffList: Staff[] = [];
    var snapshot = await this.db.database.ref('User').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('Role').val() != "DIRECTOR" && childSnapshot.child('Role').val() != "ADMIN"){
          const staff = new Staff();
          staff.StaffID = childSnapshot.key;
          staff.StaffName = childSnapshot.child('StaffName').val();
          staff.StaffNo = childSnapshot.child('StaffNo').val();
          staff.Email = childSnapshot.child('Email').val();
          staff.Role = childSnapshot.child('Role').val();
          staff.UpdatedBy = childSnapshot.child('UpdatedBy').val();
          staff.CreatedBy = childSnapshot.child('CreatedBy').val();
          staff.CreatedDate = new Date(childSnapshot.child('CreatedDate').val());
          staff.UpdatedDate = new Date(childSnapshot.child('UpdatedDate').val());

          if (staff.Role.toUpperCase() !== 'ADMIN') {
            childSnapshot.child('Access').forEach(snapshot2 => {
              staff.Access.push(snapshot2.key);
            })
          }

          let schedule_TrackList: ScheduleTracker[] = [];
          
          childSnapshot.child("Schedule").forEach(childSnapshot2 => {
            const scheduleTracker = new ScheduleTracker();

            scheduleTracker.ID = childSnapshot2.key;
            scheduleTracker.PO_No = childSnapshot2.child('_CR_No').val();
            scheduleTracker.Customer = childSnapshot2.child('_Customer').val();
            scheduleTracker.Delivery_Address = childSnapshot2.child('_Delivery_Address').val();
            scheduleTracker.Billing_Address = childSnapshot2.child('_Billing_Address').val();
            scheduleTracker.Updated_Date = new Date(childSnapshot2.child('_Updated_Date').val());
            scheduleTracker.Updated_By = childSnapshot2.child('_Updated_By').val();
            scheduleTracker.Status = childSnapshot2.child('_Status').val();
            schedule_TrackList.push(scheduleTracker);
          });

          staff.Schedule_Track = schedule_TrackList;
          StaffList.push(staff);
        }
      });
    }

    StaffList = StaffList.filter(s => s.StaffName).sort((a, b) => {
      return (a.StaffName < b.StaffName ? -1 : 1) * (true ? 1 : -1);
    })

    return StaffList;
  }

  async add_Staff(_staff: Staff, email: string): Promise<ReplaySubject<any>> {
    let resultSubject = new ReplaySubject(1);
    if (!this.app) {
      this.app = firebase.initializeApp(firebaseConfig, "secondary");
    }
    await this.app.auth().createUserWithEmailAndPassword(_staff.Email, _staff.password)
      .then(async fbAuth => {
        var updates = {}
        _staff.CreatedDate = new Date();
        _staff.UpdatedDate = new Date();
        _staff.CreatedBy = email;
        _staff.UpdatedBy = email;
        updates['/' + fbAuth.user.uid + '/StaffName'] = _staff.StaffName;
        updates['/' + fbAuth.user.uid + '/Email'] = _staff.Email;
        updates['/' + fbAuth.user.uid + '/Role'] = _staff.Role;
        updates['/' + fbAuth.user.uid + '/StaffNo'] = _staff.StaffNo;
        updates['/' + fbAuth.user.uid + '/CreatedDate'] = _staff.CreatedDate;
        updates['/' + fbAuth.user.uid + '/UpdatedDate'] = _staff.UpdatedDate;
        updates['/' + fbAuth.user.uid + '/CreatedBy'] = _staff.CreatedBy;
        updates['/' + fbAuth.user.uid + '/UpdatedBy'] = _staff.UpdatedBy;
        for (const acc of _staff.Access) {
          updates['/' + fbAuth.user.uid + '/Access/' + acc] = "All"
        }
        await this.db.database.ref('User').update(updates).then(e => {
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            message: _staff.StaffName + ' has been added by the ' + email,
            date: new Date()
          };
          this.firestore.collection('StaffLog').doc(dateFormat).set({ Date: new Date() });
          this.firestore.collection('StaffLog').doc(dateFormat).collection('Staff').add(info);
          resultSubject.next(_staff);
        });

      })
      .catch(err => {
        resultSubject.error(err);
      })
    return resultSubject;

  }

  async update_Staff(_staff: Staff, email: string) {

    var updates = {}
    _staff.CreatedDate = new Date();
    _staff.UpdatedDate = new Date();
    _staff.CreatedBy = email;
    _staff.UpdatedBy = email;
    updates['/' + _staff.StaffID + '/StaffName'] = _staff.StaffName;
    updates['/' + _staff.StaffID + '/Role'] = _staff.Role;
    updates['/' + _staff.StaffID + '/StaffNo'] = _staff.StaffNo;
    updates['/' + _staff.StaffID + '/UpdatedDate'] = _staff.UpdatedDate;
    updates['/' + _staff.StaffID + '/UpdatedBy'] = _staff.UpdatedBy;
    for (const acc of _staff.Access) {
      updates['/' + _staff.StaffID + '/Access/' + acc] = "All"
    }
    await this.db.database.ref('User/'+_staff.StaffID +'/Access').set(null).then(async e=>{
      await this.db.database.ref('User').update(updates).then(e => {
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: _staff.StaffName + ' has been updated by the ' + email,
          date: new Date()
        };
        this.firestore.collection('StaffLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('StaffLog').doc(dateFormat).collection('Staff').add(info);
      });
    })
  }

  async searchStaffbyEmail(email: any): Promise<Staff> {
    const staff = new Staff();
    var snapshot = await this.db.database.ref('User').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('Email').val() == email){
          staff.StaffID = childSnapshot.key;
          staff.StaffName = childSnapshot.child('StaffName').val();
          staff.StaffNo = childSnapshot.child('StaffNo').val();
          staff.Email = childSnapshot.child('Email').val();
          staff.Role = childSnapshot.child('Role').val();
          staff.UpdatedBy = childSnapshot.child('UpdatedBy').val();
          staff.CreatedBy = childSnapshot.child('CreatedBy').val();
          staff.CreatedDate = new Date(childSnapshot.child('CreatedDate').val());
          staff.UpdatedDate = new Date(childSnapshot.child('UpdatedDate').val());
        }
      });
    }

    return staff;
  }

  delete_Staff(id: string) {
    this.db.database.ref('/User/' + id).set(null);
  }
}
