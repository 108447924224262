import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private role = new BehaviorSubject<string>('');
  private accessRight = new BehaviorSubject<any[]>([]);

  currentRole = this.role.asObservable();
  currentAccessRights = this.accessRight.asObservable();


  constructor() { }

  SetRole(role: string) {
    this.role.next(role);
  }


  setAccessRight(accessRight: any[]) {
    this.accessRight.next(accessRight);
  }

  checkRight(role, module, rightlist: any[]) {
    if (role === 'ADMIN' || role === 'DIRECTOR') {
      return true;
    }
    else {
      const result = rightlist.find(r => r.module.toLowerCase() === module.toLowerCase());
      
      if (result) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  checkPermission(role, module, rightlist: any[]) {
    if (role === 'ADMIN' || role === 'DIRECTOR') {
      return true;
    }
    else if (role !== 'ADMIN' && role !== 'DIRECTOR') {
      const result = rightlist.find(r => r.module.toLowerCase() === module.toLowerCase())
      if (result) {
        if (result.right === 'All') {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
  }
}
