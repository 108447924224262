import { SupplierDB_controller } from '../../../Services/DB_Controller/SupplierDB_controller';
import { RawMaterialInfo } from '../../../Services/Object_Classes/RawMaterial/RawMaterial';
import { RawDB_controller } from '../../../Services/DB_Controller/RawDB_controller';
import { RawMaterialOrder } from '../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { v4 as uuidv4 } from 'uuid';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { PurchaseOrderRaw } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';

@Component({
  selector: 'app-order-consumable',
  templateUrl: './order-consumable.component.html',
  styleUrls: ['./order-consumable.component.css']
})
export class OrderConsumableComponent implements OnInit {
  poNo: any;
  totalPart: any;
  partNumber: any;
  quantity: any;
  part: Part = new Part();
  machine: Machine = new Machine();
  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);
  RawDbController: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  supplierDbController: SupplierDB_controller = new SupplierDB_controller(this.db, this.firestore);

  rawlist: RawMaterialInfo[] = [];
  matSelectRaw: RawMaterialInfo[] = [];
  clone = [];
  cloneSup:Supplier[] = [];
  choosingRaw = [];

  addForm: FormGroup;
  newPO: PurchaseOrderRaw = new PurchaseOrderRaw();
  newPO_RawMaterial: RawMaterialOrder[] = [];
  startDate = [];
  endDate = [];
  searchRaw: any;
  check = false;
  supplier: Supplier[] = [];

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];
  minDate :Date;
  email: string;
  infolist = [];
  amountlist=[];
  constructor(
    private dialogRef: MatDialogRef<OrderConsumableComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
  ) {
    this.minDate = new Date();
    this.addForm = this.fb.group({
      name: '',
      raws: this.fb.array([]),
    });
    this.raws().push(this.newRaws());
    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    })
  }

  ngOnInit() {
    this.spinner.show();
    this.RawDbController.getRawList().then(data => {
      this.matSelectRaw = data;
      this.clone = this.matSelectRaw.slice();
      this.spinner.hide();
    });

    this.supplierDbController.getRawMatSupplierList().then(data => {
      this.supplier = data;
      this.cloneSup = this.supplier.slice();
      this.spinner.hide();
    });
  }

  raws(): FormArray {
    return this.addForm.get('raws') as FormArray;
  }

  newRaws(): FormGroup {
    const rawControl = new FormControl();
    const rawfilterControl = new FormControl();
    rawfilterControl.valueChanges.subscribe(() => {
      this.findRaw(rawfilterControl);
      if (this.clone) {
        this.check = true;
      }

    });
    rawControl.valueChanges.subscribe(() => {
      if (rawControl.value) {
        this.searchRaw = rawControl.value;
        this.check = true;
      }
    });

    const supplierControl = new FormControl();
    const supplierfilterControl = new FormControl();
    supplierfilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplierfilterControl);
    });



    return this.fb.group({
      rawControl,
      rawfilterControl,
      supplierControl,
      supplierfilterControl,
      quantity: '',
      stock: '',
      deliveryDate: new Date(),
      unit:'',
      unitPrice:'',
    });
  }

  findRaw(raw) {
    if (!this.clone) { return; }
    const search = raw.value;
    this.matSelectRaw = this.clone.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findSupplier(sup) {
    if (!this.cloneSup) { return; }
    this.supplier = this.cloneSup.filter(p => p.Supplier_Name.toLowerCase().includes(sup.value.toLowerCase()));
  }

  addRaws() {
    this.raws().push(this.newRaws());
    this.rawlist.push(null);
    this.infolist.push(null);
    this.amountlist.push(0);

  }

  removeRaws(i: number) {
    this.raws().removeAt(i);
    this.rawlist.splice(i, 1);
    this.choosingRaw.splice(i,1);
    this.infolist.splice(i,1);
    this.amountlist.splice(i,1);
  }

  cancel() {
    this.dialogRef.close(false);
  }


  existingName = false
  async nameValidation() {
    const name =  this.addForm.get('name').value.trim();
    if (name.includes('/') || name.includes('@') || name.includes('[')  || name.includes(']')  || name.includes('.')  || name.includes('#')  || name.includes('$') || name.includes('*') ) {
      this.existingName = true
    }else{
      var snapshot = await this.db.database.ref('PO RawMaterial/'+name).once('value');
      if(snapshot.exists())
        this.existingName = true;
      else
        this.existingName = false;
    }
  }


  async confirm() {
    if(this.existingName){
      this.toast.error('Same PO name already been existed', 'Please change');
      return;
    }
    const addPOModel = {
      POName: '',
      raws: []
    };

    var snapshot = await this.db.database.ref('miscellaneous').once('value');

    if (snapshot.exists()) {
      addPOModel.POName = snapshot.child('PR No').val();
      this.db.database.ref('miscellaneous/PR No').set((parseInt(snapshot.child('PR No').val()) + 1).toString());
    }

    const raws = this.addForm.get('raws').value;
    var flag = true;
    raws.forEach((element, index) => {

      if (!this.rawlist[index] || !element.quantity || !element.supplierControl || !this.infolist[index] ) {
        this.toast.error('Raw information(' + (index + 1) + ') not completed!', 'Please fill in');
        flag = false;
      }
      const info = {
        materialID: this.rawlist[index].Material_ID,
        materialName: this.rawlist[index].Material_Name,
        quantity_perBag: this.rawlist[index].Quantity_PerBag,
        quantity: element.quantity,
        supplier: this.cloneSup.find(s=>s.Supplier_Name === element.supplierControl),
        unitPrice: this.infolist[index],
        unit: this.rawlist[index].Unit,
        amount: this.amountlist[index],
        deliveryDate: element.deliveryDate,
        status: 'Pending',
        orderID: uuidv4()
      };
      addPOModel.raws.push(info);
    });
    if(!flag)
      return;
    if (addPOModel.raws.length === 0) {
      this.toast.error('Please add at least one raw material', 'Add raw material');
      return;
    }
    if (!addPOModel.POName ) {
      this.toast.error('Please fill in the PR name', 'Please fill in');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this PR?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PORawController.add_Consumable_PO(addPOModel, this.email);
        this.dialogRef.close(true);
      }
    });

  }

  getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  
  getRawMaterials(raw: any, formindex) {
    const sameRaw = this.choosingRaw.find(c=>c.raw === raw && c.index !==formindex);
    if(sameRaw){
      this.toast.warning("Same raw materials has been ordered, please choose others", "Warning");
      return;
    }
    if (raw) {
      this.RawDbController.search_RawByName(raw).then(data => {
        this.rawlist[formindex] = data;
        const info = {
          raw:raw,
          index: formindex,
        }
        this.choosingRaw.push(info);
        this.infolist[formindex] = data.Unit_Price;

      }).finally( ()=>{
        this.onchange(formindex)
      });

    }

  }


  viewRaw(materials) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = materials;

    this.dialog.open(ViewRawMaterialsComponent, dialogConfig).afterClosed().subscribe(result => {});

  }



  onchange(index){
    const raws = this.addForm.get('raws').value;
    if(raws[index].quantity && this.infolist[index]){
      this.amountlist[index] = (parseFloat( raws[index].quantity) * parseFloat( this.infolist[index])).toFixed(2);
    }
  }




}
