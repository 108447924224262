import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { SelectMachineSlotComponent } from '../../scheduling/add-event/select-machine-slot/select-machine-slot.component';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AddPartComponent } from '../../parts/PopUpModal/add-part/add-part.component';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';

@Component({
  selector: 'app-add-po',
  templateUrl: './add-po.component.html',
  styleUrls: ['./add-po.component.css']
})
export class AddPOComponent implements OnInit {
  poNo: any;
  totalPart: any;
  partNumber: any;
  quantity: any;
  part: Part = new Part();
  part_controller: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);
  po_controller: PODB_controller = new PODB_controller(this.db);
  CustomerDB_controller: CustomerDB_controller = new CustomerDB_controller(this.db,this.firestore);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);
  partlist: Part[] = [];
  matSelectCustomer: Customer[] = [];
  customerlist: Customer[] = [];
  matSelectPart: Part[] = [];
  clone = [];
  runningScheduleList = [];
  estimateTotalTime = [];
  choosingPart= [];
  timeslots = [];
  dateslots = [];
  addForm: FormGroup;
  newPO: PurchaseOrder = new PurchaseOrder();
  newPO_PartList: PartTracker[] = [];
  searchPart: any;
  email: string;
  existingName = false;
  forecastChecked = false;
  customerName = '';
  staffName = '';
  statusTo = '';
  check = false;
  Staff: Staff[] = [];
  CloneStaff: Staff[] = [];
  @ViewChild('picker', {static: true}) picker: any;

  public date: moment.Moment;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  constructor(
    private dialogRef: MatDialogRef<AddPOComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private spinner : NgxSpinnerService

  ) {
    const customerfilterControl = new FormControl();

    this.addForm = this.fb.group({
      customer: '',
      assignTo: '',
      billingAddress: '',
      deliveryAddress: '',
      remark: '',
      statusTo: 'Checking',
      customerfilterControl
    });

    customerfilterControl.valueChanges.subscribe(() => {
      this.onValueChanged(customerfilterControl);
    });

    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    });

    this.statusTo = "";
    $('#statusTo').val('');
    //this.addParts();
  }
  
  async nameValidation() {
    const name = this.addForm.get('name').value.trim();
    if (name.includes('/') || name.includes('@') || name.includes('[')  || name.includes(']')  || name.includes('.')  || name.includes('#')  || name.includes('$') || name.includes('*')) {
      this.existingName = true
    }
    else{
      var snapshot = await this.db.database.ref('Purchase Order/' + name).once('value');
      if (snapshot.exists()) {
        this.existingName = true;
      }
      else {
        this.existingName = false;
        this.poNo = name;
      }
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.part_controller.getPartList().then(data => {
      this.matSelectPart = data;
      this.clone = this.matSelectPart.slice();
      this.spinner.hide();
    });

    this.CustomerDB_controller.getCustomerList().then(data => {
      this.matSelectCustomer = data;
      this.customerlist = this.matSelectCustomer.slice();
      this.spinner.hide();
    });

    this.Staff = [];

    this.StaffDB_controller.getStaffList().then(data => {
      data.forEach(staff => {
        //if(staff.Role == 'TECHNICIAN'){
          this.Staff.push(staff);
        //}
      });

      this.spinner.hide();
    });
  }

  parts(): FormArray {
    return this.addForm.get('parts') as FormArray;
  }

  newParts(): FormGroup {

    const partControl = new FormControl();
    const partNameControl = new FormControl();
    const partfilterControl = new FormControl();

    partfilterControl.valueChanges.subscribe(() => {
      this.findPart(partfilterControl);
      if (this.clone) {
        this.check = true;

      }

    });
    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        this.searchPart = partControl.value;
        this.check = true;
      }
    });

    return this.fb.group({
      partControl,
      partNameControl,
      partfilterControl,
      partNumber: '',
      availableStock: '',
      poquantity: '',
      pos:'',
      reference:'',
      StartDate: new Date(),
      EndDate: new Date(),
    });
  }

  findPart(part) {
    if (!this.clone) { return; }
    const search = part.value;
    this.matSelectPart = this.clone.filter(p => p.Part_No.toLowerCase().includes(search.toLowerCase()));
  }

  addParts() {
    this.parts().push(this.newParts());
    this.partlist.push(null);
  }

  removeParts(i: number) {
    this.parts().removeAt(i);
    this.partlist.splice(i, 1);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  addPart(){
    const dialogRefaddPart = this.dialog.open(AddPartComponent, {
      width: '90%',
      height: '90%'
    });

    var newPart = new Part();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        newPart = result;
        this.spinner.show();
        this.part_controller.add_or_update_Part(newPart);
        this.toast.success(newPart.Part_Name + " has been added successfully!","Added Successfully");
        this.part_controller.getPartList().then(data => {
          this.matSelectPart = data;
          this.clone = this.matSelectPart.slice();
          this.spinner.hide();
        });
      }
    });
  }

  confirm() {
    if(this.addForm.get('customer').value == null || this.addForm.get('customer').value == ''){
      this.toast.error('Please Choose Customer', 'Please Choose customer!');
      return;
    }

    if(this.addForm.get('billingAddress').value == null || this.addForm.get('billingAddress').value == ''){
      this.toast.error('Please Enter Billing Address', 'Please Enter Billing Address!');
      return;
    }

    if(this.addForm.get('deliveryAddress').value == null || this.addForm.get('deliveryAddress').value == ''){
      this.toast.error('Please Enter delivery address', 'Please enter delivery address!');
      return;
    }

    const addPOModel = {
      CusId : this.addForm.get('customer').value,
      CusName : this.customerName,
      assignTo: this.addForm.get('assignTo').value || "",
      statusTo: this.statusTo || "Created",
      assignName: this.staffName || "",
      billingAddress : this.addForm.get('billingAddress').value,
      deliveryAddress : this.addForm.get('deliveryAddress').value,
      remark: this.addForm.get('remark').value || "",
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this Customer Request?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.po_controller.addPOWithoutSchedule(addPOModel,this.email);
        this.dialogRef.close(addPOModel);
      }
    });

  }

 getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( let i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  /*getPart(part,formindex){
    const samePart = this.choosingPart.find(c=>c.part === part && c.index !==formindex);
    
    if(samePart){
      this.toast.warning("Same part number has been ordered, please choose others", "Warning");
      return;
    }

    if (part) {
      this.part_controller.search_Part(part).then(data => {
        this.partlist[formindex] = data;
        const info = {
          part:part,
          index: formindex,
        }
        this.choosingPart.push(info);

      });
    }
  }*/

  getPart(formindex){
    var partNo = $('#partNo').val();
    const samePart = this.matSelectPart.find(c=>c.Part_No === partNo);
    
    if (samePart) {
      this.part_controller.search_Part(partNo.toString()).then(data => {
        this.partlist[formindex] = data;
        const info = {
          part:partNo,
          index: formindex,
        }

        //this.choosingPart.push(info);
      });
    }
  }

  parFloat(value){
    return parseFloat(value);
  }

  chooseCustomer(po: Customer){
    this.customerName = this.matSelectCustomer.find(a=>a.Customer_ID == po.Customer_ID).Customer_Name;
    this.addForm.controls['billingAddress'].setValue(po.Address);
    this.addForm.controls['deliveryAddress'].setValue(po.Address2);
  }

  chooseAssigned(po: Staff = null){
    if(po != null){
      this.staffName = po.StaffName;
    }
    else{
      this.staffName = null;
    }
  }

  loadAssigned(po: string){
    if(po != null){
      this.statusTo = po;
    }
    else{
      this.statusTo = null;
    }
  }

  change() {
    if (this.forecastChecked) {
      this.addForm.get('deliveryAddress').disable();
      this.addForm.get('deliveryAddress').setValue(this.addForm.controls['billingAddress'].value);
    } 
    else {
      this.addForm.get('deliveryAddress').enable();
      this.addForm.get('deliveryAddress').setValue('');
    }
  }

  onValueChanged(cus) {
    if (! this.customerlist) { return; }
    const search = cus.value;
    this.matSelectCustomer =  this.customerlist.filter(p => p.Customer_Name.toLowerCase().includes(search.toLowerCase()));
  }
}
