import { UserInfoService } from './../../../Services/Utilities/user-info.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hidePassword = true;
  hidePasswordR = true;
  hideConfirmPasswordR = true;


  loginModel: any = {};
  registerModel: any = {};
  flag = true;
  validationsMessages = ' ';
  today = Date.now();
  breakpoint: boolean;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private spinner: NgxSpinnerService,
    private userinfoSrv: UserInfoService,
    private dialog:MatDialog
  ) {

    this.spinner.show();
    this.angularFireAuth.authState.subscribe(async authState =>{
      if(authState === null){
        this.spinner.hide();
      } else{
        const snapshot = await this.db.database.ref('Operators').child(authState.uid).once('value');
        if (snapshot.exists()) {
          this.router.navigate(['home/operator']);
          this.spinner.hide();

        }else{
          const userSnapshot = await this.db.database.ref('User').child(authState.uid).once('value');
          if(userSnapshot.exists()){

            const role = userSnapshot.child('Role').val();
            this.userinfoSrv.SetRole(role)
            const accessRights = []
            if(role !== 'Admin'){
              userSnapshot.child('Access').forEach(e=>{
                const info={
                  module: e.key,
                  right: e.val(),
                }
                accessRights.push(info);
              })
            }
            this.userinfoSrv.setAccessRight(accessRights);
            this.router.navigate(['home']);
            this.spinner.hide();

          }
        }
      }
    })

  }

  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  changeForm() {
    this.flag = !this.flag;
  }

  forgotPassword() {
    this.dialog.open(ForgotPasswordComponent,{
      height:'70%',
      width:'70%',
      disableClose:true
    })
  }

  async login() {
    const email = this.loginModel.email;
    const password = this.loginModel.password;
    var count = 0;
    const inside = await this.db.database.ref('User').once('value');
    
    if(inside.exists()){
      inside.forEach((childSnapshot) => {
        if(childSnapshot.child('StaffNo').val() == email || childSnapshot.child('Email').val() == email){
          this.angularFireAuth.auth.signInWithEmailAndPassword(childSnapshot.child('Email').val(), password).then(async res => {
            this.toast.success('Welcome to HL Best Forklift', 'Login Successfully !', { timeOut: 3000 });
            const snapshot = await this.db.database.ref('Operators').child(res.user.uid).once('value');

            if (snapshot.exists()) {
              this.router.navigate(['home/operator']);
            }
            else {
              const userSnapshot = await this.db.database.ref('User').child(res.user.uid).once('value');

              if(userSnapshot.exists()){
                const role = userSnapshot.child('Role').val();
                this.userinfoSrv.SetRole(role)
                const accessRights = []
                if(role !== 'Admin'){
                  userSnapshot.child('Access').forEach(e=>{
                    const info={
                      module: e.key,
                      right: e.val(),
                    }
                    accessRights.push(info);
                  })
                }
                this.userinfoSrv.setAccessRight(accessRights);
                this.router.navigate(['home']);
              }
            }
          })
          .catch(err => {
            this.validationsMessages = 'Invalid email or password.';
          });
        }

        count++;
      });
    }
  }
}
