export class Customer{
  private _Customer_ID: string;
  private _Customer_Name: string;
  private _Person_In_Charge: string;
  private _Address: string;
  private _Address2: string;
  private _Address3: string;
  private _Address4: string;
  private _Contact: string;
  private _Contact2: string;
  private _Contact3: string;
  private _Fax: string;
  private _Email: string;
  private _Email2: string;
  private _Email3: string;
  
  private _Last_Updated: Date;
  private _Created_Date: Date;
  private _Updated_By: string;
  private _Created_By: string;

  get Customer_ID(): string {
    return this._Customer_ID;
  }
  set Customer_ID(value: string) {
    this._Customer_ID = value;
  }
  
  get Customer_Name(): string {
    return this._Customer_Name;
  }
  set Customer_Name(value: string) {
    this._Customer_Name = value;
  }

  get Person_In_Charge(): string {
    return this._Person_In_Charge;
  }
  set Person_In_Charge(value: string) {
    this._Person_In_Charge = value;
  }

  get Address(): string {
    return this._Address;
  }
  set Address(value: string) {
    this._Address = value;
  }

  get Address2(): string {
    return this._Address2;
  }
  set Address2(value: string) {
    this._Address2 = value;
  }

  get Address3(): string {
    return this._Address3;
  }
  set Address3(value: string) {
    this._Address3 = value;
  }

  get Address4(): string {
    return this._Address4;
  }
  set Address4(value: string) {
    this._Address4 = value;
  }

  get Contact(): string {
    return this._Contact;
  }
  set Contact(value: string) {
    this._Contact = value;
  }

  get Contact2(): string {
    return this._Contact2;
  }
  set Contact2(value: string) {
    this._Contact2 = value;
  }

  get Contact3(): string {
    return this._Contact3;
  }
  set Contact3(value: string) {
    this._Contact3 = value;
  }

  get Fax(): string {
    return this._Fax;
  }
  set Fax(value: string) {
    this._Fax = value;
  }

  get Email(): string {
    return this._Email;
  }
  set Email(value: string) {
    this._Email = value;
  }

  get Email2(): string {
    return this._Email2;
  }
  set Email2(value: string) {
    this._Email2 = value;
  }

  get Email3(): string {
    return this._Email3;
  }
  set Email3(value: string) {
    this._Email3 = value;
  }

  get Last_Updated(): Date {
    return this._Last_Updated;
  }
  set Last_Updated(value: Date) {
    this._Last_Updated = value;
  }
  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }

  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }

}
