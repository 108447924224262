import { AngularFirestore } from '@angular/fire/firestore';
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import Chart from 'chart.js';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { PartServices } from 'src/app/Services/Utilities/part.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import * as XLSX from 'xlsx';
import { AngularFireAuth } from '@angular/fire/auth';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  
  pieChart: Chart;
  pieChart2: Chart;
  pieChart3: Chart;
  arrayBuffer:any;

  POlist: PurchaseOrder[] = [];
  AssignedPOlist: PurchaseOrder[] = [];
  DiagnosedPOlist: PurchaseOrder[] = [];
  QuotedPOlist: PurchaseOrder[] = [];
  ServicedPOlist: PurchaseOrder[] = [];
  InvoicedPOlist: PurchaseOrder[] = [];
  RejectedPOlist: PurchaseOrder[] = [];

  CompletedPOList: PurchaseOrder[] = [];
  OneStarPOList: PurchaseOrder[] = [];
  TwoStarPOList: PurchaseOrder[] = [];
  ThreeStarPOList: PurchaseOrder[] = [];
  FourStarPOList: PurchaseOrder[] = [];
  FiveStarPOList: PurchaseOrder[] = [];

  Staff: Staff[] = [];

  PODB_controller: PODB_controller = new PODB_controller(this.db);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);

  selected: any;
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  maxDate: dayjs.Dayjs;
  minDate: dayjs.Dayjs;
  invalidDates: dayjs.Dayjs[] = [];
  tooltips = [{date: dayjs(), text: 'Today is just unselectable'}, {date: dayjs().add(2, 'days'), text: 'Yeeeees!!!'}];
  inlineDateTime;
  ranges: any = {
    Today: [dayjs(), dayjs()],
    Yesterday: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
    'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
    'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
  };

  isInvalidDate = (m: dayjs.Dayjs) =>  {return this.invalidDates.some(d => d.isSame(m, 'day') );}

  isTooltipDate = (m: dayjs.Dayjs) =>  {
    const tooltip = this.tooltips.find(tt => tt.date.isSame(m, 'day'));

    if (tooltip) {
      return tooltip.text;
    } 
    else {
      return false;
    }
  }

  constructor(
    public dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private toast: ToastrService,
    private zone: NgZone,
    private partSrv: PartServices,
    private changeDef: ChangeDetectorRef
  ) { 
    this.maxDate = dayjs().add(2,  'weeks');
    this.minDate = dayjs().subtract(3, 'days');
    this.alwaysShowCalendars = true;
    this.keepCalendarOpeningWithRange = true;
    this.showRangeLabelOnInput = true;
    this.selected = {startDate: dayjs().subtract(6, 'days'), endDate: dayjs()};
    this.setup();
  }

  datesUpdated(range) {
    this.getDashboard(range.startDate.$d, range.endDate.$d);
  }

  ngOnInit() {
    $('.md-drppicker').css("top", "auto");
    $('.md-drppicker').css("left", "0px");
    $('.md-drppicker').css("right", "auto");
    $('.md-drppicker').css("width", "600px");
  }

  ngAfterViewInit(): void {
    this.pieChart = new Chart('pieChart', {
      plugins: [ChartDataLabels],
      type: 'pie',
      data: {
        labels: ["Checking", "Quotation", "Rejected", "Servicing", "Invoicing", "Invoiced"],
        datasets: [{
          backgroundColor: ['#00ffcc', '#ffcc66', '#808080', '#000000', '#ff6666', '#ff66e5'],
          data: [0, 0, 0, 0, 0, 0],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(ctx.chart.data.labels[ctx.dataIndex] === 'No PO')
                return "";
              if(value === 0)
                return "";
              return value;
            },
            color: '#000',
            font: {
              weight: 'bold',
              size: 16,
            }
          }
        }
      }
    });

    this.pieChart2 = new Chart('pieChart2', {
      plugins: [ChartDataLabels],
      type: 'pie',
      data: {
        labels: ["1 Stars", "2 Stars", "3 Stars", "4 Stars", "5 Stars"],
        datasets: [{
          backgroundColor: ['#00ffcc', '#ffcc66', '#808080', '#ff66e5', '#ff6666'],
          data: [0, 0, 0, 0, 0, 0],
        }]
      },
      options: {
        legend: {
          position: 'right'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(ctx.chart.data.labels[ctx.dataIndex] === 'No PO')
                return "";
              if(value === 0)
                return "";
              return value;
            },
            color: '#000',
            font: {
              weight: 'bold',
              size: 16,
            }
          }
        }
      }
    });

    /*this.pieChart3 = new Chart('bar', {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Assigned',
            data: 0,
            backgroundColor: '#00ffcc',
          },
          {
            label: 'Checked',
            data: 1,
            backgroundColor: '#ffcc66',
          },
          {
            label: 'Quoted',
            data: 0,
            backgroundColor: '#808080',
          },
          {
            label: 'Serviced',
            data: 3,
            backgroundColor: '#ff6666',
          }
        ]
      },
      options: {
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      }
    });*/
  }

  setup() {
    this.spinner.show();
    this.initPO();
    //this.initStaff();
  }

  choosedDateTime(e) {
    this.inlineDateTime = e;
  }

  async getDashboard(start, end){
    this.AssignedPOlist = this.POlist.filter(r=> r.Status == 'Checking'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    this.DiagnosedPOlist = this.POlist.filter(r=> r.Status == 'Quotation'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());
    
    this.QuotedPOlist = this.POlist.filter(r=> r.Status == 'Servicing'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    this.ServicedPOlist = this.POlist.filter(r=> r.Status == 'Invoicing'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    this.InvoicedPOlist = this.POlist.filter(r=> r.Status == 'Invoiced'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());
    
    this.RejectedPOlist = this.POlist.filter(r=> r.Status == 'Rejected'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    if(this.AssignedPOlist.length > 0){
      this.pieChart.data.datasets[0].data[0] = this.AssignedPOlist.length;
    }
    else{
      this.pieChart.data.datasets[0].data[0] = 0;
    }

    if(this.DiagnosedPOlist.length > 0){
      this.pieChart.data.datasets[0].data[1] = this.DiagnosedPOlist.length;
    }
    else{
      this.pieChart.data.datasets[0].data[1] = 0;
    }

    if(this.QuotedPOlist.length > 0){
      this.pieChart.data.datasets[0].data[3] = this.QuotedPOlist.length;
    }
    else{
      this.pieChart.data.datasets[0].data[3] = 0;
    }

    if(this.ServicedPOlist.length > 0){
      this.pieChart.data.datasets[0].data[4] = this.ServicedPOlist.length;
    }
    else{
      this.pieChart.data.datasets[0].data[4] = 0;
    }

    if(this.InvoicedPOlist.length > 0){
      this.pieChart.data.datasets[0].data[5] = this.InvoicedPOlist.length;
    }
    else{
      this.pieChart.data.datasets[0].data[5] = 0;
    }

    if(this.RejectedPOlist.length > 0){
      this.pieChart.data.datasets[0].data[2] = this.RejectedPOlist.length;
    }
    else{
      this.pieChart.data.datasets[0].data[2] = 0;
    }
    
    this.pieChart.update();

    this.OneStarPOList = this.CompletedPOList.filter(r=> r.Rating == '1'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    this.TwoStarPOList = this.CompletedPOList.filter(r=> r.Rating == '2'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    this.ThreeStarPOList = this.CompletedPOList.filter(r=> r.Rating == '3'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    this.FourStarPOList = this.CompletedPOList.filter(r=> r.Rating == '4'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    this.FiveStarPOList = this.CompletedPOList.filter(r=> r.Rating == '5'
      && r.Created_Date.getTime() >= start.getTime() 
      && r.Created_Date.getTime()<= end.getTime());

    if(this.OneStarPOList.length > 0){
      this.pieChart2.data.datasets[0].data[0] = this.OneStarPOList.length;
    }
    else{
      this.pieChart2.data.datasets[0].data[0] = 0;
    }

    if(this.TwoStarPOList.length > 0){
      this.pieChart2.data.datasets[0].data[1] = this.TwoStarPOList.length;
    }
    else{
      this.pieChart2.data.datasets[0].data[1] = 0;
    }

    if(this.ThreeStarPOList.length > 0){
      this.pieChart2.data.datasets[0].data[2] = this.ThreeStarPOList.length;
    }
    else{
      this.pieChart2.data.datasets[0].data[2] = 0;
    }

    if(this.FourStarPOList.length > 0){
      this.pieChart2.data.datasets[0].data[3] = this.FourStarPOList.length;
    }
    else{
      this.pieChart2.data.datasets[0].data[3] = 0;
    }

    if(this.FiveStarPOList.length > 0){
      this.pieChart2.data.datasets[0].data[4] = this.FiveStarPOList.length;
    }
    else{
      this.pieChart2.data.datasets[0].data[4] = 0;
    }
    
    this.pieChart2.update();

    /*if(this.Staff.length > 0){
      this.removeData(this.pieChart3);
      
      await this.Staff.forEach(element => {
        if(element.Schedule_Track.length > 0){
          
        }
        else{
          this.addData(this.pieChart3, data);
        }
      });
    }*/
  }

  removeData(chart) {
    chart.data.datasets.forEach((dataset) => {
      dataset.data.pop();
    });

    chart.update();
  }

  addData(chart, data) {
    chart.data.datasets.forEach((dataset) => {
      dataset.data.push(data);
    });

    chart.update();
  }

  initPO(){
    this.PODB_controller.getPOList().then(data => {
      this.POlist = data;
      this.getDashboard(this.ranges['This Month'][0].$d, this.ranges['This Month'][1].$d);
      this.spinner.hide();
    });

    this.PODB_controller.search_PO_withStatusCompleted().then(data => {
      this.CompletedPOList = data;
      this.getDashboard(this.ranges['This Month'][0].$d, this.ranges['This Month'][1].$d);
      this.spinner.hide();
    });
  }

  initStaff(){
    this.StaffDB_controller.getNonAdminStaffList().then(data => {
      this.Staff = data;

      /*this.Staff.forEach(element => {
        this.pieChart3.data.labels.push(element.StaffName);
      });*/

      this.spinner.hide();
    });
  }
}
