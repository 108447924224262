import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AddStaffComponent } from './add-staff/add-staff.component';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {

  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);

  //Pagination Staff
  lengthStaff = 0;
  pageSizeStaff  = 10;
  pageSizeOptionsStaff : number[] = [10, 25, 50];
  pageIndexStaff  = 0;
  offsetStaff  = this.pageSizeStaff  * this.pageIndexStaff ;
  searchStaff : string;
  sorteduStaff  = {
    active: '',
    direction: ''
  };
  Staff: Staff[] = [];
  CloneStaff: Staff[] = [];
  @ViewChild('topPaginatorStaff', { read: MatPaginator, static: false }) topPaginatorStaff : MatPaginator;
  @ViewChild('bottomPaginatorStaff', { read: MatPaginator, static: false }) bottomPaginatorStaff : MatPaginator;
  email:string;
  role:string;
  acccessRight:any[]=[];
  disableDelete = true;

  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
    private excelHelper: ExcelHelperService,
    private firestore: AngularFirestore,
    private userinfoSrv:UserInfoService,

  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });
    this.userinfoSrv.currentAccessRights.subscribe(access=>{
      this.acccessRight = access
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });

   }

  ngOnInit() {
    this.initStaff();
  }

  refresh(){
    this.initStaff();
  }

  initStaff(){
    this.StaffDB_controller.getStaffList().then(data => {
      this.Staff = data;
      this.lengthStaff = this.Staff.length;
      this.CloneStaff = this.Staff.slice();
      this.limitListStaff();
      this.spinner.hide();
    });
  }

  DynamicSearchRawStaff(Staffname: string): void {
    this.searchStaff = Staffname;
    this.CloneStaff = this.Staff.filter(u =>
      String(u.StaffName).toLowerCase().includes(this.searchStaff.toLowerCase()));
    this.lengthStaff = this.CloneStaff.length;
    this.sortRawListStaff();
    this.limitListStaff();
  }
  DSStaff() {
    this.CloneStaff = this.Staff.filter(u =>
      String(u.StaffName).toLowerCase().includes(this.searchStaff.toLowerCase()));
    this.lengthStaff = this.CloneStaff.length;
  }

  paginatorStaff(pageEvent: PageEvent) {
    this.pageSizeStaff = pageEvent.pageSize;
    this.pageIndexStaff = pageEvent.pageIndex;
    this.offsetStaff = this.pageSizeStaff * this.pageIndexStaff;
    if (this.topPaginatorStaff.pageIndex < this.pageIndexStaff) {
      this.topPaginatorStaff.nextPage();
    } else if (this.topPaginatorStaff.pageIndex > this.pageIndexStaff) {
      this.topPaginatorStaff.previousPage();
    }
    if (this.bottomPaginatorStaff.pageIndex < this.pageIndexStaff) {
      this.bottomPaginatorStaff.nextPage();
    } else if (this.bottomPaginatorStaff.pageIndex > this.pageIndexStaff) {
      this.bottomPaginatorStaff.previousPage();
    }
    if (this.searchStaff) {
      this.DSStaff();
    } else {
      this.CloneStaff = this.Staff.slice();
    }
    this.sortRawListStaff();
    this.limitListStaff();
  }
  limitListStaff() {
    this.CloneStaff = this.CloneStaff.slice(this.offsetStaff, (this.offsetStaff + this.pageSizeStaff));
  }
  sortRawListStaff() {
    if (!this.sorteduStaff.active || this.sorteduStaff.direction === '') {
      return;
    }
    this.CloneStaff = this.CloneStaff.sort((a, b) => {
      const isAsc = this.sorteduStaff.direction === 'asc';
      switch (this.sorteduStaff.active) {
        case 'name': return this.compare(a.StaffName, b.StaffName, isAsc);
        case 'no': return this.compare(a.StaffNo, b.StaffNo, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        case 'Role': return this.compare(a.Role, b.Role, isAsc);
        case 'createdDate': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'createdBy': return this.compare(a.CreatedBy, b.CreatedBy, isAsc);
        case 'updatedBy': return this.compare(a.UpdatedBy, b.UpdatedBy, isAsc);
        case 'UpdateDate': return this.compareDate(a.UpdatedDate, b.UpdatedDate, isAsc);
        default: return 0;
      }
    });
  }
  sortDataStaff(sort: Sort) {
    this.sorteduStaff = sort;
    this.CloneStaff = this.Staff.slice();
    if (this.searchStaff) {
      this.DSStaff();
    }
    if (!sort.active || sort.direction === '' && !this.searchStaff) {
      this.CloneStaff = this.Staff.slice();
      this.limitListStaff();
      return;
    }

    this.CloneStaff = this.CloneStaff.sort((a, b) => {
      const isAsc = this.sorteduStaff.direction === 'asc';
      switch (this.sorteduStaff.active) {
        case 'name': return this.compare(a.StaffName, b.StaffName, isAsc);
        case 'no': return this.compare(a.StaffNo, b.StaffNo, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        case 'Role': return this.compare(a.Role, b.Role, isAsc);
        case 'createdDate': return this.compareDate(a.CreatedDate, b.CreatedDate, isAsc);
        case 'createdBy': return this.compare(a.CreatedBy, b.CreatedBy, isAsc);
        case 'updatedBy': return this.compare(a.UpdatedBy, b.UpdatedBy, isAsc);
        case 'UpdateDate': return this.compareDate(a.UpdatedDate, b.UpdatedDate, isAsc);

        default: return 0;
      }
    });
    this.limitListStaff();
  }

  ViewStaff(Staff: Staff){
    const dialogRef = this.dialog.open(AddStaffComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: {...Staff}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.StaffDB_controller.update_Staff(result, this.email);
        this.toast.success(Staff.StaffName +" has been updated","Success");

        this.initStaff();
      }
    });
  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }


  AddStaff(){
    const dialogRef = this.dialog.open(AddStaffComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        let r = await this.StaffDB_controller.add_Staff(result, this.email);
        if(r.hasError){
          this.toast.error("Same email has been exist","Email Error");
        }else{
          this.toast.success("New Staff has been added","Success");
          this.initStaff();
        }
      }
    });
  }
  
  deleteStaff(Staff : Staff){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this Staff? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.StaffDB_controller.delete_Staff(Staff.StaffID);
        this.initStaff();
      }
    })
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  exportToExcelStaff() {
    const exportInformation = [];
    this.spinner.show();
    const staff = this.Staff.sort((a,b)=>{
      return (a.StaffName < b.StaffName ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const staf of staff) {
      const info ={
        "Staff No":staf.StaffNo || "-",
        "Staff Name":staf.StaffName || "-",
        "Position":staf.Role || "-",
        "Email":staf.Email || "-",
        "Updated By":staf.UpdatedBy || "-",
        "Updated Date":staf.UpdatedDate || "-",
        "Created By":staf.CreatedBy || "-",
        "Created Date":staf.CreatedDate || "-",
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Staff'+new Date().getTime());
    this.spinner.hide();

  }
}
