import { Machine } from './../Object_Classes/Machine/Machine';
import { TakeFromStock } from './../Object_Classes/PackingList/PackingList';
import { BoxShippingInfo, CheckOut, Forklift, RawCheckOut } from './../Object_Classes/PurchaseOrder/PurchaseOrder';
import { AngularFireDatabase } from '@angular/fire/database';
import { PurchaseOrder, PartTracker } from '../Object_Classes/PurchaseOrder/PurchaseOrder';
import { RawMaterialInfo } from '../Object_Classes/RawMaterial/RawMaterial';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { v4 as uuidv4 } from 'uuid';

export class PODB_controller {
  private firestore: AngularFirestore;
  private dateFormat = new DateFormatService();

  constructor(private db: AngularFireDatabase) {}
  
  /************** Purchase Order ****************/
  async getPOList(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    var snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        let PO_item = new PurchaseOrder();
        PO_item.PO_No = childSnapshot.key;
        PO_item.Customer = childSnapshot.child('_Customer').val();
        PO_item.Customer_ID = childSnapshot.child('_Customer_ID').val();
        this.getCustomerInfo(PO_item);
        PO_item.Person_In_Charge = childSnapshot.child('_Assign_To').val();
        this.getPartInfo(PO_item);
        PO_item.Delivery_Address = childSnapshot.child('_Delivery_Address').val();
        PO_item.Billing_Address = childSnapshot.child('_Billing_Address').val();
        PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        PO_item.Remark = childSnapshot.child('_Remark').val();
        PO_item.Created_By = childSnapshot.child('_Created_By').val();
        PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
        PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
        PO_item.Status = childSnapshot.child('_Status').val();
        PO_item.Schedule = childSnapshot.child('_Schedule').val();
        
        PO_item.Pic_IC = childSnapshot.child('_Pic_IC').val();
        PO_item.Pic_Name = childSnapshot.child('_Pic_Name').val();
        PO_item.Comments = childSnapshot.child('_Customer_Comments').val();
        PO_item.Reason = childSnapshot.child('_Reason').val();
        PO_item.SDiagnosisPhotos = childSnapshot.child('Diagnosed_Photos').val();
        PO_item.ServicePhotos = childSnapshot.child('Serviced_Photos').val();
        PO_item.Rating = childSnapshot.child('_Rating').val();

        var forkliftList: Forklift[] = [];
        childSnapshot.child("Forklift").forEach((childSnapshot2) => {
          let part_track = new Forklift;

          part_track.ID = childSnapshot2.key;
          part_track.Diagnosed_MC_Type = childSnapshot2.child('_Diagnosed_MC_Type').val();
          part_track.Diagnosed_Model = childSnapshot2.child('_Diagnosed_Model').val();
          part_track.Diagnosed_Serial_No = childSnapshot2.child('_Diagnosed_Serial_No').val();
          part_track.Diagnosed_Remark = childSnapshot2.child('_Diagnosed_Remark').val();
          part_track.Diagnosed_By = childSnapshot2.child('_Diagnosed_By').val();
          part_track.Serviced_MC_Type = childSnapshot2.child('_Serviced_MC_Type').val();
          part_track.Serviced_Model = childSnapshot2.child('_Serviced_Model').val();
          part_track.Serviced_Serial_No = childSnapshot2.child('_Serviced_Serial_No').val();
          part_track.Serviced_Remark = childSnapshot2.child('_Serviced_Remark').val();
          part_track.Serviced_By = childSnapshot2.child('_Serviced_By').val();
          part_track.Quotation = childSnapshot2.child('_Quotation').val();
          part_track.QuotationUrl = childSnapshot2.child('_Quotation_URL').val();
          part_track.PurchaseOrder = childSnapshot2.child('_PurchaseOrder').val();
          part_track.PurchaseOrderUrl = childSnapshot2.child('_PurchaseOrder_URL').val();
          part_track.Invoice = childSnapshot2.child('_Invoice').val();
          part_track.InvoiceUrl = childSnapshot2.child('_Invoice_URL').val();
          part_track.Quotation_By = childSnapshot2.child('_Quotation_By').val();
          part_track.Invoice_By = childSnapshot2.child('_Invoice_By').val();
          part_track.Signature = childSnapshot2.child('_Signature').val();
          part_track.Chekcing_Signature = childSnapshot2.child('_Chekcing_Signature').val();
          part_track.Status = childSnapshot2.child('_Status').val();
          part_track.internal_notes = childSnapshot2.child('_internal_notes').val();
          part_track.Reason = childSnapshot2.child('_Reason').val();
          part_track.SDiagnosisPhotos = childSnapshot2.child('Diagnosed_Photos').val();
          part_track.ServicePhotos = childSnapshot2.child('Serviced_Photos').val();

          if(childSnapshot2.child('_Diagnosed_Date').val() != null && childSnapshot2.child('_Diagnosed_Date').val() != ''){
            part_track.Diagnosed_Date = new Date(childSnapshot2.child('_Diagnosed_Date').val());
          }
  
          if(childSnapshot2.child('_Serviced_Date').val() != null && childSnapshot2.child('_Serviced_Date').val() != ''){
            part_track.Serviced_Date = new Date(childSnapshot2.child('_Serviced_Date').val());
          }
  
          if(childSnapshot2.child('_Invoice_Date').val() != null && childSnapshot2.child('_Invoice_Date').val() != ''){
            part_track.Invoice_Date = new Date(childSnapshot2.child('_Invoice_Date').val());
          }
  
          if(childSnapshot2.child('_Quotation_Date').val() != null && childSnapshot2.child('_Quotation_Date').val() != ''){
            part_track.Quotation_Date = new Date(childSnapshot2.child('_Quotation_Date').val());
          }

          var diagnosisLogList: PartTracker[] = [];
          var serviceLogList: PartTracker[] = [];

          childSnapshot.child("DiagnosisLog").forEach((childSnapshot2) => {
            let part_track = new PartTracker;

            part_track.EndDate = new Date(parseInt(childSnapshot2.key.toString()));
            part_track.PO_Part_Name = childSnapshot2.child("staffName").val();
            part_track.CustomerName = childSnapshot2.child("status").val();
            diagnosisLogList.push(part_track);
          });
          
          childSnapshot.child("ServiceLog").forEach((childSnapshot2) => {
            let part_track = new PartTracker;

            part_track.EndDate = new Date(parseInt(childSnapshot2.key.toString()));
            part_track.PO_Part_Name = childSnapshot2.child("staffName").val();
            part_track.CustomerName = childSnapshot2.child("status").val();
            serviceLogList.push(part_track);
          });

          part_track.DiagnosisList = diagnosisLogList;
          part_track.ServiceList = serviceLogList;
          forkliftList.push(part_track);
        });

        PO_item.Forklifts = forkliftList;

        
        PO_list.push(PO_item);
      });
    }

    return PO_list;
  }

  async getPOListForPackingList(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    var snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        let PO_item = new PurchaseOrder();
        PO_item.PO_No = childSnapshot.key;
        PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        PO_item.Created_By = childSnapshot.child('_Created_By').val();
        PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
        PO_item.Updated_By = childSnapshot.child('_Updated_By').val();

        var part_trackList: PartTracker[] = [];
        childSnapshot.child("Part List").forEach((childSnapshot2) => {
          let part_track = new PartTracker;
          var boxList: BoxShippingInfo[] = [];
          var checkout: CheckOut[] = [];
          childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
            let box = new BoxShippingInfo;
            box.Box_No = childSnapshot3.key;
            box.Box_Status = childSnapshot3.child('Box Status').val();
            box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
            box.Status = childSnapshot3.child('Status').val();
            box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
            box.Updated_Date = new Date(childSnapshot3.child('Date').val());
            boxList.push(box);
          });
          childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
            let out = new CheckOut;
            var raw: RawCheckOut[] = [];
            childSnapshot3.forEach(childSnapshot4 => {
              let r = new RawCheckOut;
              out.Raw_PO = childSnapshot4.key;
              r.Raw_ID = childSnapshot4.child('RawMaterialID').val();
              r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
              r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
              raw.push(r);
            })
            out.RawCheckOutList = raw;
            checkout.push(out);
          });
          part_track.ID = childSnapshot2.key;
          part_track.CheckOut_RawList = checkout;
          part_track.PO_Part_No = childSnapshot2.child("Part No").val();
          part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
          part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
          part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
          part_track.PO_Status = childSnapshot2.child("Status").val();
          part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
          part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
          part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
          part_track.Reference = childSnapshot2.child("Reference").val();
          part_track.POS = childSnapshot2.child("POS").val();
          part_track.PIC = childSnapshot2.child("PIC").val();
          part_track.MachineNo = childSnapshot2.child("MachineNo").val() || "";
          part_track.MachineNo = part_track.MachineNo.replace('@@','');
          part_track.MachineNo = part_track.MachineNo.replace('_1','');
          part_track.MachineNo = part_track.MachineNo.replace('_2','');
          part_track.subFrom = childSnapshot2.child("SubFrom").val();
          part_track.BoxInfo_List = boxList;

          //this.getPartInfo(part_track);
          part_trackList.push(part_track);
        });

        PO_item.PO_Part_List = part_trackList;
        PO_list.push(PO_item);
      });
    }
    return PO_list;
  }

  async addPO(_newPO: any, email) {
    for (const data of _newPO.parts) {
      let updates = {};
      if (data.partNumber2) {
        const info = {
          'Accumulate Quantity': 0,
          'Expected Quantity': data.quantity,
          'PO Quantity': data.poquantity2,
          'Code': '-',
          'PO No': _newPO.POName,
          'Part No': data.partNumber2,
          'Part Name': data.partName2,
          'Schedule Status': 'Waiting',
          'startAt': data.startTime.toISOString(),
          'endAt': data.endTime.toISOString(),
          'presetStart': '-',
          'presetEnd': '-',
          'downStart': 0,
          'downEnd': 0,
          'Total Down Time': 0,
          'Availability': 0,
          'Performance': 0,
          'Effectiveness': 0,
        }
        await this.db.database.ref('Machine/@@' + data.machineChosen + '_1' + '/Schedule/' + data.scheduleID2).set(info)

        updates['LinkedPart2'] = data.partNumber2;
        updates['LinkedPart2ScheduleID'] = data.scheduleID2;
      }
      if (data.partNumber3) {
        const info = {
          'Accumulate Quantity': 0,
          'Expected Quantity': data.quantity,
          'PO Quantity': data.poquantity3,
          'Code': '-',
          'PO No': _newPO.POName,
          'Part No': data.partNumber3,
          'Part Name': data.partName3,
          'Schedule Status': 'Waiting',
          'startAt': data.startTime.toISOString(),
          'endAt': data.endTime.toISOString(),
          'presetStart': '-',
          'presetEnd': '-',
          'downStart': 0,
          'downEnd': 0,
          'Total Down Time': 0,
          'Availability': 0,
          'Performance': 0,
          'Effectiveness': 0,
        }
        await this.db.database.ref('Machine/@@' + data.machineChosen + '_2' + '/Schedule/' + data.scheduleID3).set(info)
        updates['LinkedPart3'] = data.partNumber3;
        updates['LinkedPart3ScheduleID'] = data.scheduleID3;
      }
      updates['Accumulate Quantity'] = 0;
      updates['Expected Quantity'] = data.quantity;
      updates['PO Quantity'] = data.poquantity;
      updates['Code'] = '-';
      updates['PO No'] = _newPO.POName;
      updates['Part No'] = data.partNumber;
      updates['Schedule Status'] = 'Waiting';
      updates['startAt'] = data.startTime.toISOString();
      updates['endAt'] = data.endTime.toISOString();
      updates['presetStart'] = '-';
      updates['presetEnd'] = '-';
      updates['downStart'] = 0;
      updates['downEnd'] = 0;
      updates['Total Down Time'] = 0;
      updates['Availability'] = 0;
      updates['Performance'] = 0;
      updates['Effectiveness'] = 0;
      await this.db.database.ref('Machine/' + data.machineChosen + '/Schedule/' + data.scheduleID).set(updates, async () => {

        for (const element of data.raw.Raw_Material) {
          if (element.check) {
            this.db.database.ref('RawMaterial/' + element.Material_ID).once('value').then(datasnap => {
              let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());

              quantity += parseFloat(element.quantityNeeded);
              this.db.database.ref('RawMaterial/' + element.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
            })
            const info = {}
            info['ID'] = element.Material_ID;
            info['Name'] = element.Material_Name;
            info['Quantity Needed'] = element.quantityNeeded;
            info['Type'] = element.Raw_Type;
            await this.db.database.ref('Machine/' + data.machineChosen + '/Schedule/' + data.scheduleID + '/RawMaterials/' + element.Material_ID).set(info)
          }
        }
      })
    }
    let newPO = {};

    newPO['_Created_Date'] = new Date().toISOString();
    newPO['_Updated_Date'] = new Date().toISOString();
    newPO['_Created_By'] = email;
    newPO['_Updated_By'] = email;
    await this.db.database.ref('Purchase Order/' + _newPO.POName).set(newPO);

    for (const data of _newPO.parts) {
      if (data.partNumber2) {
        const info = {}
        info['Part No'] = data.partNumber2;
        info['Part Name'] = data.partName2;
        info['Part Quantity'] = data.quantity;
        info['PO Quantity'] = data.poquantity2;
        info['Status'] = "Waiting";
        info['_Completed_Date'] = "";
        info['Added to packing'] = false;
        info['Sub'] = true;
        info['SubFrom'] = data.scheduleID;
        info['MachineNo'] = '@@' + data.machineChosen + '_1';
        info['POS'] = data.pos2;
        info['Reference'] = data.reference2;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID2).set(info);

      }
      if (data.partNumber3) {
        const info = {}
        info['Part No'] = data.partNumber3;
        info['Part Name'] = data.partName3;
        info['Part Quantity'] = data.quantity;
        info['PO Quantity'] = data.poquantity3;
        info['Status'] = "Waiting";
        info['_Completed_Date'] = "";
        info['Added to packing'] = false;
        info['Sub'] = true;
        info['SubFrom'] = data.scheduleID;
        info['MachineNo'] = '@@' + data.machineChosen + '_2';
        info['POS'] = data.pos3;
        info['Reference'] = data.reference3;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID3).set(info);

      }
      const info = {}
      info['Part No'] = data.partNumber;
      info['Part Name'] = data.partName;
      info['Part Quantity'] = data.quantity;
      info['PO Quantity'] = data.poquantity;
      info['Status'] = "Waiting";
      info['_Completed_Date'] = "";
      info['Added to packing'] = false;
      info['MachineNo'] = data.machineChosen;
      info['POS'] = data.pos;
      info['Reference'] = data.reference;
      await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID).set(info);

      for (const element of data.raw.Raw_Material) {
        if (element.check) {
          const inf = {}
          inf['ID'] = element.Material_ID;
          inf['Name'] = element.Material_Name;
          inf['Quantity Needed'] = element.quantityNeeded;
          inf['Type'] = element.Raw_Type;
          await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/RawMaterials/' + element.Material_ID).set(inf);
        }
      }

      if (data.raw.innerCheck) {
        const innerBox = {}
        innerBox['ID'] = data.raw.InnerBox.Material_ID;
        innerBox['Name'] = data.raw.InnerBox.Material_Name;
        innerBox['Type'] = data.raw.InnerBox.Raw_Type;
        innerBox['Quantity Needed'] = data.raw.innerBoxNeeded;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/InnerBox').set(innerBox);

        this.db.database.ref('RawMaterial/' + data.raw.InnerBox.Material_ID).once('value').then(datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity += parseFloat(data.raw.innerBoxNeeded);
          this.db.database.ref('RawMaterial/' + data.raw.InnerBox.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }
      if (data.raw.polyCheck) {
        const polybox = {}
        polybox['ID'] = data.raw.PolyBag.Material_ID;
        polybox['Name'] = data.raw.PolyBag.Material_Name;
        polybox['Type'] = data.raw.PolyBag.Raw_Type;
        polybox['Quantity Needed'] = data.raw.polyBagNeeded;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/PolyBag').set(polybox);
        this.db.database.ref('RawMaterial/' + data.raw.PolyBag.Material_ID).once('value').then(datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity += parseFloat(data.raw.polyBagNeeded);
          this.db.database.ref('RawMaterial/' + data.raw.PolyBag.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }
      const cartonbox = {}

      cartonbox['ID'] = data.raw.CartonBox.Material_ID;
      cartonbox['Name'] = data.raw.CartonBox.Material_Name;
      cartonbox['Type'] = data.raw.CartonBox.Raw_Type;
      cartonbox['Quantity Needed'] = data.raw.cartonNeeded;
      cartonbox['Carton Weight'] = data.raw.CartonBox.Carton_Weight;
      await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/Carton').set(cartonbox);

      await this.db.database.ref('RawMaterial/' + data.raw.CartonBox.Material_ID).once('value').then(async datasnap => {
        let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
        quantity += parseFloat(data.raw.cartonNeeded);
        await this.db.database.ref('RawMaterial/' + data.raw.CartonBox.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
      })
    }


  }

  async addPOWithoutSchedule(_newPO: any, email) {
    let updates1 = {};
    let poNumber = "";
    var scheduleId = uuidv4();

    const currentTime = this.dateFormat.convertDateIntoISO8601(new Date());
    var snapshot = await this.db.database.ref('DefaultPO').once('value');

    if (snapshot.exists() && (new Date(snapshot.child('currentDate').val()) >= new Date(currentTime))) {
      var newPO;
      if((parseInt(snapshot.child('currentNum').val()) <= 9)){
        newPO = "RE" + this.dateFormat.convertDateIntoYearMonthDay(new Date(snapshot.child('currentDate').val())) + '-0' + snapshot.child('currentNum').val();
        await this.db.database.ref('DefaultPO/currentNum').set((parseInt(snapshot.child('currentNum').val()) + 1).toString());
      }
      else{
        newPO = "RE" + this.dateFormat.convertDateIntoYearMonthDay(new Date(snapshot.child('currentDate').val())) + '-' + snapshot.child('currentNum').val();
        await this.db.database.ref('DefaultPO/currentNum').set((parseInt(snapshot.child('currentNum').val()) + 1).toString());
      }
      poNumber = newPO;
    }
    else{
      await this.db.database.ref('DefaultPO/currentDate').set(currentTime);
      await this.db.database.ref('DefaultPO/currentNum').set("2");

      const newPO = "RE" + this.dateFormat.convertDateIntoYearMonthDay(new Date(currentTime)) + '-01';
      poNumber = newPO;
    }

    updates1[poNumber + '/_Customer'] = _newPO.CusName;
    updates1[poNumber + '/_Customer_ID'] = _newPO.CusId;
    updates1[poNumber + '/_Assign_To'] = _newPO.assignTo || "-";
    updates1[poNumber + '/_Billing_Address'] = _newPO.billingAddress;
    updates1[poNumber + '/_Delivery_Address'] = _newPO.deliveryAddress || _newPO.billingAddress;
    updates1[poNumber + '/_Remark'] = _newPO.remark;
    updates1[poNumber + '/_Status'] = _newPO.statusTo || "Created";
    updates1[poNumber + '/_Created_Date'] = new Date();
    updates1[poNumber + '/_Updated_Date'] = new Date();
    updates1[poNumber + '/_Created_By'] = email;
    updates1[poNumber + '/_Updated_By'] = email;

    if(_newPO.assignTo != null && _newPO.assignTo != ""){
      updates1[poNumber + '/_Schedule'] = scheduleId;

      if(_newPO.statusTo == "Quotation"){
        updates1[poNumber + '/_Diagnosed_Date'] = new Date();
      }
      else if(_newPO.statusTo == "Servicing"){
        updates1[poNumber + '/_Quotation_Date'] = new Date();
      }
      else if(_newPO.statusTo == "Invoicing"){
        updates1[poNumber + '/_Serviced_Date'] = new Date();
      }

      let updates2 = {};
      updates2['_Status'] = "Pending";
      updates2['_CR_No'] = poNumber;
      updates2['_Customer'] = _newPO.CusName;
      updates2['_Billing_Address'] = _newPO.billingAddress;
      updates2['_Delivery_Address'] = _newPO.deliveryAddress || _newPO.billingAddress;
      updates2['_Updated_Date'] = updates1[poNumber + '/_Updated_Date'];
      await this.db.database.ref('User/'+_newPO.assignTo+'/Schedule/'+scheduleId+'/').update(updates2);
    }
    else{
      updates1[poNumber + '/_Status'] = "Created";
    }

    await this.db.database.ref('Purchase Order/').update(updates1);
  }

  async updateJOWithoutSchedule(_newPO: any, email) {
    let updates1 = {};

    updates1[_newPO.PONo + '/Part List/' + _newPO.JONo + '/Status'] = _newPO.JOStatus;
    this.db.database.ref('Purchase Order/').update(updates1);

    _newPO.rawMaterials.forEach(data => {
      let newStock = parseFloat(data.rawMatStock) - parseFloat(data.rawMatNeeded);
      this.db.database.ref('RawMaterial/' + data.rawMatId + '/_In_Stock').set(newStock.toFixed(2));
    });
  }

  async search_PO(PO_No: string): Promise<PurchaseOrder> {
    let searched_PO = new PurchaseOrder;
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let get_PO_No = childSnapshot.key;
        if (PO_No.match(get_PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          let part_trackList: PartTracker[] = [];
          childSnapshot.child("Part List").forEach((function (childSnapshot2) {
            let part_track = new PartTracker;
            var boxList: BoxShippingInfo[] = [];
            var checkout: CheckOut[] = [];
            childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
              let box = new BoxShippingInfo;
              box.Box_No = childSnapshot3.key;
              box.Box_Status = childSnapshot3.child('Box Status').val();
              box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
              box.Status = childSnapshot3.child('Status').val();
              box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
              box.Updated_Date = new Date(childSnapshot3.child('Date').val());
              boxList.push(box);
            });
            childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
              let out = new CheckOut;
              var raw: RawCheckOut[] = [];
              out.Raw_PO = childSnapshot3.key;
              childSnapshot3.forEach(childSnapshot4 => {
                let r = new RawCheckOut;
                r.Raw_ID = childSnapshot4.key
                r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
                r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
                raw.push(r);
              })
              out.RawCheckOutList = raw;
              checkout.push(out);
            });
            part_track.CheckOut_RawList = checkout;
            part_track.PO_Part_No = childSnapshot2.child("Part No").val();
            part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
            part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
            part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
            part_track.PO_Status = childSnapshot2.child("Status").val();
            part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
            part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
            part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
            part_track.BoxInfo_List = boxList;
            part_trackList.push(part_track);
          }));
          searched_PO.PO_Part_List = part_trackList;
        }
      }));
    }
    return searched_PO;
  }

  async search_Partial_PO(PO_No: string): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let searched_PO = new PurchaseOrder;
        let get_PO_No = childSnapshot.key;
        if (get_PO_No.includes(PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          let part_trackList: PartTracker[] = [];
          childSnapshot.child("Part List").forEach((function (childSnapshot2) {
            let part_track = new PartTracker;
            var boxList: BoxShippingInfo[] = [];
            var checkout: CheckOut[] = [];
            childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
              let box = new BoxShippingInfo;
              box.Box_No = childSnapshot3.key;
              box.Box_Status = childSnapshot3.child('Box Status').val();
              box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
              box.Status = childSnapshot3.child('Status').val();
              box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
              box.Updated_Date = new Date(childSnapshot3.child('Date').val());
              boxList.push(box);
            });
            childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
              let out = new CheckOut;
              var raw: RawCheckOut[] = [];
              out.Raw_PO = childSnapshot3.key;
              childSnapshot3.forEach(childSnapshot4 => {
                let r = new RawCheckOut;
                r.Raw_ID = childSnapshot4.key
                r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
                r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
                raw.push(r);
              })
              out.RawCheckOutList = raw;
              checkout.push(out);
            });
            part_track.CheckOut_RawList = checkout;
            part_track.PO_Part_No = childSnapshot2.child("Part No").val();
            part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
            part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
            part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
            part_track.PO_Status = childSnapshot2.child("Status").val();
            part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
            part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
            part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
            part_track.BoxInfo_List = boxList;
            part_trackList.push(part_track);
          }));
          searched_PO.PO_Part_List = part_trackList;
          PO_list.push(searched_PO);
        }
      }));
    }
    return PO_list;
  }

  delete_PO(PO_No: string, machinelist) {
    const de = [];
    this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value').then(async snapshot => {
      snapshot.forEach(deleteSchedule => {
        if (deleteSchedule.child('RawMaterials').val()) {
          deleteSchedule.child('RawMaterials').forEach(e => {
            const info = {
              ID: e.child('ID').val(),
              value: parseFloat(e.child('Quantity Needed').val())
            }
            if (de.length > 0) {
              const result = de.findIndex(e => e.ID === info.ID);
              if (result !== -1) {
                de[result].value += parseFloat(e.child('Quantity Needed').val());
              } else {
                de.push(info);
              }
            } else {
              de.push(info);
            }
          })
        }
        if (deleteSchedule.child('Box').val()) {
          deleteSchedule.child('Box').forEach(e => {
            const info = {
              ID: e.child('ID').val(),
              value: parseFloat(e.child('Quantity Needed').val())
            }
            if (de.length > 0) {
              const result = de.findIndex(e => e.ID === info.ID);
              if (result !== -1) {
                de[result].value += parseFloat(e.child('Quantity Needed').val());
              } else {
                de.push(info);
              }
            } else {
              de.push(info);
            }
          })
        }

        machinelist.forEach(element => {
          this.db.database.ref('/Machine/' + element + '/Schedule/' + deleteSchedule.key).set(null);
        });
      })
    }).finally(() => {
      this.db.database.ref('/Purchase Order/' + PO_No).set(null);
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }

    })
  }

  //Temporary
  async delete_Schedule(PO_No, machine, id, schedule) {
    const de = [];

    if(schedule.LinkedPart2ScheduleID){
      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + schedule.LinkedPart2ScheduleID).set(null).then(async result => {
        await this.db.database.ref('/Machine/@@' + machine + '_1/Schedule/' + schedule.LinkedPart2ScheduleID).set(null);
      });
    }
    if(schedule.LinkedPart3ScheduleID){
      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + schedule.LinkedPart3ScheduleID).set(null).then(async result => {
        await this.db.database.ref('/Machine/@@' + machine + '_2/Schedule/' + schedule.LinkedPart3ScheduleID).set(null);
      });
    }

    await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).once('value').then(async snapshot => {
      if (snapshot.child('RawMaterials').val()) {
        snapshot.child('RawMaterials').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
      if (snapshot.child('Box').val()) {
        snapshot.child('Box').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
    }).finally(() => {
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }

      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).set(null).then(async result => {
        await this.db.database.ref('/Machine/' + machine + '/Schedule/' + id).set(null);
        const snapshot = await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value')
        if (!snapshot.exists()) {
          await this.db.database.ref('/Purchase Order/' + PO_No).set(null)
        }
      });
    })


  }

  async delete_ScheduleFromPO(PO_No, machine, id, subpart) {
    const de = [];

    for (const part of subpart) {
      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + part.ID).set(null).then(async result => {
        await this.db.database.ref('/Machine/'+ part.MachineNo+'/Schedule/' + part.ID).set(null);
      });
    }


    await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).once('value').then(async snapshot => {
      if (snapshot.child('RawMaterials').val()) {
        snapshot.child('RawMaterials').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
      if (snapshot.child('Box').val()) {
        snapshot.child('Box').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
    }).finally(() => {
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }




      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).set(null).then(async result => {
        await this.db.database.ref('/Machine/' + machine + '/Schedule/' + id).set(null);
        const snapshot = await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value')
        if (!snapshot.exists()) {
          await this.db.database.ref('/Purchase Order/' + PO_No).set(null)
        }
      });
    })


  }

  async search_PO_withStatusCompleted(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Invoiced' || childSnapshot.child('_Status').val() == 'Invoicing'){
          let PO_item = new PurchaseOrder;

          PO_item.PO_No = childSnapshot.key;
          PO_item.Customer = childSnapshot.child('_Customer').val();
          PO_item.Person_In_Charge = childSnapshot.child('_Assign_To').val();
          PO_item.Delivery_Address = childSnapshot.child('_Delivery_Address').val();
          PO_item.Billing_Address = childSnapshot.child('_Billing_Address').val();
          PO_item.Remark = childSnapshot.child('_Remark').val();
          PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          PO_item.Created_By = childSnapshot.child('_Created_By').val();
          PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
          PO_item.Status = childSnapshot.child('_Status').val();
          PO_item.Pic_IC = childSnapshot.child('_Pic_IC').val();
          PO_item.Pic_Name = childSnapshot.child('_Pic_Name').val();
          PO_item.Reason = childSnapshot.child('_Reason').val();
          PO_item.SDiagnosisPhotos = childSnapshot.child('Diagnosed_Photos').val();
          PO_item.ServicePhotos = childSnapshot.child('Serviced_Photos').val();
          PO_item.Rating = childSnapshot.child('_Rating').val();
          PO_item.Schedule = childSnapshot.child('_Schedule').val();

          var forkliftList: Forklift[] = [];
          childSnapshot.child("Forklift").forEach((childSnapshot2) => {
            let part_track = new Forklift;

            part_track.ID = childSnapshot2.key;
            part_track.Diagnosed_MC_Type = childSnapshot2.child('_Diagnosed_MC_Type').val();
            part_track.Diagnosed_Model = childSnapshot2.child('_Diagnosed_Model').val();
            part_track.Diagnosed_Serial_No = childSnapshot2.child('_Diagnosed_Serial_No').val();
            part_track.Diagnosed_Remark = childSnapshot2.child('_Diagnosed_Remark').val();
            part_track.Diagnosed_By = childSnapshot2.child('_Diagnosed_By').val();
            part_track.Serviced_MC_Type = childSnapshot2.child('_Serviced_MC_Type').val();
            part_track.Serviced_Model = childSnapshot2.child('_Serviced_Model').val();
            part_track.Serviced_Serial_No = childSnapshot2.child('_Serviced_Serial_No').val();
            part_track.Serviced_Remark = childSnapshot2.child('_Serviced_Remark').val();
            part_track.Serviced_By = childSnapshot2.child('_Serviced_By').val();
            part_track.Quotation = childSnapshot2.child('_Quotation').val();
            part_track.QuotationUrl = childSnapshot2.child('_Quotation_URL').val();
            part_track.PurchaseOrder = childSnapshot2.child('_PurchaseOrder').val();
            part_track.PurchaseOrderUrl = childSnapshot2.child('_PurchaseOrder_URL').val();
            part_track.Invoice = childSnapshot2.child('_Invoice').val();
            part_track.InvoiceUrl = childSnapshot2.child('_Invoice_URL').val();
            part_track.Quotation_By = childSnapshot2.child('_Quotation_By').val();
            part_track.Invoice_By = childSnapshot2.child('_Invoice_By').val();
            part_track.Signature = childSnapshot2.child('_Signature').val();
            part_track.Chekcing_Signature = childSnapshot2.child('_Chekcing_Signature').val();

            if(childSnapshot2.child('_Diagnosed_Date').val() != null && childSnapshot2.child('_Diagnosed_Date').val() != ''){
              part_track.Diagnosed_Date = new Date(childSnapshot2.child('_Diagnosed_Date').val());
            }
    
            if(childSnapshot2.child('_Serviced_Date').val() != null && childSnapshot2.child('_Serviced_Date').val() != ''){
              part_track.Serviced_Date = new Date(childSnapshot2.child('_Serviced_Date').val());
            }
    
            if(childSnapshot2.child('_Invoice_Date').val() != null && childSnapshot2.child('_Invoice_Date').val() != ''){
              part_track.Invoice_Date = new Date(childSnapshot2.child('_Invoice_Date').val());
            }
    
            if(childSnapshot2.child('_Quotation_Date').val() != null && childSnapshot2.child('_Quotation_Date').val() != ''){
              part_track.Quotation_Date = new Date(childSnapshot2.child('_Quotation_Date').val());
            }

            forkliftList.push(part_track);
          });

          PO_item.Forklifts = forkliftList;

          PO_list.push(PO_item);
        }
      });
    }

    return PO_list;
  }

  async search_PO_withStatusNotCompleted(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() != 'Invoiced' && childSnapshot.child('_Status').val() != 'Rejected' && !childSnapshot.child('_Status').val().includes('Cancelled') && !childSnapshot.child('_Status').val().includes('KIV')){
          let PO_item = new PurchaseOrder;

          PO_item.PO_No = childSnapshot.key;
          PO_item.Customer = childSnapshot.child('_Customer').val();
          PO_item.Customer_ID = childSnapshot.child('_Customer_ID').val();
          this.getCustomerInfo(PO_item);
          PO_item.Person_In_Charge = childSnapshot.child('_Assign_To').val();
          this.getPartInfo(PO_item);
          PO_item.Delivery_Address = childSnapshot.child('_Delivery_Address').val();
          PO_item.Billing_Address = childSnapshot.child('_Billing_Address').val();
          PO_item.Remark = childSnapshot.child('_Remark').val();
          PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          PO_item.Created_By = childSnapshot.child('_Created_By').val();
          this.getStaffInfo(PO_item);
          PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
          PO_item.Status = childSnapshot.child('_Status').val();
          PO_item.Pic_IC = childSnapshot.child('_Pic_IC').val();
          PO_item.Pic_Name = childSnapshot.child('_Pic_Name').val();
          PO_item.Comments = childSnapshot.child('_Customer_Comments').val();
          PO_item.Reason = childSnapshot.child('_Reason').val();
          PO_item.Rating = childSnapshot.child('_Rating').val();
          PO_item.Schedule = childSnapshot.child('_Schedule').val();

          var forkliftList: Forklift[] = [];
          childSnapshot.child("Forklift").forEach((childSnapshot2) => {
            let part_track = new Forklift;

            part_track.ID = childSnapshot2.key;
            part_track.Diagnosed_MC_Type = childSnapshot2.child('_Diagnosed_MC_Type').val();
            part_track.Diagnosed_Model = childSnapshot2.child('_Diagnosed_Model').val();
            part_track.Diagnosed_Serial_No = childSnapshot2.child('_Diagnosed_Serial_No').val();
            part_track.Diagnosed_Remark = childSnapshot2.child('_Diagnosed_Remark').val();
            part_track.Diagnosed_By = childSnapshot2.child('_Diagnosed_By').val();
            part_track.Serviced_MC_Type = childSnapshot2.child('_Serviced_MC_Type').val();
            part_track.Serviced_Model = childSnapshot2.child('_Serviced_Model').val();
            part_track.Serviced_Serial_No = childSnapshot2.child('_Serviced_Serial_No').val();
            part_track.Serviced_Remark = childSnapshot2.child('_Serviced_Remark').val();
            part_track.Serviced_By = childSnapshot2.child('_Serviced_By').val();
            part_track.Quotation = childSnapshot2.child('_Quotation').val();
            part_track.QuotationUrl = childSnapshot2.child('_Quotation_URL').val();
            part_track.PurchaseOrder = childSnapshot2.child('_PurchaseOrder').val();
            part_track.PurchaseOrderUrl = childSnapshot2.child('_PurchaseOrder_URL').val();
            part_track.Invoice = childSnapshot2.child('_Invoice').val();
            part_track.InvoiceUrl = childSnapshot2.child('_Invoice_URL').val();
            part_track.Quotation_By = childSnapshot2.child('_Quotation_By').val();
            part_track.Invoice_By = childSnapshot2.child('_Invoice_By').val();
            part_track.Signature = childSnapshot2.child('_Signature').val();
            part_track.Chekcing_Signature = childSnapshot2.child('_Chekcing_Signature').val();
            part_track.Status = childSnapshot2.child('_Status').val();
            part_track.internal_notes = childSnapshot2.child('_internal_notes').val();
            part_track.Reason = childSnapshot2.child('_Reason').val();
            part_track.SDiagnosisPhotos = childSnapshot2.child('Diagnosed_Photos').val();
            part_track.ServicePhotos = childSnapshot2.child('Serviced_Photos').val();

            if(childSnapshot2.child('_Diagnosed_Date').val() != null && childSnapshot2.child('_Diagnosed_Date').val() != ''){
              part_track.Diagnosed_Date = new Date(childSnapshot2.child('_Diagnosed_Date').val());
            }
    
            if(childSnapshot2.child('_Serviced_Date').val() != null && childSnapshot2.child('_Serviced_Date').val() != ''){
              part_track.Serviced_Date = new Date(childSnapshot2.child('_Serviced_Date').val());
            }
    
            if(childSnapshot2.child('_Invoice_Date').val() != null && childSnapshot2.child('_Invoice_Date').val() != ''){
              part_track.Invoice_Date = new Date(childSnapshot2.child('_Invoice_Date').val());
            }
    
            if(childSnapshot2.child('_Quotation_Date').val() != null && childSnapshot2.child('_Quotation_Date').val() != ''){
              part_track.Quotation_Date = new Date(childSnapshot2.child('_Quotation_Date').val());
            }

            forkliftList.push(part_track);
          });

          PO_item.Forklifts = forkliftList;

          PO_list.push(PO_item);
        }
      });
    }

    return PO_list;
  }

  async search_PO_withStatusRejected(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val().toString().includes('Rejected')){
          let PO_item = new PurchaseOrder;

          PO_item.PO_No = childSnapshot.key;
          PO_item.Customer = childSnapshot.child('_Customer').val();
          PO_item.Person_In_Charge = childSnapshot.child('_Assign_To').val();
          PO_item.Delivery_Address = childSnapshot.child('_Delivery_Address').val();
          PO_item.Billing_Address = childSnapshot.child('_Billing_Address').val();
          PO_item.Remark = childSnapshot.child('_Remark').val();
          PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          PO_item.Created_By = childSnapshot.child('_Created_By').val();
          PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
          this.getStaffUpdateInfo(PO_item);
          PO_item.Status = childSnapshot.child('_Status').val();
          PO_item.Pic_IC = childSnapshot.child('_Pic_IC').val();
          PO_item.Pic_Name = childSnapshot.child('_Pic_Name').val();
          PO_item.Reason = childSnapshot.child('_Reason').val();
          PO_item.Rating = childSnapshot.child('_Rating').val();
          PO_item.Schedule = childSnapshot.child('_Schedule').val();

          var forkliftList: Forklift[] = [];
          var haveKIV = false;

          childSnapshot.child("Forklift").forEach((childSnapshot2) => {
            let part_track = new Forklift;

            part_track.ID = childSnapshot2.key;
            part_track.Diagnosed_MC_Type = childSnapshot2.child('_Diagnosed_MC_Type').val();
            part_track.Diagnosed_Model = childSnapshot2.child('_Diagnosed_Model').val();
            part_track.Diagnosed_Serial_No = childSnapshot2.child('_Diagnosed_Serial_No').val();
            part_track.Diagnosed_Remark = childSnapshot2.child('_Diagnosed_Remark').val();
            part_track.Diagnosed_By = childSnapshot2.child('_Diagnosed_By').val();
            part_track.Serviced_MC_Type = childSnapshot2.child('_Serviced_MC_Type').val();
            part_track.Serviced_Model = childSnapshot2.child('_Serviced_Model').val();
            part_track.Serviced_Serial_No = childSnapshot2.child('_Serviced_Serial_No').val();
            part_track.Serviced_Remark = childSnapshot2.child('_Serviced_Remark').val();
            part_track.Serviced_By = childSnapshot2.child('_Serviced_By').val();
            part_track.Quotation = childSnapshot2.child('_Quotation').val();
            part_track.QuotationUrl = childSnapshot2.child('_Quotation_URL').val();
            part_track.PurchaseOrder = childSnapshot2.child('_PurchaseOrder').val();
            part_track.PurchaseOrderUrl = childSnapshot2.child('_PurchaseOrder_URL').val();
            part_track.Invoice = childSnapshot2.child('_Invoice').val();
            part_track.InvoiceUrl = childSnapshot2.child('_Invoice_URL').val();
            part_track.Quotation_By = childSnapshot2.child('_Quotation_By').val();
            part_track.Invoice_By = childSnapshot2.child('_Invoice_By').val();
            part_track.Signature = childSnapshot2.child('_Signature').val();
            part_track.Chekcing_Signature = childSnapshot2.child('_Chekcing_Signature').val();
            part_track.Status = childSnapshot2.child('_Status').val();
            part_track.Reason = childSnapshot2.child('_Reason').val();
            part_track.internal_notes = childSnapshot2.child('_internal_notes').val();
            part_track.SDiagnosisPhotos = childSnapshot.child('Diagnosed_Photos').val();
            part_track.ServicePhotos = childSnapshot.child('Serviced_Photos').val();
            
            if(childSnapshot2.child('_Status').val() == 'Rejected'){
              haveKIV = true;
            }

            if(childSnapshot2.child('_Diagnosed_Date').val() != null && childSnapshot2.child('_Diagnosed_Date').val() != ''){
              part_track.Diagnosed_Date = new Date(childSnapshot2.child('_Diagnosed_Date').val());
            }
    
            if(childSnapshot2.child('_Serviced_Date').val() != null && childSnapshot2.child('_Serviced_Date').val() != ''){
              part_track.Serviced_Date = new Date(childSnapshot2.child('_Serviced_Date').val());
            }
    
            if(childSnapshot2.child('_Invoice_Date').val() != null && childSnapshot2.child('_Invoice_Date').val() != ''){
              part_track.Invoice_Date = new Date(childSnapshot2.child('_Invoice_Date').val());
            }
    
            if(childSnapshot2.child('_Quotation_Date').val() != null && childSnapshot2.child('_Quotation_Date').val() != ''){
              part_track.Quotation_Date = new Date(childSnapshot2.child('_Quotation_Date').val());
            }

            forkliftList.push(part_track);
          });

          PO_item.Forklifts = forkliftList;

          if(haveKIV){
            PO_list.push(PO_item);
          }
        }
      });
    }

    return PO_list;
  }

  async search_PO_withStatusKIV(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val().toString().includes('KIV')){
          let PO_item = new PurchaseOrder;

          PO_item.PO_No = childSnapshot.key;
          PO_item.Customer = childSnapshot.child('_Customer').val();
          PO_item.Person_In_Charge = childSnapshot.child('_Assign_To').val();
          PO_item.Delivery_Address = childSnapshot.child('_Delivery_Address').val();
          PO_item.Billing_Address = childSnapshot.child('_Billing_Address').val();
          PO_item.Remark = childSnapshot.child('_Remark').val();
          PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          PO_item.Created_By = childSnapshot.child('_Created_By').val();
          PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
          PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
          PO_item.Updated_By = childSnapshot.child('_Updated_By').val();
          this.getStaffUpdateInfo(PO_item);
          PO_item.Status = childSnapshot.child('_Status').val();
          PO_item.Pic_IC = childSnapshot.child('_Pic_IC').val();
          PO_item.Pic_Name = childSnapshot.child('_Pic_Name').val();
          PO_item.Reason = childSnapshot.child('_Reason').val();
          PO_item.Rating = childSnapshot.child('_Rating').val();
          PO_item.Schedule = childSnapshot.child('_Schedule').val();

          var forkliftList: Forklift[] = [];
          var haveKIV = false;

          childSnapshot.child("Forklift").forEach((childSnapshot2) => {
            let part_track = new Forklift;

            part_track.ID = childSnapshot2.key;
            part_track.Diagnosed_MC_Type = childSnapshot2.child('_Diagnosed_MC_Type').val();
            part_track.Diagnosed_Model = childSnapshot2.child('_Diagnosed_Model').val();
            part_track.Diagnosed_Serial_No = childSnapshot2.child('_Diagnosed_Serial_No').val();
            part_track.Diagnosed_Remark = childSnapshot2.child('_Diagnosed_Remark').val();
            part_track.Diagnosed_By = childSnapshot2.child('_Diagnosed_By').val();
            part_track.Serviced_MC_Type = childSnapshot2.child('_Serviced_MC_Type').val();
            part_track.Serviced_Model = childSnapshot2.child('_Serviced_Model').val();
            part_track.Serviced_Serial_No = childSnapshot2.child('_Serviced_Serial_No').val();
            part_track.Serviced_Remark = childSnapshot2.child('_Serviced_Remark').val();
            part_track.Serviced_By = childSnapshot2.child('_Serviced_By').val();
            part_track.Quotation = childSnapshot2.child('_Quotation').val();
            part_track.QuotationUrl = childSnapshot2.child('_Quotation_URL').val();
            part_track.PurchaseOrder = childSnapshot2.child('_PurchaseOrder').val();
            part_track.PurchaseOrderUrl = childSnapshot2.child('_PurchaseOrder_URL').val();
            part_track.Invoice = childSnapshot2.child('_Invoice').val();
            part_track.InvoiceUrl = childSnapshot2.child('_Invoice_URL').val();
            part_track.Quotation_By = childSnapshot2.child('_Quotation_By').val();
            part_track.Invoice_By = childSnapshot2.child('_Invoice_By').val();
            part_track.Signature = childSnapshot2.child('_Signature').val();
            part_track.Chekcing_Signature = childSnapshot2.child('_Chekcing_Signature').val();
            part_track.Status = childSnapshot2.child('_Status').val();
            part_track.internal_notes = childSnapshot2.child('_internal_notes').val();
            part_track.SDiagnosisPhotos = childSnapshot.child('Diagnosed_Photos').val();
            part_track.ServicePhotos = childSnapshot.child('Serviced_Photos').val();
            
            if(childSnapshot2.child('_Status').val() == 'KIV'){
              haveKIV = true;
            }

            if(childSnapshot2.child('_Diagnosed_Date').val() != null && childSnapshot2.child('_Diagnosed_Date').val() != ''){
              part_track.Diagnosed_Date = new Date(childSnapshot2.child('_Diagnosed_Date').val());
            }
    
            if(childSnapshot2.child('_Serviced_Date').val() != null && childSnapshot2.child('_Serviced_Date').val() != ''){
              part_track.Serviced_Date = new Date(childSnapshot2.child('_Serviced_Date').val());
            }
    
            if(childSnapshot2.child('_Invoice_Date').val() != null && childSnapshot2.child('_Invoice_Date').val() != ''){
              part_track.Invoice_Date = new Date(childSnapshot2.child('_Invoice_Date').val());
            }
    
            if(childSnapshot2.child('_Quotation_Date').val() != null && childSnapshot2.child('_Quotation_Date').val() != ''){
              part_track.Quotation_Date = new Date(childSnapshot2.child('_Quotation_Date').val());
            }

            forkliftList.push(part_track);
          });

          PO_item.Forklifts = forkliftList;

          if(haveKIV || PO_item.Status == 'KIV'){
            PO_list.push(PO_item);
          }
        }
      });
    }

    return PO_list;
  }

  async search_JO_withStatusNotCompleted(): Promise<PartTracker[]> {
    let JOList: PartTracker[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() == 'Incomplete'){
              let part_track = new PartTracker;
              part_track.SO_No = childSnapshot.key;
              part_track.JO_No = childSnapshot2.key;
              part_track.EndDate = new Date(childSnapshot2.child("End Date").val());
              part_track.PO_Part_No = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.PO_Status = childSnapshot2.child('Status').val();
              part_track.Customer_Name = childSnapshot.child('_Customer').val();
              this.getPartRawMaterialUsed(part_track);
              //this.getPartInfo(part_track);
              JOList.push(part_track);
            };
          });
        }
      });
    }

    return JOList;
  }

  async getJOList(): Promise<PartTracker[]> {
    let JOList: PartTracker[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Status').val() == 'Incomplete'){
          childSnapshot.child('Part List').forEach(childSnapshot2 => {
            if(childSnapshot2.child('Status').val() == 'Planned Material' || childSnapshot2.child('Status').val() == 'Processing'){
              let part_track = new PartTracker;
              part_track.SO_No = childSnapshot.key;
              part_track.JO_No = childSnapshot2.key;
              part_track.EndDate = new Date(childSnapshot2.child("End Date").val());
              part_track.PO_Part_No = childSnapshot2.child('Part No').val();
              part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
              part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
              part_track.PO_Status = childSnapshot2.child('Status').val();
              JOList.push(part_track);
            };
          });
        }
      });
    }

    return JOList;
  }

  async getPartRawMaterialUsed(part_track: PartTracker): Promise<void> {
    var childSnapshot = await this.db.database.ref('Part/'+part_track.PO_Part_No).once('value');

    const rawMaterialID = [];
    part_track.Raw_Material_Status = "true";
    childSnapshot.child('Raw Material').forEach((childSnapshot2) => {
      const info = {
        ID: childSnapshot2.child('ID').val(),
        Name: childSnapshot2.child('Name').val(),
        Material: childSnapshot2.child('Material').val(),
        Raw_Type: childSnapshot2.child('Type').val(),
        UOM: childSnapshot2.child('UOM').val(),
        Amount: childSnapshot2.child('Amount').val()
      }

      this.db.database.ref('RawMaterial').child(info.ID).once('value').then(snap=>{
        if (snap.exists()) {
          const raw = new RawMaterialInfo();
          raw.Material_ID = snap.key;
          raw.Material_Name = snap.child('_Material_Name').val();
          raw.Quantity_Reserved = snap.child('_Quantity_Reserved').val();
          raw.In_Stock = snap.child('_In_Stock').val();
          raw.Unit = info.UOM;
          raw.Unit_Price = snap.child('_Unit_Price').val(); 
          raw.Updated_By = snap.child('_Updated_By').val();
          raw.Created_By = snap.child('_Created_By').val();
          raw.Created_Date = new Date(snap.child('_Created_Date').val());
          raw.Last_Updated = new Date(snap.child('_Last_Updated').val());
          raw.Part_Material_Name = info.Name;
          raw.Raw_Amount = info.Amount;
          raw.Raw_Type = info.Raw_Type;
          this.getRawMaterialUsed(raw, part_track);
          rawMaterialID.push(raw);
        }
      });
    });

    part_track.Raw_Material = rawMaterialID;
  }

  async getRawMaterialUsed(raw: RawMaterialInfo, quantity: PartTracker): Promise<void> {
    raw.quantityNeeded = parseFloat((parseFloat(raw.Raw_Amount) * parseFloat(quantity.POQuantity.toString())).toString()).toFixed(2).toString();

    if(parseFloat(raw.quantityNeeded) > parseFloat(raw.In_Stock)){
      quantity.Raw_Material_Status = "false";
    }
  }

  async getPartInfo(pt: PurchaseOrder): Promise<void> {
    var snapshot = await this.db.database.ref('User').child(pt.Person_In_Charge).once('value');
    if (snapshot.exists()) {
      pt.Person_Name = snapshot.child('StaffName').val();
    }
  }

  async getStaffInfo(pt: PurchaseOrder): Promise<void> {
    var snapshot = await this.db.database.ref('User').once('value');

    if (snapshot.exists()) {
      var found = false

      snapshot.forEach(childSnapshot => {
        if(!found && childSnapshot.key != "-" && childSnapshot.child('Email').val() == pt.Created_By){          
          pt.Created_By_Name = childSnapshot.child('StaffName').val();
          found = true;
        }
      });
    }
    else{
      pt.Created_By_Name = "-";
    }
  }

  async getStaffUpdateInfo(pt: PurchaseOrder): Promise<void> {
    var snapshot = await this.db.database.ref('User').once('value');

    if (snapshot.exists()) {
      var found = false

      snapshot.forEach(childSnapshot => {
        if(!found && childSnapshot.key != "-" && childSnapshot.child('Email').val() == pt.Updated_By){          
          pt.Created_By_Name = childSnapshot.child('StaffName').val();
          found = true;
        }
      });
    }
    else{
      pt.Created_By_Name = "-";
    }
  }

  async getCustomerInfo(pt: PurchaseOrder): Promise<void> {
    var cusSnapshot = await this.db.database.ref('Customer').child(pt.Customer_ID).once('value');

    if (cusSnapshot.exists()) {
      pt.Customer = cusSnapshot.child('_Customer_Name').val();
    }
  }

  async getInvoice(poNumber, pt: PartTracker): Promise<void> {
    var snapshot = await this.db.database.ref('PackingList').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(chilsnapshot => {
        if (chilsnapshot.child('POList').child(poNumber).exists()) {
          pt.PackingListNo = chilsnapshot.key;
          // pt.PackingListNo = chilsnapshot.child('Inv_PackingListNo').val();
        }
      })
    }
  }
}
