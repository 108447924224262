import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';

@Component({
  selector: 'app-oee-history',
  templateUrl: './oee-history.component.html',
  styleUrls: ['./oee-history.component.css']
})
export class OEEHistoryComponent {
  po: Staff = new Staff();
  email:string;
  
  constructor(
    private dialogRef: MatDialogRef<OEEHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.po = data.machineNumber;
  }

  ngOnInit(): void {
    
  }
  update(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm update this with this Staff Schedule? ' + this.po.StaffName;
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        for (const part of this.po.Schedule_Track) {
          let updates2 = {};
          updates2['_Sequence'] = part.Sequence;
          await this.db.database.ref('User/'+this.po.StaffID+'/Schedule/'+part.ID+'/').update(updates2);
        }
        this.dialogRef.close();
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
