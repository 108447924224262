import { PartDB_controller } from './../../Services/DB_Controller/PartDB_controller';
import { SelectMachineComponent } from './select-machine/select-machine.component';
import { AddEventComponent } from './add-event/add-event.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Calendar, CalendarOptions, createElement, FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { UpdateScheduleComponent } from './update-schedule/update-schedule.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { ScheduleTracker, Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { weekdays } from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.css']
})
export class SchedulingComponent implements AfterViewInit {
  email: string;
  role: string;
  index = 0;

  constructor(private angularFireDatabase: AngularFireDatabase,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private userinfoSrv:UserInfoService
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role;
    });
  }

  staffId;
  count = 0;
  machine: string;
  machinelist = [];
  eventlist = [];

  POlist: PurchaseOrder[] = [];
  IncompletePOList: ScheduleTracker[] = [];
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.angularFireDatabase, this.firestore);
  partController = new PartDB_controller(this.angularFireDatabase, this.storage,this.firestore);
  PODB_controller: PODB_controller = new PODB_controller(this.angularFireDatabase);
  @ViewChild('draggable', { static: true }) draggableEl: ElementRef;
  @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent;
  
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.spinner.show();
    this.machinelist = [];

    this.PODB_controller.getPOList().then(data => {
      this.POlist = data;
    });

    this.StaffDB_controller.getStaffList().then(async data => {
      await data.forEach(staff=>{
        if(this.role == 'ADMIN'){
          this.machinelist.push(staff);
        }
        else if(this.role != 'ADMIN' && staff.Email == this.email){
          this.machinelist.push(staff);
        }
      });

      this.machinelist.forEach((data, index) => {
        if(data.Email == this.email){
          this.getSchedule(this.machinelist[index]);
        }
      });
    });
  }

  getSchedule(po: Staff) {
    this.staffId = po.StaffID;

    this.IncompletePOList = [];
    if(po.Schedule_Track != null && po.Schedule_Track.length > 0){
      po.Schedule_Track.forEach(data => {
        this.IncompletePOList.push(data);
      });
    }

    this.spinner.hide();
  }

  viewDetail(po: ScheduleTracker){
    let PO = this.POlist.find(a => po.PO_No === a.PO_No);

    const dialogRefupdatePart = this.dialog.open(UpdateScheduleComponent, {
      data: PO,
      width: '90%',
      height: '80%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(result => {
      this.spinner.show();
      this.ngAfterViewInit();
    });
  }

  selectMachine(value) {
    let chosen = this.machinelist.find(p => p.StaffID.includes(value));
    this.spinner.show();
    //this.calendarComponent.getApi().removeAllEvents();
    this.getSchedule(chosen);
  }
}
