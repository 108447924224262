import { Part } from './../Object_Classes/Part/Part';
import { CheckOut } from './../Object_Classes/PurchaseOrder/PurchaseOrder';
import { OldStock, oldStockPO } from './../Object_Classes/RawMaterial/OldStock';
import { AngularFireDatabase } from '@angular/fire/database';
import { RawMaterialInfo } from '../Object_Classes/RawMaterial/RawMaterial';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';



export class RawDB_controller {

  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }

  private dateFormat = new DateFormatService();

  async getRawList(): Promise<RawMaterialInfo[]> {
    let RawList: RawMaterialInfo[] = [];
    var snapshot = await this.db.database.ref('RawMaterial').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const raw = new RawMaterialInfo();
        raw.Material_ID = childSnapshot.key;
        raw.Material_Name = childSnapshot.child('_Material_Name').val();
        raw.Material_Type = childSnapshot.child('_Material_Type').val();
        raw.Material_Grade = childSnapshot.child('_Material_Grade').val();
        raw.Thickness = childSnapshot.child('_Thickness').val();
        raw.Width = childSnapshot.child('_Width').val();
        raw.Length = childSnapshot.child('_Length').val();
        raw.Material_Color = childSnapshot.child('_Material_Color').val();
        raw.Raw_Category = childSnapshot.child('_Raw_Category').val();
        raw.Material_Color_Code = childSnapshot.child('_Material_Color_Code').val();
        raw.Material_Color_Ratio = childSnapshot.child('_Material_Color_Ratio').val();
        raw.Material_Spen = childSnapshot.child('_Material_Spen').val();
        raw.Quantity_Reserved = childSnapshot.child('_Quantity_Reserved').val();
        raw.In_Stock = childSnapshot.child('_In_Stock').val();
        raw.Unit = childSnapshot.child('_Unit').val();
        raw.Quantity_PerBag = childSnapshot.child('_Quantity_PerBag').val();
        raw.Unit_Price = childSnapshot.child('_Unit_Price').val();
        raw.Updated_By = childSnapshot.child('_Updated_By').val();
        raw.Created_By = childSnapshot.child('_Created_By').val();
        raw.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        raw.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        raw.Carton_Weight = childSnapshot.child('_Carton_Weight').val();
        raw.Raw_Type = childSnapshot.child('_Raw_Type').val();
        RawList.push(raw);
        if(!raw.Material_Name)
          console.log( raw.Material_ID )
        this.db.database.ref('OldStockRawMaterial').orderByChild('MaterialID').equalTo(raw.Material_ID).once("value").then((stock)=>{
          if(stock.exists()){
            let temp =[]
            stock.forEach(e=>{
              if(e.child('Location').val()){
                const exist = temp.findIndex(t=>t=== e.child('Location').val());
                if(exist === -1){
                  raw.location += e.child('Location').val() + '|';
                  temp.push(e.child('Location').val());
                }
              }
            })
          }
        })


      });
    }
    return RawList;
  }

  add_Raw(_raw: RawMaterialInfo, email: string) {
    var updates = {}
    const id = uuidv4();
    _raw.Raw_Category = "RAW MATERIALS";
    updates['/' + id + '/'] = _raw;
    _raw.Quantity_Reserved = "0";
    _raw.Created_Date = new Date();
    _raw.Last_Updated = new Date();
    _raw.Created_By = email;
    _raw.Updated_By = email;
    this.db.database.ref('RawMaterial').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _raw.Material_Name + ' has been added by the <b>' + email+'</b>',
      date: new Date()
    };

    this.firestore.collection('RawMaterialLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('RawMaterialLog').doc(dateFormat).collection('Raw').add(info);
  }

  add_Outsource(_raw: RawMaterialInfo, email: string) {
    var updates = {}
    const id = uuidv4();
    updates['/' + id + '/'] = _raw;
    _raw.Created_Date = new Date();
    _raw.Last_Updated = new Date();
    _raw.Created_By = email;
    _raw.Updated_By = email;
    this.db.database.ref('Outsource').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _raw.Material_Name + ' has been added by the <b>' + email+'</b>',
      date: new Date()
    };

    this.firestore.collection('OutsourceLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('OutsourceLog').doc(dateFormat).collection('Raw').add(info);
  }

  async getOutsourceList(): Promise<RawMaterialInfo[]> {
    let RawList: RawMaterialInfo[] = [];
    var snapshot = await this.db.database.ref('Outsource').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const raw = new RawMaterialInfo();
        raw.Material_ID = childSnapshot.key;
        raw.Material_Name = childSnapshot.child('_Material_Name').val();
        raw.Updated_By = childSnapshot.child('_Updated_By').val();
        raw.Created_By = childSnapshot.child('_Created_By').val();
        raw.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        raw.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        raw.Raw_Type = childSnapshot.child('_Raw_Type').val();
        RawList.push(raw);
      });
    }
    return RawList;
  }

  update_Raw(_raw: RawMaterialInfo, email: string) {
    var updates = {}
    _raw.Raw_Category = "RAW MATERIALS";
    updates['/' + _raw.Material_ID + '/'] = _raw;
    _raw.Last_Updated = new Date();
    _raw.Updated_By = email;
    this.db.database.ref('RawMaterial').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _raw.Material_Name + ' has been updated by the <b>' + email+'</b>',
      date: new Date()
    };
    this.firestore.collection('RawMaterialLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('RawMaterialLog').doc(dateFormat).collection('Raw').add(info);
  }

  update_Outsource(_raw: RawMaterialInfo, email: string) {
    var updates = {}
    updates['/' + _raw.Material_ID + '/'] = _raw;
    _raw.Last_Updated = new Date();
    _raw.Updated_By = email;
    this.db.database.ref('Outsource').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _raw.Material_Name + ' has been updated by the <b>' + email+'</b>',
      date: new Date()
    };
    this.firestore.collection('OutsourceLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('OutsourceLog').doc(dateFormat).collection('Raw').add(info);
  }

  async getOldStock(): Promise<OldStock[]> {
    let stocklist: OldStock[] = [];
    var snapshot = await this.db.database.ref('OldStockRawMaterial').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const s = new OldStock();
        s.ID = childSnapshot.key;
        s.MaterialName = childSnapshot.child('MaterialName').val();
        s.MaterialID = childSnapshot.child('MaterialID').val();
        s.Quantity = childSnapshot.child('Quantity').val();
        s.Quantity = childSnapshot.child('Quantity').val();
        s.DeductedQuantity = childSnapshot.child('_Deducted_Quantity').val();
        s.Location = childSnapshot.child('Location').val();
        s._Updated_By = childSnapshot.child('_Updated_By').val();
        s._Created_By = childSnapshot.child('_Created_By').val();
        s._Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        s._Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());

        childSnapshot.child('_AssignedPO').forEach(po=>{
          const n = new oldStockPO();
          n.POName = po.key;
          n.CheckOutQuantity = po.child('Checkout Raw Material Quantity').val();
          n.PartNo = po.child('Part No').val();
          n.MachineAssign = po.child('Machine Assign').val();
          n.CheckOutDate = new Date(po.child('Checkout Date').val())
          s.assignedPO.push(n);
        })
        stocklist.push(s);
      });
    }
    return stocklist;
  }

  addOldStock(_stock: OldStock, email: string, id) {
    var updates = {}
    updates['/' + id + '/'] = _stock;
    _stock._Created_Date = new Date();
    _stock._Last_Updated = new Date();
    _stock._Created_By = email;
    _stock._Updated_By = email;
    this.db.database.ref('OldStockRawMaterial').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: 'Old Stock: <b>' + _stock.MaterialName + '</b>\nQuantity: <b>'+ _stock.Quantity + '</b>.\nIt has been added by the <b>' + email+'</b>',
      date: new Date()
    };
    this.db.database.ref('RawMaterial/' + _stock.MaterialID).once('value').then(datasnap => {
      let quantity = parseFloat(datasnap.child('_In_Stock').val());
      quantity += _stock.Quantity;
      this.db.database.ref('RawMaterial/' + _stock.MaterialID + '/_In_Stock').set(quantity.toFixed(2));
      const raw = {
        Last_Updated :new Date(),
        Updated_By : email,
      }
      this.db.database.ref('RawMaterial/' + _stock.MaterialID ).update(raw);

    })
    this.firestore.collection('OldStockLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('OldStockLog').doc(dateFormat).collection('Raw').add(info);
  }

  updateOldStock(_stock: OldStock, email: string) {
    var updates = {}
    updates['/' + _stock.ID + '/'] = _stock;
    _stock._Last_Updated = new Date();
    _stock._Updated_By = email;
    this.db.database.ref('OldStockRawMaterial').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: 'Old Stock: <b>' + _stock.MaterialName + '</b>\nQuantity: <b>'+ _stock.Quantity + '</b>.\nIt has been updated by the <b>' + email+'</b>',
      date: new Date()
    };
    this.firestore.collection('OldStockLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('OldStockLog').doc(dateFormat).collection('Raw').add(info);
  }

  async search_Raw(id: string): Promise<RawMaterialInfo> {
    let searched_raw = new RawMaterialInfo();
    var snapshot = await this.db.database.ref('RawMaterial').child(id).once('value');

    if (snapshot.exists()) {
        searched_raw.Material_ID = snapshot.key;
        searched_raw.Material_Name = snapshot.child('_Material_Name').val();
        searched_raw.Material_Color = snapshot.child('_Material_Color').val();
        searched_raw.Raw_Category = snapshot.child('_Raw_Category').val();
        searched_raw.Material_Color_Code = snapshot.child('_Material_Color_Code').val();
        searched_raw.Material_Color_Ratio = snapshot.child('_Material_Color_Ratio').val();
        searched_raw.Material_Spen = snapshot.child('_Material_Spen').val();
        searched_raw.In_Stock = snapshot.child('_In_Stock').val();
        searched_raw.Quantity_Reserved = snapshot.child('_Quantity_Reserved').val();
        searched_raw.Unit = snapshot.child('_Unit').val();
        searched_raw.Quantity_PerBag = snapshot.child('_Quantity_PerBag').val();
        searched_raw.Unit_Price = snapshot.child('_Unit_Price').val();
        searched_raw.Updated_By = snapshot.child('_Updated_By').val();
        searched_raw.Created_By = snapshot.child('_Created_By').val();
        searched_raw.Created_Date = new Date(snapshot.child('_Created_Date').val());
        searched_raw.Last_Updated = new Date(snapshot.child('_Last_Updated').val());

        searched_raw.Raw_Type = snapshot.child('_Raw_Type').val();
        searched_raw.Carton_Weight = snapshot.child('_Carton_Weight').val();

    }
    return searched_raw;
  }

  async search_RawByName(name: string): Promise<RawMaterialInfo> {
    let searched_raw = new RawMaterialInfo();
    var snapshot = await this.db.database.ref('RawMaterial').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if (childSnapshot.child('_Material_Name').val() === name) {
          searched_raw.Material_ID = childSnapshot.key;
          searched_raw.Material_Name = childSnapshot.child('_Material_Name').val();
          searched_raw.Raw_Category = childSnapshot.child('_Raw_Category').val();
          searched_raw.Material_Color = childSnapshot.child('_Material_Color').val();
          searched_raw.Material_Color_Code = childSnapshot.child('_Material_Color_Code').val();
          searched_raw.Material_Color_Ratio = childSnapshot.child('_Material_Color_Ratio').val();
          searched_raw.Material_Spen = childSnapshot.child('_Material_Spen').val();
          searched_raw.Quantity_Reserved = childSnapshot.child('_Quantity_Reserved').val();
          searched_raw.In_Stock = childSnapshot.child('_In_Stock').val();
          searched_raw.Unit = childSnapshot.child('_Unit').val();
          searched_raw.Quantity_PerBag = childSnapshot.child('_Quantity_PerBag').val();
          searched_raw.Unit_Price = childSnapshot.child('_Unit_Price').val();
          searched_raw.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_raw.Created_By = childSnapshot.child('_Created_By').val();
          searched_raw.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_raw.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
          searched_raw.Raw_Type = childSnapshot.child('_Raw_Type').val();
          searched_raw.Carton_Weight = childSnapshot.child('_Carton_Weight').val();
        }
      });
    }
    return searched_raw;
  }

  async search_OutsourceByName(name: string): Promise<RawMaterialInfo> {
    let searched_raw = new RawMaterialInfo();
    var snapshot = await this.db.database.ref('Outsource').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if (childSnapshot.child('_Material_Name').val() === name) {
          searched_raw.Material_ID = childSnapshot.key;
          searched_raw.Material_Name = childSnapshot.child('_Material_Name').val();
          searched_raw.Raw_Category = childSnapshot.child('_Raw_Category').val();
          searched_raw.Material_Color = childSnapshot.child('_Material_Color').val();
          searched_raw.Material_Color_Code = childSnapshot.child('_Material_Color_Code').val();
          searched_raw.Material_Color_Ratio = childSnapshot.child('_Material_Color_Ratio').val();
          searched_raw.Material_Spen = childSnapshot.child('_Material_Spen').val();
          searched_raw.Quantity_Reserved = childSnapshot.child('_Quantity_Reserved').val();
          searched_raw.In_Stock = childSnapshot.child('_In_Stock').val();
          searched_raw.Unit = childSnapshot.child('_Unit').val();
          searched_raw.Quantity_PerBag = childSnapshot.child('_Quantity_PerBag').val();
          searched_raw.Unit_Price = childSnapshot.child('_Unit_Price').val();
          searched_raw.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_raw.Created_By = childSnapshot.child('_Created_By').val();
          searched_raw.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_raw.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
          searched_raw.Raw_Type = childSnapshot.child('_Raw_Type').val();
          searched_raw.Carton_Weight = childSnapshot.child('_Carton_Weight').val();
        }
      });
    }
    return searched_raw;
  }

  async search_Partial_Raw(name: string): Promise<RawMaterialInfo[]> {
    let rawList: RawMaterialInfo[] = [];
    var snapshot = await this.db.database.ref('RawMaterial').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_raw = new RawMaterialInfo();
        var get_name = childSnapshot.key;
        if (get_name.includes(name)) {
          searched_raw.Material_ID = childSnapshot.key;
          searched_raw.Material_Name = childSnapshot.child('_Material_Name').val();
          searched_raw.Material_Color = childSnapshot.child('_Material_Color').val();
          searched_raw.Raw_Category = childSnapshot.child('_Raw_Category').val();
          searched_raw.Material_Color_Code = childSnapshot.child('_Material_Color_Code').val();
          searched_raw.Material_Color_Ratio = childSnapshot.child('_Material_Color_Ratio').val();
          searched_raw.Material_Spen = childSnapshot.child('_Material_Spen').val();
          searched_raw.In_Stock = childSnapshot.child('_In_Stock').val();
          searched_raw.Quantity_PerBag = childSnapshot.child('_Quantity_PerBag').val();
          searched_raw.Unit = childSnapshot.child('_Unit').val();
          searched_raw.Unit_Price = childSnapshot.child('_Unit_Price').val();
          searched_raw.Quantity_Reserved = childSnapshot.child('_Quantity_Reserved').val();
          searched_raw.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_raw.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_raw.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
          searched_raw.Raw_Type = childSnapshot.child('_Raw_Type').val();
          searched_raw.Carton_Weight = childSnapshot.child('_Carton_Weight').val();

          rawList.push(searched_raw);
        }
      });
    }
    return rawList;
  }

  delete_Raw(id: string, name,  email) {
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: id + ' : ' + name + ' has been deleted by the <b>' + email+'</b>',
      date: new Date()
    };

    this.firestore.collection('RawMaterialLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('RawMaterialLog').doc(dateFormat).collection('Raw').add(info);


    this.db.database.ref('/RawMaterial/' + id).set(null);
  }

  delete_Outsource(id: string, name,  email) {
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: id + ' : ' + name + ' has been deleted by the <b>' + email+'</b>',
      date: new Date()
    };

    this.firestore.collection('OutsourceLog').doc(dateFormat).set({ Date: new Date() });
    this.firestore.collection('OutsourceLog').doc(dateFormat).collection('Raw').add(info);


    this.db.database.ref('Outsource/' + id).set(null);
  }

  async getRawTypeList(): Promise<any[]> {
    let rawTypeList: any[] = [];
    var snapshot = await this.db.database.ref('RawMaterialType').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const info ={
          id: childSnapshot.key,
          value: childSnapshot.child('value').val(),
        }
        rawTypeList.push(info);
      });
    }
    return rawTypeList;
  }

  async getOutsourceTypeList(): Promise<any[]> {
    let rawTypeList: any[] = [];
    var snapshot = await this.db.database.ref('OutsourceType').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const info ={
          id: childSnapshot.key,
          value: childSnapshot.child('value').val(),
        }
        rawTypeList.push(info);
      });
    }
    return rawTypeList;
  }

  async addType(type){
    var updates = {}
    const id = uuidv4();
    updates['/' + id + '/value'] = type;
    this.db.database.ref('RawMaterialType').update(updates);
  }

  async addOutsourceType(type){
    var updates = {}
    const id = uuidv4();
    updates['/' + id + '/value'] = type;
    this.db.database.ref('OutsourceType').update(updates);
  }

  async delete_RawType(id: string) {
    this.db.database.ref('RawMaterialType/' + id).set(null);
  }

  async delete_OutsourceType(id: string) {
    this.db.database.ref('OutsourceType/' + id).set(null);
  }
}
