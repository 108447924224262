import { Chart } from 'chart.js';
import { Part } from './../../Services/Object_Classes/Part/Part';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { ModalService } from '../_modal';
import { ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { PartServices } from 'src/app/Services/Utilities/part.service';
import 'chartjs-chart-radial-gauge';
import { OEEHistoryComponent } from './oee-history/oee-history.component';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements OnInit {
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);

  Staff: Staff[] = [];
  
  // noJobHours: number[] = [];

  constructor(private db: AngularFireDatabase,
    private zone: NgZone,
    private modalService: ModalService,
    private toast: ToastrService,
    private ref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private partSrv: PartServices,
    private firestore: AngularFirestore,
    private dateService: DateFormatService,
    private dialog: MatDialog) {
    
  }

  ngOnInit() {
    this.setup();
  }

  ngAfterViewInit(): void {}

  setup() {
    this.spinner.show();
    this.StaffDB_controller.getStaffListWithPendingJob().then(data => {
      this.Staff = data;
      this.spinner.hide();
    });
  }

  viewOEEHistory(po: Staff) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      machineNumber: po
    };
    this.dialog.open(OEEHistoryComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    });
  }
}
