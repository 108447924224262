import { AngularFirestore } from '@angular/fire/firestore';
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { PartServices } from 'src/app/Services/Utilities/part.service';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {
  //map: google.maps.Map;
  lat = 6.003530779982281;
  lng = 116.1209046;
  mapType = 'roadmap';

  agmMarkers: any[] = [];
  mcar = "https://maps.google.com/mapfiles/kml/shapes/parking_lot_maps.png";

  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);

  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private dateFormat: DateFormatService,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private toast: ToastrService,
    private zone: NgZone,
    private partSrv: PartServices,
    private changeDef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    const currentTime = this.dateFormat.convertDateIntoISO8601(new Date())
    var snapshot = await this.db.database.ref('User').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child("Location") != null){
          if(childSnapshot.child("Location").child('currentDate') != null 
            && (new Date(childSnapshot.child("Location").child('currentDate').val()) >= new Date(currentTime))){
            const person = {
              lat: Number(childSnapshot.child("Location").child('latitude').val()),
              lng: Number(childSnapshot.child("Location").child('longitude').val()),
              //icn: this.mcar
            };
              
            this.agmMarkers.push(person);
          }
        }
      });
    }
  }
}

