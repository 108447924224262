import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { Supplier } from './../../../../Services/Object_Classes/RawMaterial/Supplier';
import { SupplierDB_controller } from './../../../../Services/DB_Controller/SupplierDB_controller';
import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AddRawComponent } from 'src/app/Components/raw/PopUpRaw/add-raw/add-raw.component';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-add-part',
  templateUrl: './add-part.component.html',
  styleUrls: ['./add-part.component.css']
})
export class AddPartComponent implements OnInit {

  part = new Part();
  raw = new RawMaterialInfo();
  email: string;

  searchRaw: any;
  clone = [];
  matSelectRaw: RawMaterialInfo[] = [];

  searchBatch: any;
  cloneBatch = [];
  matSelectBatch: RawMaterialInfo[] = [];

  searchPigment: any;
  clonePigment = [];
  matSelectPigment: RawMaterialInfo[] = [];

  searchCarton: any;
  cloneCarton = [];
  matSelectCarton: RawMaterialInfo[] = [];

  searchPoly: any;
  clonePolyBag = [];
  matSelectPolyBag: RawMaterialInfo[] = [];

  searchInner: any;
  cloneInnerBag = [];
  matSelectInnerBag : RawMaterialInfo[] = [];

  modulesList = ['TREATMENT', 'POWDER COATING', 'MACHINING', 'ENGRAVING', 'OTHERS'];

  counter = 0;
  RawDbController: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  SupplierDbController: SupplierDB_controller = new SupplierDB_controller(this.db, this.firestore);
  CustomerDbController: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);

  searchSupplier: any;
  cloneSupplier : Supplier[] = [];
  matSelectSupplier : Supplier[] = [];

  searchCustomer: any;
  cloneCustomer : Customer[] = [];
  matSelectCustomer : Customer[] = [];

  rawForm: FormGroup;
  packagingForm: FormGroup;
  secondaryForm: FormGroup;
  customerForm: FormGroup;

  ngOnInit() {
  }

  constructor(
    public dialogRefaddPart: MatDialogRef<AddPartComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private angularFireAuth: AngularFireAuth,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.part.Raw_Material = [];
    this.part.Raw_Material.push(new RawMaterialInfo());


    this.rawForm = this.fb.group({
      name:'',
      raws: this.fb.array([]),
    });

    //this.raws().push(this.newRaws('Raw Material'));
    this.RawDbController.getRawList().then(data => {
      this.matSelectRaw = data.filter(r=>r.Raw_Category === 'RAW MATERIALS');
      this.clone = this.matSelectRaw.slice();
    });

    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    /*this.CustomerDbController.getCustomerList().then(data=>{
      this.matSelectCustomer = data;
      this.cloneCustomer = this.matSelectCustomer.slice();
    });

    this.setPackaging();
    this.setSecondaryProcess();
    this.setCustomer();*/

  }

  setSecondaryProcess(){
    const supplier4Control = new FormControl();
    const supplier4FilterControl = new FormControl();

    supplier4FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier4FilterControl);
    });
    supplier4Control.valueChanges.subscribe(() => {
      if (supplier4Control.value) {
        this.searchSupplier = supplier4Control.value;
      }
    });

    const supplier5Control = new FormControl();
    const supplier5FilterControl = new FormControl();

    supplier5FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier5FilterControl);
    });
    supplier5Control.valueChanges.subscribe(() => {
      if (supplier5Control.value) {
        this.searchSupplier = supplier5Control.value;
      }
    });

    this.secondaryForm = this.fb.group({
      supplier4Control: supplier4Control,
      supplier4FilterControl: supplier4FilterControl,
      supplier5Control: supplier5Control,
      supplier5FilterControl: supplier5FilterControl,
      sec1: new FormControl('', Validators.required),
      sec1_Cycle: new FormControl('', Validators.required),
      sec2: new FormControl('', Validators.required),
      sec2_Cycle: new FormControl('', Validators.required),
      usage1: new FormControl('', Validators.required),
      usage2: new FormControl('', Validators.required),
      cycleTime: new FormControl('', Validators.required),


    });
  }

  setPackaging(){
    const cartonControl = new FormControl();
    const cartonfilterControl = new FormControl();

    cartonfilterControl.valueChanges.subscribe(() => {
      this.findCarton(cartonfilterControl);
    });
    cartonControl.valueChanges.subscribe(() => {
      if (cartonControl.value) {
        this.searchCarton = cartonControl.value;
      }
    });

    const PolyControl = new FormControl();
    const PolyFilterControl = new FormControl();

    PolyFilterControl.valueChanges.subscribe(() => {
      this.findPoly(PolyFilterControl);
    });
    PolyControl.valueChanges.subscribe(() => {
      if (PolyControl.value) {
        this.searchPoly = PolyControl.value;
      }
    });

    const InnerControl = new FormControl();
    const InnerFilterControl = new FormControl();

    InnerFilterControl.valueChanges.subscribe(() => {
      this.findInner(InnerFilterControl);
    });
    InnerControl.valueChanges.subscribe(() => {
      if (InnerControl.value) {
        this.searchInner = InnerControl.value;
      }
    });

    const supplier1Control = new FormControl();
    const supplier1FilterControl = new FormControl();

    supplier1FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier1FilterControl);
    });
    supplier1Control.valueChanges.subscribe(() => {
      if (supplier1Control.value) {
        this.searchSupplier = supplier1Control.value;
      }
    });

    const supplier6Control = new FormControl();
    const supplier6FilterControl = new FormControl();

    supplier6FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier6FilterControl);
    });
    supplier6Control.valueChanges.subscribe(() => {
      if (supplier6Control.value) {
        this.searchSupplier = supplier6Control.value;
      }
    });

    this.packagingForm = this.fb.group({
      cartonControl: cartonControl,
      cartonfilterControl: cartonfilterControl,
      InnerControl: InnerControl,
      InnerFilterControl: InnerFilterControl,
      PolyControl: PolyControl,
      PolyFilterControl: PolyFilterControl,
      supplier1Control: supplier1Control,
      supplier1FilterControl: supplier1FilterControl,
      supplier6Control: supplier6Control,
      supplier6FilterControl: supplier6FilterControl,
      qtypoly: new FormControl('', Validators.required),
      qtybox: new FormControl('', Validators.required),
      qtyinner: new FormControl('', Validators.required),

    });
  }

  setCustomer(){
    const customerControl = new FormControl();
    const customerfilterControl = new FormControl();

    customerfilterControl.valueChanges.subscribe(() => {
      this.findCustomer(customerfilterControl);
    });
    customerControl.valueChanges.subscribe(() => {
      if (customerControl.value) {
        this.searchCustomer = customerControl.value;
      }
    });

    this.customerForm = this.fb.group({
      customerControl: customerControl,
      customerfilterControl: customerfilterControl,
      po: new FormControl('', Validators.required),
      pplMold: new FormControl('', Validators.required),
      pplCus: new FormControl('', Validators.required),
      Remark: new FormControl('', Validators.required),
    });
  }

  findRaw(raw) {
    if (!this.clone) { return; }
    const search = raw.value;
    this.matSelectRaw = this.clone.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findBatch(raw) {
    if (!this.cloneBatch) { return; }
    const search = raw.value;
    this.matSelectBatch = this.cloneBatch.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findPigment(raw) {
    if (!this.clonePigment) { return; }
    const search = raw.value;
    this.matSelectPigment = this.clonePigment.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findCarton(raw) {
    if (!this.cloneCarton) { return; }
    const search = raw.value;
    this.matSelectCarton = this.cloneCarton.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findPoly(raw) {
    if (!this.clonePolyBag) { return; }
    const search = raw.value;
    this.matSelectPolyBag = this.clonePolyBag.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findInner(raw) {
    if (!this.cloneInnerBag) { return; }
    const search = raw.value;
    this.matSelectInnerBag = this.cloneInnerBag.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findSupplier(supplier) {
    if (!this.cloneSupplier) { return; }
    const search = supplier.value;
    this.matSelectSupplier = this.cloneSupplier.filter(p => p.Supplier_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findCustomer(cus){
    if (!this.cloneCustomer) { return; }
    const search = cus.value;
    this.matSelectCustomer = this.cloneCustomer.filter(p => p.Customer_Name.toLowerCase().includes(search.toLowerCase()));
  }

  raws(): FormArray {
    return this.rawForm.get('raws') as FormArray;
  }

  newRaws(choice): FormGroup {
    const rawControl = new FormControl();
    const rawfilterControl = new FormControl();

    rawfilterControl.valueChanges.subscribe(() => {
      this.findRaw(rawfilterControl);
    });
    rawControl.valueChanges.subscribe(() => {
      if (rawControl.value) {
        this.searchRaw = rawControl.value;
      }
    });


    const batchControl = new FormControl();
    const batchFilterControl = new FormControl();

    batchFilterControl.valueChanges.subscribe(() => {
      this.findBatch(batchFilterControl);
    });
    batchControl.valueChanges.subscribe(() => {
      if (batchControl.value) {
        this.searchBatch = batchControl.value;
      }
    });

    const pigmentControl = new FormControl();
    const pigmentFilterControl = new FormControl();

    pigmentFilterControl.valueChanges.subscribe(() => {
      this.findPigment(pigmentFilterControl);
    });
    pigmentControl.valueChanges.subscribe(() => {
      if (pigmentControl.value) {
        this.searchPigment = pigmentControl.value;
      }
    });

    const supplier3Control = new FormControl();
    const supplier3FilterControl = new FormControl();
    supplier3Control.setValidators(Validators.required);

    supplier3FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier3FilterControl);
    });
    supplier3Control.valueChanges.subscribe(() => {
      if (supplier3Control.value) {
        this.searchSupplier = supplier3Control.value;
      }
    });

    this.counter++;
    return this.fb.group({
      type: choice,
      rawControl: rawControl,
      rawfilterControl: rawfilterControl,
      batchControl: batchControl,
      batchFilterControl: batchFilterControl,
      pigmentControl: pigmentControl,
      pigmentFilterControl: pigmentFilterControl,
      supplier3Control: supplier3Control,
      supplier3FilterControl: supplier3FilterControl,
      rawAmount: new FormControl('', Validators.required),
      material: new FormControl('', Validators.required),
      rawUOM: new FormControl('', Validators.required),
      spen: new FormControl('', Validators.required),
      ral: new FormControl('', Validators.required),
      matratio: new FormControl('', Validators.required)
    });
  }

  onNoClick(): void {
    this.dialogRefaddPart.close();
  }

  onAddClick(): Part {
    this.dialogRefaddPart.close();
    return this.part;
  }

  addRawMaterial(choice) {
    const form = this.newRaws(choice);
    this.part.Raw_Material.push(new RawMaterialInfo());
    this.raws().push(form);
    this.cd.detectChanges();
  }

  removeRaws(i: number) {
    this.raws().removeAt(i);
    this.part.Raw_Material.splice(i, 1);
  }

  saveRaw(){
    this.raws().controls.forEach( (e, i) => {

      switch(e.get('type').value){
        case 'Raw Material':
          this.part.Raw_Material[i].Material_Name = e.get('rawControl').value;
          this.part.Raw_Material[i].Raw_Amount = e.get('rawAmount').value;
          this.part.Raw_Material[i].Material_ID = this.matSelectRaw.find(r=>r.Material_Name === e.get('rawControl').value).Material_ID;
          this.part.Raw_Material[i].Part_Material_Name = this.matSelectRaw.find(r=>r.Material_Name === e.get('rawControl').value).Material_Name;
          this.part.Raw_Material[i].Raw_Type = 'Raw Material';
          //this.part.Raw_Material[i].Unit = e.get('rawUOM').value;
      }

      //this.part.Raw_Material[i].Raw_Amount = e.get('rawAmount').value;
    });

    this.dialogRefaddPart.close(this.part);
  }

  onFileSelected(event) {
    this.part.DrawingURL = event.target.files[0];
  }

  onFile2Selected(event) {
    this.part.PhotoURL = event.target.files[0];
  }

  onFile3Selected(event) {
    this.part.UnfoldURL = event.target.files[0];
  }

  positionChange(Role){
    if(Role === 'Y')
      this.part.Process = [];
  }

  addNewRawMaterial(){
    const dialogRefaddPart = this.dialog.open(AddRawComponent, {
      width: '50%',
      height: '80%',
      disableClose: true,
    });
    var newRaw = new RawMaterialInfo();
    dialogRefaddPart.afterClosed().subscribe(async result => {
      if (typeof result !== 'undefined') {
        newRaw = result;
        if (!newRaw.In_Stock){
          newRaw.In_Stock = "0";
        }
        
        await this.RawDbController.add_Raw(newRaw, this.email);
        
        this.RawDbController.getRawList().then(data => {
          this.matSelectRaw = data.filter(r=>r.Raw_Category === 'RAW MATERIALS');
          this.clone = this.matSelectRaw.slice();
        });
      }
    });
  }
}
