import { Forklift, PartTracker } from './../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PurchaseOrder } from '../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-detail-po-page',
  templateUrl: './detail-po-page.component.html',
  styleUrls: ['./detail-po-page.component.css']
})
export class DetailPOPageComponent implements OnInit {

  po: PurchaseOrder = new PurchaseOrder();
  PODB_controller: PODB_controller = new PODB_controller(this.db);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);

  matSelectCustomer: Customer[] = [];
  customerlist: Customer[] = [];
  matSelectStaff: Staff[] = [];
  rawTypes = [];
  statusList = [];
  email:string;
  ForkliftNo: string;
  QuotationNo:string;
  QuotationUrl: string;
  InvoiceNo:string;
  InvoiceUrl: string;
  ReasonInner: any = [];
  StatusInner: any;
  reasonInnerFlag: any = [];

  Diagnosed_MC_Type: string;
  Diagnosed_Model:string;
  Diagnosed_Serial_No: string;
  Diagnosed_Remark:string;
  Diagnosed_By:string;

  Serviced_MC_Type: string;
  Serviced_Model:string;
  Serviced_Serial_No: string;
  Serviced_Remark: string;
  Serviced_By: string;

  Diagnosed_Url: string;
  Serviced_Url: string;

  SDiagnosisPhotos = [];
  ServicePhotos = [];

  oldStaff = '';
  oldStatus = '';
  forkliftStatus = '';
  staffName = '';

  recFlag = false;
  reasonFlag = false;
  updateFlag = false;
  checkFlag = false;

  public date: moment.Moment;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;

  constructor(
    public dialogRef: MatDialogRef<DetailPOPageComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.po =cloneDeep(data);
    this.oldStatus = this.po.Status;

    /*if(this.po.Status == 'Invoiced'){
      this.updateFlag = true;
    }
    else */
    if (this.po.Status == 'Rejected' || this.po.Status.includes('Cancelled')){
      this.updateFlag = true;
      this.reasonFlag = true;
    }
    else if(this.po.Status == 'KIV'){
      this.reasonFlag = true;
    }

    this.oldStaff = this.po.Person_In_Charge;
  }

  ngOnInit(): void {
    this.spinner.show();
    this.StaffDB_controller.getStaffList().then(data => {
      data.forEach(staff => {
        this.matSelectStaff.push(staff);
      });

      this.spinner.hide();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  filterStatus(){
    if(this.po.Status.includes('Cancelled') || this.po.Status == 'Rejected' || this.po.Status == 'KIV'){
      this.reasonFlag = true;
    }
    else{
      this.reasonFlag = false;
    }
  }

  filterStatusInner(i: any){
    if(this.po.Forklifts[i].Status == 'Cancelled With Invoice' || this.po.Forklifts[i].Status == 'Cancelled Without Invoice' || this.po.Forklifts[i].Status == 'Rejected' || this.po.Forklifts[i].Status == 'KIV'){
      this.reasonInnerFlag[i] = true;
    }
    else{
      this.reasonInnerFlag[i] = false;
    }
  }

  async updateInner() {
    if(this.StatusInner == null){
      this.toast.error('Please Choose a Status', 'Please Choose a Status!!!');
      return;
    }

    if((this.StatusInner == 'Cancelled With Invoice' || this.StatusInner == 'Cancelled Without Invoice') && this.ReasonInner == null){
      this.toast.error('Please Enter a Reason', 'Please Enter a Reason!!!');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to update the Forklift Detail';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        if(this.StatusInner == 'KIV'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update({
            '_Status': this.StatusInner,
            '_Reason': this.ReasonInner,
          });
        }
        else if(this.StatusInner == 'Cancelled With Invoice'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update({
            '_Status': this.StatusInner,
            '_Reason': this.ReasonInner,
          });
        }
        else if(this.StatusInner == 'Cancelled Without Invoice'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update({
            '_Status': this.StatusInner,
            '_Reason': this.ReasonInner,
          });
        }

        this.toast.success('Update successful');
      }
    });
  }
  
  update(){
    if((this.po.Status.includes('Cancelled') || this.po.Status == 'Rejected' || this.po.Status == 'KIV') && !this.po.Reason){
      this.toast.error('Please Give a reason', 'Please give a reason!');
      return;
    }

    const addPOModel = {
      POName: this.po.PO_No,
      Forklifts: []
    };

    var flag = true;
    if(this.po.Forklifts.length > 0){
      this.po.Forklifts.forEach(async (element, index) => {
        if((element.Status=='Rejected' || element.Status=='Cancelled With Invoice' || element.Status=='Cancelled Without Invoice' || element.Status=='KIV') && !element.Reason){
          this.toast.error('Please Give a reason', 'Please give a reason!');
          flag = false;
        }

        const info = {
          id: element.ID,
          status : element.Status,
          reason : element.Reason || "",
          internal_notes : element.internal_notes || ""
        }

        addPOModel.Forklifts.push(info);
      });
    }

    if(!flag)
      return;

    if (this.po.Forklifts.length > 0 && addPOModel.Forklifts.length === 0) {
      this.toast.error('Please add complete one forklift details', 'Add forklift details');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm update this with this CR? ' + this.po.PO_No;

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        if(this.oldStatus != this.po.Status){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/_Status').set(this.po.Status);
        }

        if(this.oldStaff != null && this.oldStaff != ''){
          await this.db.database.ref('User/' + this.oldStaff + '/Schedule/'+this.po.Schedule + '/').set(null);
        }
        
        if(this.po.Forklifts.length > 0){
          if(this.reasonFlag && (this.po.Status=='Cancelled With Invoice'
          ||this.po.Status=='Cancelled Without Invoice'
          ||this.po.Status=='Rejected'||this.po.Status=='KIV')){
            const update ={}
            update[this.po.PO_No + '/_Status'] = this.po.Status;
            update[this.po.PO_No + '/_Reason'] = this.po.Reason;
            update[this.po.PO_No + '/_Assign_To'] = "-";
            update[this.po.PO_No + '/_Updated_Date'] = new Date();
            update[this.po.PO_No + '/_Updated_By'] = this.email;
            await this.db.database.ref('Purchase Order/').update(update);

            this.po.Forklifts.forEach(async a => {
              await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + a.ID + '/').update({
                '_Status': this.po.Status,
                '_Reason': this.po.Reason,
                '_internal_notes': a.internal_notes
              });
            });

            /*if(this.po.Person_In_Charge != null && this.po.Person_In_Charge
              && this.po.Schedule != null && this.po.Schedule != ''){
              await this.db.database.ref('User/'+this.po.Person_In_Charge+'/Schedule/'+this.po.Schedule+'/').update(null);
            }*/
          }
          else{
            var statuslist = [];
            var poStatus = '';
            var counter = 1;

            await addPOModel.Forklifts.forEach(async (element, index) => {
              await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update({
                '_Status': element.status,
                '_internal_notes': element.internal_notes
              });

              if(element.reason && (element.status=='Cancelled With Invoice'
                ||element.status=='Cancelled Without Invoice'
                ||element.status=='Rejected')){
                await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update({
                  '_Reason': element.reason
                });
              }

              if(!statuslist.includes(element.status)){
                statuslist.push(element.status);

                if(counter == 1){
                  poStatus += element.status;
                }
                else{
                  poStatus = poStatus + "&" + element.status;
                }

                await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/').update({
                  '_Status': poStatus
                });

                counter++;
              }
            });

            const update ={}

            if(this.staffName == null){
              update[this.po.PO_No + '/_Assign_To'] = "-";
              update[this.po.PO_No + '/_Updated_Date'] = new Date();
              update[this.po.PO_No + '/_Updated_By'] = this.email;
              await this.db.database.ref('Purchase Order/').update(update);

              /*if(this.po.Person_In_Charge != null && this.po.Person_In_Charge
                && this.po.Schedule != null && this.po.Schedule != ''){
                await this.db.database.ref('User/'+this.po.Person_In_Charge+'/Schedule/'+this.po.Schedule+'/').update(null);
              }*/
            }
            else{
              var scheduleId=uuidv4();
              update[this.po.PO_No + '/_Status'] = poStatus;
              update[this.po.PO_No + '/_Assign_To'] = this.po.Person_In_Charge;
              update[this.po.PO_No + '/_Updated_Date'] = new Date();
              update[this.po.PO_No + '/_Updated_By'] = this.email;
              update[this.po.PO_No + '/_Schedule'] = scheduleId;
              await this.db.database.ref('Purchase Order/').update(update);
  
              let updates2 = {};
              updates2['_Status'] = 'Pending';
              updates2['_CR_No'] = this.po.PO_No;
              updates2['_Customer'] = this.po.Customer;
              updates2['_Billing_Address'] = this.po.Billing_Address;
              updates2['_Delivery_Address'] = this.po.Delivery_Address || this.po.Billing_Address;
              updates2['_Updated_Date'] = new Date();
              //updates2['_Sequence'] = "1";
              await this.db.database.ref('User/'+this.po.Person_In_Charge+'/Schedule/'+scheduleId+'/').update(updates2);
            }
          }
        }
        else{
          if(this.reasonFlag && (this.po.Status=='Cancelled With Invoice'
            ||this.po.Status=='Cancelled Without Invoice'
            ||this.po.Status=='Rejected'||this.po.Status=='KIV')){
            const update ={}
            update[this.po.PO_No + '/_Status'] = this.po.Status;
            update[this.po.PO_No + '/_Reason'] = this.po.Reason;
            update[this.po.PO_No + '/_Assign_To'] = "-";
            update[this.po.PO_No + '/_Updated_Date'] = new Date();
            update[this.po.PO_No + '/_Updated_By'] = this.email;
            await this.db.database.ref('Purchase Order/').update(update);

            /*if(this.po.Person_In_Charge != null && this.po.Person_In_Charge
              && this.po.Schedule != null && this.po.Schedule != ''){
              await this.db.database.ref('User/'+this.po.Person_In_Charge+'/Schedule/'+this.po.Schedule+'/').set(null);
            }*/
          }
          else{
            const update ={}
            
            //update[this.po.PO_No + '/_Status'] = this.po.Status;
            if(this.staffName == null){
              update[this.po.PO_No + '/_Assign_To'] = "-";
              update[this.po.PO_No + '/_Updated_Date'] = new Date();
              update[this.po.PO_No + '/_Updated_By'] = this.email;
              await this.db.database.ref('Purchase Order/').update(update);

              /*if(this.po.Person_In_Charge != null && this.po.Person_In_Charge
                && this.po.Schedule != null && this.po.Schedule != ''){
                await this.db.database.ref('User/'+this.po.Person_In_Charge+'/Schedule/'+this.po.Schedule+'/').update(null);
              }*/
            }
            else{
              var scheduleId=uuidv4();
              update[this.po.PO_No + '/_Assign_To'] = this.po.Person_In_Charge;
              update[this.po.PO_No + '/_Updated_Date'] = new Date();
              update[this.po.PO_No + '/_Updated_By'] = this.email;
              update[this.po.PO_No + '/_Schedule'] = scheduleId;
              update[this.po.PO_No + '/_Remark'] = this.po.Remark;
              await this.db.database.ref('Purchase Order/').update(update);
  
              let updates2 = {};
              updates2['_Status'] = 'Pending';
              updates2['_CR_No'] = this.po.PO_No;
              updates2['_Customer'] = this.po.Customer;
              updates2['_Billing_Address'] = this.po.Billing_Address;
              updates2['_Delivery_Address'] = this.po.Delivery_Address || this.po.Billing_Address;
              updates2['_Updated_Date'] = new Date();
              //updates2['_Sequence'] = "1";
              await this.db.database.ref('User/'+this.po.Person_In_Charge+'/Schedule/'+scheduleId+'/').update(updates2);
            }
          }
        }
        
        this.dialogRef.close(this.po);
      }
    });
  }

  chooseAssigned(po: Staff = null){
    if(po != null){
      this.staffName = po.StaffName;
    }
    else{
      this.staffName = null;
    }
  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  deleteSchedule(part:PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this schedule?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await this.PODB_controller.delete_ScheduleFromPO(this.po.PO_No, part.MachineNo, part.ID, part.subPart);
        this.dialogRef.close(true);
      }
    })
  }

  showRec(){
    this.recFlag = !this.recFlag;
  }

  showCheck(){
    this.checkFlag = !this.checkFlag

  }

  viewDrawing(url){
    window.open(url, '_blank');
  }

  validate(s){
    return typeof(s) === 'string'? true:false;
  }

  view(check: Forklift){
    this.SDiagnosisPhotos = [];
    this.ServicePhotos = [];
    this.ForkliftNo = check.ID;
    this.recFlag = false;
    this.checkFlag = false;
    this.reasonInnerFlag = false;
    this.ReasonInner = null;
    this.StatusInner = null;
    this.forkliftStatus = check.Status;

    if(check.Quotation != null && check.Quotation != ""){
      this.QuotationNo = check.Quotation;

      if(check.QuotationUrl != null && check.QuotationUrl != ""){
        this.QuotationUrl = check.QuotationUrl;
      }
      else{
        this.QuotationUrl = "";
      }
    }
    else{
      this.QuotationUrl = "";
      this.QuotationNo = "";
    }

    if(check.Invoice != null && check.Invoice != ""){
      this.InvoiceNo = check.Quotation;

      if(check.InvoiceUrl != null && check.InvoiceUrl != ""){
        this.InvoiceUrl = check.InvoiceUrl;
      }
      else{
        this.InvoiceUrl = "";
      }
    }
    else{
      this.InvoiceUrl = "";
      this.InvoiceNo = "";
    }

    if(check.Diagnosed_By != null && check.Diagnosed_By != ""){
      this.Diagnosed_By = check.Diagnosed_By;
      this.Diagnosed_MC_Type = check.Diagnosed_MC_Type;
      this.Diagnosed_Model = check.Diagnosed_Model;
      this.Diagnosed_Serial_No = check.Diagnosed_Serial_No;
      this.Diagnosed_Remark = check.Diagnosed_Remark;
      this.Diagnosed_Url = check.Chekcing_Signature;
      
      if(check.SDiagnosisPhotos != null && check.SDiagnosisPhotos.length > 0){
        this.SDiagnosisPhotos = check.SDiagnosisPhotos;
      }
    }
    else{
      this.Diagnosed_By = null;
    }

    if(check.Serviced_By != null && check.Serviced_By != ""){
      this.Serviced_By = check.Serviced_By;
      this.Serviced_MC_Type = check.Serviced_MC_Type;
      this.Serviced_Model = check.Serviced_Model;
      this.Serviced_Serial_No = check.Serviced_Serial_No;
      this.Serviced_Remark = check.Serviced_Remark;
      this.Serviced_Url = check.Signature;

      if(check.ServicePhotos != null && check.ServicePhotos.length > 0){
        this.ServicePhotos = check.ServicePhotos;
      }
    }
    else{
      this.Serviced_By = null;
    }
  }

  getColorPanel(po:Forklift, index:any = 0){
    if(po.Status == 'Checking'){
      return 'running';
    }
    else if(po.Status.includes('Quotation')){
      return 'warning';
    }
    else if(po.Status == 'Servicing'){
      return 'off';
    }
    else if(po.Status == 'Invoicing'){
      return 'error';
    }
  }

  handleButtonClick(cr: string) {
    /*const url = 'https://speedjin.com/hlbest/sendreport.php';
    const data = {
      crNo: cr
    };
    
    const headers = new HttpHeaders().set('Referrer-Policy', 'unsafe-url');

    this.http.post(url, data, { headers }).subscribe(
      response => {
        console.log('Report sent successfully:', response);
        // Handle success, if needed
      },
      error => {
        console.error('Error sending report:', error);
        // Handle error, if needed
      }
    );*/

    var printWindow = window.open("https://speedjin.com/hlbest/report.php?crNo="+cr, '_blank');
    setTimeout(function(){printWindow.focus();printWindow.close();}, 5000);
  }
}

