import { element } from 'protractor';
import { AngularFireDatabase } from '@angular/fire/database';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { Customer } from '../Object_Classes/Customer/Customer';



export class CustomerDB_controller {
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }

  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getCustomerList(): Promise<Customer[]> {
    let CustomerList: Customer[] = [];
    var snapshot = await this.db.database.ref('Customer').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const cus = new Customer();
        cus.Customer_ID = childSnapshot.key;
        cus.Customer_Name = childSnapshot.child('_Customer_Name').val();
        cus.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
        cus.Address = childSnapshot.child('_Address').val();
        cus.Address2 = childSnapshot.child('_Address2').val();
        cus.Address3 = childSnapshot.child('_Address3').val();
        cus.Address4 = childSnapshot.child('_Address4').val();
        cus.Contact = childSnapshot.child('_Contact').val();
        cus.Contact2 = childSnapshot.child('_Contact2').val();
        cus.Contact3 = childSnapshot.child('_Contact3').val();
        cus.Fax = childSnapshot.child('_Fax').val();
        cus.Email = childSnapshot.child('_Email').val();
        cus.Email2 = childSnapshot.child('_Email2').val();
        cus.Email3 = childSnapshot.child('_Email3').val();
        cus.Updated_By = childSnapshot.child('_Updated_By').val();
        cus.Created_By = childSnapshot.child('_Created_By').val();
        cus.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        cus.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        CustomerList.push(cus);
      })
      ;
    }

     CustomerList = CustomerList.sort((a,b)=>{
      return (a.Customer_Name < b.Customer_Name ? -1 : 1)* (true ? 1 : -1);})

    return CustomerList;
  }

  add_Customer(_cus: Customer,email: string) {
    _cus.Customer_Name = _cus.Customer_Name.trim();
    var updates = {}
    updates['/' + _cus.Customer_ID + '/'] = _cus;
    _cus.Created_Date = new Date();
    _cus.Last_Updated = new Date();
    _cus.Created_By = email;
    _cus.Updated_By = email;
    this.db.database.ref('Customer').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _cus.Customer_Name + ' has been added by the ' + email,
      date: new Date()
    };
    this.firestore.collection('CustomerLog').doc(dateFormat).set({Date: new Date()});
    this.firestore.collection('CustomerLog').doc(dateFormat).collection('Customer').add(info);
  }

  update_Customer(_cus: Customer,email: string) {
    var updates = {}
    updates['/' + _cus.Customer_ID + '/'] = _cus;
    _cus.Last_Updated = new Date();
    _cus.Updated_By = email;
    this.db.database.ref('Customer').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message:  _cus.Customer_Name + ' has been updated by the ' + email,
      date: new Date()
    };
    this.firestore.collection('CustomerLog').doc(dateFormat).set({Date: new Date()});
    this.firestore.collection('CustomerLog').doc(dateFormat).collection('Customer').add(info);
  }

  async search_Customer(id: string): Promise<Customer> {
    let cus = new Customer();
    var snapshot = await this.db.database.ref('Customer').child(id).once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        cus.Customer_ID = childSnapshot.key;
        cus.Customer_Name = childSnapshot.child('_Customer_Name').val();
        cus.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
        cus.Address = childSnapshot.child('_Address').val();
        cus.Address2 = childSnapshot.child('_Address2').val();
        cus.Address3 = childSnapshot.child('_Address3').val();
        cus.Address4 = childSnapshot.child('_Address4').val();
        cus.Contact = childSnapshot.child('_Contact').val();
        cus.Contact2 = childSnapshot.child('_Contact2').val();
        cus.Fax = childSnapshot.child('_Fax').val();
        cus.Email = childSnapshot.child('_Email').val();
        cus.Updated_By = childSnapshot.child('_Updated_By').val();
        cus.Created_By = childSnapshot.child('_Created_By').val();
        cus.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        cus.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
      });
    }
    return cus;
  }

  async search_CustomerByName(name: string): Promise<Customer> {
    let cus = new Customer();
    var snapshot = await this.db.database.ref('Customer').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Customer_Name').val() === name){
          cus.Customer_ID = childSnapshot.key;
          cus.Customer_Name = childSnapshot.child('_Customer_Name').val();
          cus.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
          cus.Address = childSnapshot.child('_Address').val();
          cus.Address2 = childSnapshot.child('_Address2').val();
          cus.Address3 = childSnapshot.child('_Address3').val();
          cus.Address4 = childSnapshot.child('_Address4').val();
          cus.Contact = childSnapshot.child('_Contact').val();
          cus.Contact2 = childSnapshot.child('_Contact2').val();
          cus.Fax = childSnapshot.child('_Fax').val();
          cus.Email = childSnapshot.child('_Email').val();
          cus.Updated_By = childSnapshot.child('_Updated_By').val();
          cus.Created_By = childSnapshot.child('_Created_By').val();
          cus.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          cus.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        }
      });
    }
    return cus;
  }


  async search_Partial_Customer(name: string): Promise<Customer[]> {
    let cusList: Customer[] = [];
    var snapshot = await this.db.database.ref('Customer').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let cus = new Customer();
        var get_name = childSnapshot.key;
        if (get_name.includes(name)) {
          cus.Customer_ID = childSnapshot.key;
          cus.Customer_Name = childSnapshot.child('_Customer_Name').val();
          cus.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
          cus.Address = childSnapshot.child('_Address').val();
          cus.Address2 = childSnapshot.child('_Address2').val();
          cus.Address3 = childSnapshot.child('_Address3').val();
          cus.Address4 = childSnapshot.child('_Address4').val();
          cus.Contact = childSnapshot.child('_Contact').val();
          cus.Contact2 = childSnapshot.child('_Contact2').val();
          cus.Fax = childSnapshot.child('_Fax').val();
          cus.Email = childSnapshot.child('_Email').val();
          cus.Updated_By = childSnapshot.child('_Updated_By').val();
          cus.Created_By = childSnapshot.child('_Created_By').val();
          cus.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          cus.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
          cusList.push(cus);
        }
      });
    }

    return cusList;
  }


  delete_Customer(id: string) {
    this.db.database.ref('/Customer/' + id).set(null);
  }


}
