import { RawMaterialInfo } from '../RawMaterial/RawMaterial';
import { TakeFromStock } from './../PackingList/PackingList';
export class PurchaseOrder {

  private _PO_No: string;
  private _Customer: string;
  public Customer_ID: string;
  public Comments: string;
  private _Customer_PO: string;
  private _Billing_Address: string;
  private _Delivery_Address: string;
  private _Person_In_Charge: string;
  private _Person_Name: string;
  private _PO_Part_List: PartTracker[];
  private _Created_By : string;
  private _Created_By_Name : string;
  private _Created_Date : Date;
  private _Updated_By : string;
  private _Updated_Date : Date;
  private _Longest_Date : Date;
  private _Status : string;
  private _Remark : string;
  private _Pic_IC: string;
  private _Pic_Name: string;
  private _Reason: string;
  private _Rating: string;
  private _Schedule: string;

  public Forklifts : Forklift[] = [];
  public DiagnosisList: PartTracker[];
  public ServiceList: PartTracker[];
  public SDiagnosisPhotos = [];
  public ServicePhotos = [];

  get Person_Name(): string {
    return this._Person_Name;
  }
  set Person_Name(value: string) {
    this._Person_Name = value;
  }

  get Schedule(): string {
    return this._Schedule;
  }
  set Schedule(value: string) {
    this._Schedule = value;
  }

  get Rating(): string {
    return this._Rating;
  }
  set Rating(value: string) {
    this._Rating = value;
  }

  get Reason(): string {
    return this._Reason;
  }
  set Reason(value: string) {
    this._Reason = value;
  }

  get Pic_IC(): string {
    return this._Pic_IC;
  }
  set Pic_IC(value: string) {
    this._Pic_IC = value;
  }

  get Pic_Name(): string {
    return this._Pic_Name;
  }
  set Pic_Name(value: string) {
    this._Pic_Name = value;
  }

  get Remark(): string {
    return this._Remark;
  }
  set Remark(value: string) {
    this._Remark = value;
  }

  get Delivery_Address(): string {
    return this._Delivery_Address;
  }
  set Delivery_Address(value: string) {
    this._Delivery_Address = value;
  }

  get Billing_Address(): string {
    return this._Billing_Address;
  }
  set Billing_Address(value: string) {
    this._Billing_Address = value;
  }
  
  get Customer_PO(): string {
    return this._Customer_PO;
  }
  set Customer_PO(value: string) {
    this._Customer_PO = value;
  }

  get PO_No(): string {
    return this._PO_No;
  }
  set PO_No(value: string) {
    this._PO_No = value;
  }

  get Customer(): string {
    return this._Customer;
  }
  set Customer(value: string) {
    this._Customer = value;
  }

  get Person_In_Charge(): string {
    return this._Person_In_Charge;
  }
  set Person_In_Charge(value: string) {
    this._Person_In_Charge = value;
  }

  get PO_Part_List(): PartTracker[] {
    return this._PO_Part_List;
  }
  set PO_Part_List(value: PartTracker[]) {
    this._PO_Part_List = value;
  }

  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }

  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

  get Longest_Date(): Date {
    return this._Longest_Date;
  }
  set Longest_Date(value: Date) {
    this._Longest_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }

  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }

  get Created_By_Name(): string {
    return this._Created_By_Name;
  }
  set Created_By_Name(value: string) {
    this._Created_By_Name = value;
  }

  get Status(): string {
    return this._Status;
  }
  set Status(value: string) {
    this._Status = value;
  }
}

export class PartTracker {

  private _Raw_Material_Status: string;
  private _PO_Part_No: string;
  private _PO_Part_Qty: number;
  private _PO_Part_Name: string;
  private _PO_Shipping_Date: Date;
  private _PO_Status: string;
  private _Completed_Date: Date;
  private _BoxInfo_List: BoxShippingInfo[];
  private _CheckOut_RawList: CheckOut[];
  private _Added_TO_Packing : boolean;
  private _CartonBoxNeeded : number;
  private _DrawingURL:string;
  private _PhotoURL:string;
  private _UnfoldURL:string;
  public POQuantity : number;
  public partCurrency : string;
  public InStock: number;
  public TakeFromStocks : TakeFromStock[] = [];
  public PIC: string;
  public JOStatus: string;
  public SO_No: string;
  public JO_No: string;
  public StartDate: Date;
  public EndDate: Date;
  public Raw_Material: RawMaterialInfo[] = [];
  public Customer_Name: string;
  public RevNO: string;
  public MachineNo: string;
  public Process = [];
  public Outsource;

  //Generate Label
  private _PartPhoto : string;
  private _PackingListNo : string;
  private _InvoiceNo : string;
  private _CustomerName  = "";
  private _CustomerAddress : string;
  private _Carton_Weight : string;
  private _Part_Weight : string;
  private _QuantityPerBox: string
  //end

  public POS: string = ''
  public Reference: string = ''
  public ID: string = ''

  public subFrom: string = ''

  public subPart: PartTracker[] = [];

  //
  get UnfoldURL(): string {
    return this._UnfoldURL;
  }
  set UnfoldURL(value: string) {
    this._UnfoldURL = value;
  }

  get DrawingURL(): string {
    return this._DrawingURL;
  }
  set DrawingURL(value: string) {
    this._DrawingURL = value;
  }
  
  get PhotoURL(): string {
    return this._PhotoURL;
  }
  set PhotoURL(value: string) {
    this._PhotoURL = value;
  }

  get Raw_Material_Status(): string {
    return this._Raw_Material_Status;
  }
  set Raw_Material_Status(value: string) {
    this._Raw_Material_Status = value;
  }

  get QuantityPerBox(): string {
    return this._QuantityPerBox;
  }
  set QuantityPerBox(value: string) {
    this._QuantityPerBox = value;
  }

  get PartPhoto(): string {
    return this._PartPhoto;
  }
  set PartPhoto(value: string) {
    this._PartPhoto = value;
  }
  get PackingListNo(): string {
    return this._PackingListNo;
  }
  set PackingListNo(value: string) {
    this._PackingListNo = value;
  }
  get InvoiceNo(): string {
    return this._InvoiceNo;
  }
  set InvoiceNo(value: string) {
    this._InvoiceNo = value;
  }
  get CustomerName(): string {
    return this._CustomerName;
  }
  set CustomerName(value: string) {
    this._CustomerName = value;
  }
  get CustomerAddress(): string {
    return this._CustomerAddress;
  }
  set CustomerAddress(value: string) {
    this._CustomerAddress = value;
  }
  get Carton_Weight(): string {
    return this._Carton_Weight;
  }
  set Carton_Weight(value: string) {
    this._Carton_Weight = value;
  }
  get Part_Weight(): string {
    return this._Part_Weight;
  }
  set Part_Weight(value: string) {
    this._Part_Weight = value;
  }
  //





  get CartonBoxNeeded(): number {
    return this._CartonBoxNeeded;
  }
  set CartonBoxNeeded(value: number) {
    this._CartonBoxNeeded = value;
  }

  get Added_TO_Packing(): boolean {
    return this._Added_TO_Packing;
  }
  set Added_TO_Packing(value: boolean) {
    this._Added_TO_Packing = value;
  }

  get BoxInfo_List(): BoxShippingInfo[] {
    return this._BoxInfo_List;
  }
  set BoxInfo_List(value: BoxShippingInfo[]) {
    this._BoxInfo_List = value;
  }

  get CheckOut_RawList(): CheckOut[] {
    return this._CheckOut_RawList;
  }
  set CheckOut_RawList(value: CheckOut[]) {
    this._CheckOut_RawList = value;
  }
  get PO_Part_No(): string {
    return this._PO_Part_No;
  }
  set PO_Part_No(value: string) {
    this._PO_Part_No = value;
  }

  get PO_Part_Qty(): number {
    return this._PO_Part_Qty;
  }
  set PO_Part_Qty(value: number) {
    this._PO_Part_Qty = value;
  }

  get PO_Part_Name(): string {
    return this._PO_Part_Name;
  }
  set PO_Part_Name(value: string) {
    this._PO_Part_Name = value;
  }

  get PO_Shipping_Date(): Date {
    return this._PO_Shipping_Date;
  }
  set PO_Shipping_Date(value: Date) {
    this._PO_Shipping_Date = value;
  }

  get Completed_Date(): Date {
    return this._Completed_Date;
  }
  set Completed_Date(value: Date) {
    this._Completed_Date = value;
  }

  get PO_Status(): string {
    return this._PO_Status;
  }
  set PO_Status(value: string) {
    this._PO_Status = value;
  }
}

export class BoxShippingInfo {
  private _Box_No: string;
  private _Box_Status: string;
  private _Updated_Date: Date;
  private _Status: string;
  private _Packaging_Quantity: string;
  private _Weighting_Status: string;

  get Box_No(): string {
    return this._Box_No;
  }
  set Box_No(value: string) {
    this._Box_No = value;
  }
  get Box_Status(): string {
    return this._Box_Status;
  }
  set Box_Status(value: string) {
    this._Box_Status = value;
  }
  get Weighting_Status(): string {
    return this._Weighting_Status;
  }
  set Weighting_Status(value: string) {
    this._Weighting_Status = value;
  }
  set Status(value: string) {
    this._Status = value;
  }
  get Status(): string {
    return this._Status;
  }
  set Packaging_Quantity(value: string) {
    this._Packaging_Quantity = value;
  }

  get Packaging_Quantity(): string {
    return this._Packaging_Quantity;
  }

  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

}

export class Forklift {
  private _ID: string;
  private _Diagnosed_MC_Type: string;
  private _Diagnosed_Model: string;
  private _Diagnosed_Serial_No: string = '';
  private _Diagnosed_Remark: string;
  private _Diagnosed_Date: Date;
  private _Diagnosed_By: string;
  private _Serviced_MC_Type: string;
  private _Serviced_Model: string;
  private _Serviced_Serial_No: string = '';
  private _Serviced_Remark: string;
  private _Serviced_Date: Date;
  private _Serviced_By: string;
  private _Quotation: string;
  private _QuotationUrl: string;
  private _Invoice: string;
  private _InvoiceUrl: string;
  private _Quotation_Date: Date;
  private _Invoice_Date: Date;
  private _Quotation_By: string;
  private _Invoice_By: string;
  private _Signature: string;
  private _Status: string;
  private _Chekcing_Signature: string;
  public Reason: string;
  public internal_notes: string = "";
  public DiagnosisList: PartTracker[];
  public ServiceList: PartTracker[];
  public SDiagnosisPhotos = [];
  public ServicePhotos = [];
  public PurchaseOrder: string = "";
  public PurchaseOrderUrl: string = ""

  get ID(): string {
    return this._ID;
  }
  set ID(value: string) {
    this._ID = value;
  }

  get Status(): string {
    return this._Status;
  }
  set Status(value: string) {
    this._Status = value;
  }

  get Diagnosed_MC_Type(): string {
    return this._Diagnosed_MC_Type;
  }
  set Diagnosed_MC_Type(value: string) {
    this._Diagnosed_MC_Type = value;
  }

  get Diagnosed_Model(): string {
    return this._Diagnosed_Model;
  }
  set Diagnosed_Model(value: string) {
    this._Diagnosed_Model = value;
  }

  get Diagnosed_Serial_No(): string {
    return this._Diagnosed_Serial_No;
  }
  set Diagnosed_Serial_No(value: string) {
    this._Diagnosed_Serial_No = value;
  }

  get Diagnosed_Remark(): string {
    return this._Diagnosed_Remark;
  }
  set Diagnosed_Remark(value: string) {
    this._Diagnosed_Remark = value;
  }

  get Diagnosed_Date(): Date {
    return this._Diagnosed_Date;
  }
  set Diagnosed_Date(value: Date) {
    this._Diagnosed_Date = value;
  }

  get Diagnosed_By(): string {
    return this._Diagnosed_By;
  }
  set Diagnosed_By(value: string) {
    this._Diagnosed_By = value;
  }

  get Serviced_MC_Type(): string {
    return this._Serviced_MC_Type;
  }
  set Serviced_MC_Type(value: string) {
    this._Serviced_MC_Type = value;
  }

  get Serviced_Model(): string {
    return this._Serviced_Model;
  }
  set Serviced_Model(value: string) {
    this._Serviced_Model = value;
  }

  get Serviced_Serial_No(): string {
    return this._Serviced_Serial_No;
  }
  set Serviced_Serial_No(value: string) {
    this._Serviced_Serial_No = value;
  }

  get Serviced_Remark(): string {
    return this._Serviced_Remark;
  }
  set Serviced_Remark(value: string) {
    this._Serviced_Remark = value;
  }

  get Serviced_Date(): Date {
    return this._Serviced_Date;
  }
  set Serviced_Date(value: Date) {
    this._Serviced_Date = value;
  }

  get Serviced_By(): string {
    return this._Serviced_By;
  }
  set Serviced_By(value: string) {
    this._Serviced_By = value;
  }

  get Quotation(): string {
    return this._Quotation;
  }
  set Quotation(value: string) {
    this._Quotation = value;
  }
  
  get QuotationUrl(): string {
    return this._QuotationUrl;
  }
  set QuotationUrl(value: string) {
    this._QuotationUrl = value;
  }

  get Invoice(): string {
    return this._Invoice;
  }
  set Invoice(value: string) {
    this._Invoice = value;
  }

  get InvoiceUrl(): string {
    return this._InvoiceUrl;
  }
  set InvoiceUrl(value: string) {
    this._InvoiceUrl = value;
  }

  get Quotation_By(): string {
    return this._Quotation_By;
  }
  set Quotation_By(value: string) {
    this._Quotation_By = value;
  }

  get Invoice_By(): string {
    return this._Invoice_By;
  }
  set Invoice_By(value: string) {
    this._Invoice_By = value;
  }

  get Quotation_Date(): Date {
    return this._Quotation_Date;
  }
  set Quotation_Date(value: Date) {
    this._Quotation_Date = value;
  }

  get Invoice_Date(): Date {
    return this._Invoice_Date;
  }
  set Invoice_Date(value: Date) {
    this._Invoice_Date = value;
  }

  get Chekcing_Signature(): string {
    return this._Chekcing_Signature;
  }
  set Chekcing_Signature(value: string) {
    this._Chekcing_Signature = value;
  }

  get Signature(): string {
    return this._Signature;
  }
  set Signature(value: string) {
    this._Signature = value;
  }
}

export class CheckOut {
  private _Raw_PO: string;
  private _RawCheckOutList: RawCheckOut[];

  get Raw_PO(): string {
    return this._Raw_PO;
  }
  set Raw_PO(value: string) {
    this._Raw_PO = value;
  }

  get RawCheckOutList(): RawCheckOut[] {
    return this._RawCheckOutList;
  }
  set RawCheckOutList(value: RawCheckOut[]) {
    this._RawCheckOutList = value;
  }
}

export class RawCheckOut {
  private _Raw_ID: string;
  private _Raw_Name: string;
  private _CheckOut_Date: Date;
  private _CheckOut_Quantity: string;

  get Raw_ID(): string {
    return this._Raw_ID;
  }

  set Raw_ID(value: string) {
    this._Raw_ID = value;
  }
  get Raw_Name(): string {
    return this._Raw_Name;
  }
  set Raw_Name(value: string) {
    this._Raw_Name = value;
  }
  set CheckOut_Quantity(value: string) {
    this._CheckOut_Quantity = value;
  }
  get CheckOut_Quantity(): string {
    return this._CheckOut_Quantity;
  }

  get CheckOut_Date(): Date {
    return this._CheckOut_Date;
  }
  set CheckOut_Date(value: Date) {
    this._CheckOut_Date = value;
  }
}

