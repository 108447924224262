import { Forklift, PartTracker } from './../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PurchaseOrder } from '../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-update-schedule',
  templateUrl: './update-schedule.component.html',
  styleUrls: ['./update-schedule.component.css']
})
export class UpdateScheduleComponent implements OnInit {

  po: PurchaseOrder = new PurchaseOrder();
  PODB_controller: PODB_controller = new PODB_controller(this.db);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);

  matSelectCustomer: Customer[] = [];
  customerlist: Customer[] = [];
  matSelectStaff: Staff[] = [];
  rawTypes = [];
  email:string;
  scheduleId: string;
  staffId: string;
  ForkliftNo: string;
  QuotationNo:string;
  QuotationUrl: string;
  InvoiceNo:string;
  InvoiceUrl: string;

  Diagnosed_MC_Type: string;
  Diagnosed_Model:string;
  Diagnosed_Serial_No: string;
  Diagnosed_Remark:string;
  Diagnosed_By:string;

  Serviced_MC_Type: string;
  Serviced_Model:string;
  Serviced_Serial_No: string;
  Serviced_Remark: string;
  Serviced_By: string;

  Diagnosed_Url: string;
  Serviced_Url: string;

  SDiagnosisPhotos = [];
  ServicePhotos = [];

  staffName = '';
  oldStaff = '';
  oldStatus = '';
  forkliftStatus = '';
  DrawingURL: any = [];
  PhotoURL: any = [];
  POURL: any = [];
  scheduleInformation: any;

  recFlag = false;
  reasonFlag = false;
  updateFlag = false;
  checkFlag = false;

  public date: moment.Moment;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;

  constructor(
    public dialogRef: MatDialogRef<UpdateScheduleComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.po =cloneDeep(data);

    this.po.Forklifts.forEach(data => {
      this.DrawingURL.push('');
      this.PhotoURL.push('');
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.StaffDB_controller.getStaffList().then(data => {
      data.forEach(staff => {
        if(staff.StaffName == this.oldStaff){
          this.po.Person_In_Charge = staff.StaffID
        }

        this.matSelectStaff.push(staff);
      });

      this.spinner.hide();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  filterStatus(){
    if(this.po.Status == 'Cancelled' || this.po.Status == 'Rejected'){
      this.reasonFlag = true;
    }
    else{
      this.reasonFlag = false;
    }
  }
  
  async update() {
    if (this.forkliftStatus == "Quotation" && (this.DrawingURL == '' || this.DrawingURL == null)) {
      this.toast.warning("Please Upload the quoation !!!");
      return;
    }

    if (this.forkliftStatus == "Invoicing" && (this.PhotoURL == '' || this.PhotoURL == null)) {
      this.toast.warning("Please Upload the invoice !!!");
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to update the Forklift Details';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.spinner.show();

        if(this.forkliftStatus == 'Checking'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update({
            '_Diagnosed_MC_Type': this.Diagnosed_MC_Type,
            '_Diagnosed_Model': this.Diagnosed_Model, 
            '_Diagnosed_Serial_No': this.Diagnosed_Serial_No, 
            '_Diagnosed_Remark': this.Diagnosed_Remark || '-',
            '_Assign_To': "-",
            '_Status': "Quotation",
            '_Diagnosed_Date': new Date(),
            '_Diagnosed_By': this.email
          });
        }
        else if(this.forkliftStatus == 'Quotation'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update({
            '_Quotation': this.QuotationNo,
            '_Assign_To': "-",
            '_Status': "Servicing",
            '_Quotation_Date': new Date(),
            '_Quotation_By': this.email
          });
    
          if(this.DrawingURL != null && this.DrawingURL != ''){
            if (typeof (this.DrawingURL) !== 'string') {
              var n = Date.now();
              var filePath = `QuotationImages/${n}`;
              var fileRef = this.storage.ref(filePath);
              const task = this.storage.upload(`QuotationImages/${n}`, this.DrawingURL);
              task.snapshotChanges().pipe(
                finalize(() => {
                  var downloadURL = fileRef.getDownloadURL();
                  downloadURL.subscribe(async url => {
                    if (url) {
                      const photo = {}
                      this.DrawingURL = url;
                      photo['_Quotation_URL'] = this.DrawingURL;
                      await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update(photo);
                    }
                  });
                })
              ).subscribe(url => {
                if (url) {
                  console.log(url);
                }
              });
            }
            else {
              const photo = {}
              photo['_Quotation_URL'] = this.DrawingURL;
              await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update(photo);
            }
          }
        }
        else if(this.forkliftStatus == 'Servicing'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update({
            '_Serviced_MC_Type': this.Serviced_MC_Type,
            '_Serviced_Model': this.Serviced_Model, 
            '_Serviced_Serial_No': this.Serviced_Serial_No, 
            '_Serviced_Remark': this.Serviced_Remark || '-',
            '_Assign_To': "-",
            '_Status': "Invoicing",
            '_Serviced_Date': new Date(),
            '_Serviced_By': this.email
          });
        }
        else if(this.forkliftStatus == 'Invoicing'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update({
            '_Invoice': this.InvoiceNo,
            '_Assign_To': "-",
            '_Status': "Invoiced",
            '_Invoice_Date': new Date(),
            '_Invoice_By': this.email
          });
    
          if(this.PhotoURL != null && this.PhotoURL != ''){
            if (typeof (this.PhotoURL) !== 'string') {
              var n = Date.now();
              var filePath = `InvoiceImages/${n}`;
              var fileRef = this.storage.ref(filePath);
              const task = this.storage.upload(`InvoiceImages/${n}`, this.PhotoURL);
              task.snapshotChanges().pipe(
                finalize(() => {
                  var downloadURL = fileRef.getDownloadURL();
                  downloadURL.subscribe(async url => {
                    if (url) {
                      const photo = {}
                      this.PhotoURL = url;
                      photo['_Invoice_URL'] = this.PhotoURL;
                      await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update(photo);
                    }
                  });
                })
              ).subscribe(url => {
                if (url) {
                  console.log(url);
                }
              });
            }
            else {
              const photo = {}
              photo['_Invoice_URL'] = this.PhotoURL;
              await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + this.ForkliftNo + '/').update(photo);
            }
          }
        }

        this.toast.success('Update successful');
        this.spinner.hide();
      }
    });
  }

  chooseAssigned(po: Staff){
    this.staffName = po.StaffName;
  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  deleteSchedule(part:PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this schedule?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await this.PODB_controller.delete_ScheduleFromPO(this.po.PO_No, part.MachineNo, part.ID, part.subPart);
        this.dialogRef.close(true);
      }
    })
  }

  complete(){
    const addPOModel = {
      POName: this.po.PO_No,
      Forklifts: []
    };

    var flag = true;
    if(this.po.Forklifts.length > 0){
      this.po.Forklifts.forEach(async (element, index) => {
        if(element.Status=='Quotation' && !element.Quotation){
          this.toast.error('Please Enter Quotation No.', 'Please Enter Quotation No.!');
          flag = false;
        }

        if(element.Status=='Invoicing' && !element.Invoice){
          this.toast.error('Please Enter Invoice No.', 'Please Enter Invoice No.!');
          flag = false;
        }

        const info = {
          id: element.ID,
          status : element.Status,
          quotation : element.Quotation || "",
          quotationUrl : this.DrawingURL[index] || "",
          purchaseOrder : element.PurchaseOrder || "",
          purchaseOrderUrl : this.POURL[index] || "",
          invoice : element.Invoice || "",
          invoiceUrl : this.PhotoURL[index] || ""
        }

        addPOModel.Forklifts.push(info);
      });
    }

    if(!flag)
      return;

    if (this.po.Forklifts.length > 0 && addPOModel.Forklifts.length === 0) {
      this.toast.error('Please add complete one forklift details', 'Add forklift details');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really update this schedule?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        var statuslist = [];
        var poStatus = '';
        var counter = 1;

        await addPOModel.Forklifts.forEach(async (element, index) => {
          if(element.status == 'Checking'){
            element.status = "Quotation";

            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update({
              '_Diagnosed_MC_Type': element.Diagnosed_MC_Type,
              '_Diagnosed_Model': element.Diagnosed_Model, 
              '_Diagnosed_Serial_No': element.Diagnosed_Serial_No, 
              '_Diagnosed_Remark': element.Diagnosed_Remark || '-',
              '_Assign_To': "-",
              '_Status': "Quotation",
              '_Diagnosed_Date': new Date(),
              '_Diagnosed_By': this.email
            });
          }
          else if(element.status == 'Quotation'){
            element.status = "Servicing";

            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update({
              '_Quotation': element.quotation,
              '_PurchaseOrder': element.purchaseOrder || '',
              '_Assign_To': "-",
              '_Status': "Servicing",
              '_Quotation_Date': new Date(),
              '_Quotation_By': this.email
            });
      
            if(element.quotationUrl != null && element.quotationUrl != ''){
              if (typeof (element.quotationUrl) !== 'string') {
                var n = Date.now();
                var filePath = `QuotationImages/${n}`;
                var fileRef = this.storage.ref(filePath);
                const task = this.storage.upload(`QuotationImages/${n}`, element.quotationUrl);
                task.snapshotChanges().pipe(
                  finalize(() => {
                    var downloadURL = fileRef.getDownloadURL();
                    downloadURL.subscribe(async url => {
                      if (url) {
                        const photo = {}
                        element.quotationUrl = url;
                        photo['_Quotation_URL'] = element.quotationUrl;
                        await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id+ '/').update(photo);
                      }
                    });
                  })
                ).subscribe(url => {
                  if (url) {
                    console.log(url);
                  }
                });
              }
              else {
                const photo = {}
                photo['_Quotation_URL'] = element.quotationUrl;
                await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update(photo);
              }
            }

            if(element.purchaseOrderUrl != null && element.purchaseOrderUrl != ''){
              if (typeof (element.purchaseOrderUrl) !== 'string') {
                var n = Date.now();
                var filePath = `PurchaseOrderImages/${n}`;
                var fileRef = this.storage.ref(filePath);
                const task = this.storage.upload(`PurchaseOrderImages/${n}`, element.purchaseOrderUrl);
                task.snapshotChanges().pipe(
                  finalize(() => {
                    var downloadURL = fileRef.getDownloadURL();
                    downloadURL.subscribe(async url => {
                      if (url) {
                        const photo = {}
                        element.purchaseOrderUrl = url;
                        photo['_PurchaseOrder_URL'] = element.purchaseOrderUrl;
                        await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id+ '/').update(photo);
                      }
                    });
                  })
                ).subscribe(url => {
                  if (url) {
                    console.log(url);
                  }
                });
              }
              else {
                const photo = {}
                photo['_PurchaseOrder_URL'] = element.purchaseOrderUrl;
                await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update(photo);
              }
            }
          }
          else if(element.status == 'Servicing'){
            element.status = "Invoicing";

            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update({
              '_Serviced_MC_Type': element.Serviced_MC_Type,
              '_Serviced_Model': element.Serviced_Model, 
              '_Serviced_Serial_No': element.Serviced_Serial_No, 
              '_Serviced_Remark': element.Serviced_Remark || '-',
              '_Assign_To': "-",
              '_Status': "Invoicing",
              '_Serviced_Date': new Date(),
              '_Serviced_By': this.email
            });
          }
          else if(element.status == 'Invoicing'){
            element.status = "Invoiced";

            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update({
              '_Invoice': element.invoice,
              '_PurchaseOrder': element.purchaseOrder || '',
              '_Assign_To': "-",
              '_Status': "Invoiced",
              '_Invoice_Date': new Date(),
              '_Invoice_By': this.email
            });
      
            if(element.invoiceUrl != null && element.invoiceUrl != ''){
              if (typeof (element.invoiceUrl) !== 'string') {
                var n = Date.now();
                var filePath = `InvoiceImages/${n}`;
                var fileRef = this.storage.ref(filePath);
                const task = this.storage.upload(`InvoiceImages/${n}`, element.invoiceUrl);
                task.snapshotChanges().pipe(
                  finalize(() => {
                    var downloadURL = fileRef.getDownloadURL();
                    downloadURL.subscribe(async url => {
                      if (url) {
                        const photo = {}
                        element.invoiceUrl = url;
                        photo['_Invoice_URL'] = element.invoiceUrl;
                        await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update(photo);
                      }
                    });
                  })
                ).subscribe(url => {
                  if (url) {
                    console.log(url);
                  }
                });
              }
              else {
                const photo = {}
                photo['_Invoice_URL'] = element.invoiceUrl;
                await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update(photo);
              }
            }

            if(element.purchaseOrderUrl != null && element.purchaseOrderUrl != ''){
              if (typeof (element.purchaseOrderUrl) !== 'string') {
                var n = Date.now();
                var filePath = `PurchaseOrderImages/${n}`;
                var fileRef = this.storage.ref(filePath);
                const task = this.storage.upload(`PurchaseOrderImages/${n}`, element.purchaseOrderUrl);
                task.snapshotChanges().pipe(
                  finalize(() => {
                    var downloadURL = fileRef.getDownloadURL();
                    downloadURL.subscribe(async url => {
                      if (url) {
                        const photo = {}
                        element.purchaseOrderUrl = url;
                        photo['_PurchaseOrder_URL'] = element.purchaseOrderUrl;
                        await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id+ '/').update(photo);
                      }
                    });
                  })
                ).subscribe(url => {
                  if (url) {
                    console.log(url);
                  }
                });
              }
              else {
                const photo = {}
                photo['_PurchaseOrder_URL'] = element.purchaseOrderUrl;
                await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Forklift/' + element.id + '/').update(photo);
              }
            }
          }

          if(!statuslist.includes(element.status)){
            statuslist.push(element.status);

            if(counter == 1){
              poStatus += element.status;
            }
            else{
              poStatus = poStatus + "&" + element.status;
            }

            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/').update({
              '_Status': poStatus,
              '_Updated_Date': new Date()
            });

            counter++;
          }
        });

        await this.db.database.ref('User/' + this.po.Person_In_Charge + '/Schedule/' + this.po.Schedule).update({
          '_Status': 'Completed'
        });

        this.dialogRef.close(true);
      }
    })
  }

  showRec(){
    this.recFlag = !this.recFlag;
  }

  showCheck(){
    this.checkFlag = !this.checkFlag

  }

  viewDrawing(url){
    window.open(url, '_blank');
  }

  validate(s){
    return typeof(s) === 'string'? true:false;
  }

  onFileSelected(event, i:any) {
    this.DrawingURL[i] = event.target.files[0];
  }

  onFile2Selected(event, i:any) {
    this.PhotoURL[i] = event.target.files[0];
  }

  onFile3Selected(event, i:any) {
    this.POURL[i] = event.target.files[0];
  }

  view(check: Forklift){
    this.SDiagnosisPhotos = [];
    this.ServicePhotos = [];
    this.ForkliftNo = check.ID;
    this.recFlag = false;
    this.checkFlag = false;
    this.forkliftStatus = check.Status;

    if(check.Quotation != null && check.Quotation != ""){
      this.QuotationNo = check.Quotation;

      if(check.QuotationUrl != null && check.QuotationUrl != ""){
        this.QuotationUrl = check.QuotationUrl;
      }
      else{
        this.QuotationUrl = "";
      }
    }
    else{
      this.QuotationUrl = "";
      this.QuotationNo = "";
    }

    if(check.Invoice != null && check.Invoice != ""){
      this.InvoiceNo = check.Quotation;

      if(check.InvoiceUrl != null && check.InvoiceUrl != ""){
        this.InvoiceUrl = check.InvoiceUrl;
      }
      else{
        this.InvoiceUrl = "";
      }
    }
    else{
      this.InvoiceUrl = "";
      this.InvoiceNo = "";
    }

    if(check.Diagnosed_By != null && check.Diagnosed_By != ""){
      this.Diagnosed_By = check.Diagnosed_By;
      this.Diagnosed_MC_Type = check.Diagnosed_MC_Type;
      this.Diagnosed_Model = check.Diagnosed_Model;
      this.Diagnosed_Serial_No = check.Diagnosed_Serial_No;
      this.Diagnosed_Remark = check.Diagnosed_Remark;
      this.Diagnosed_Url = check.Chekcing_Signature;
      
      if(check.SDiagnosisPhotos != null && check.SDiagnosisPhotos.length > 0){
        this.SDiagnosisPhotos = check.SDiagnosisPhotos;
      }
    }
    else{
      this.Diagnosed_By = null;
    }

    if(check.Serviced_By != null && check.Serviced_By != ""){
      this.Serviced_By = check.Serviced_By;
      this.Serviced_MC_Type = check.Serviced_MC_Type;
      this.Serviced_Model = check.Serviced_Model;
      this.Serviced_Serial_No = check.Serviced_Serial_No;
      this.Serviced_Remark = check.Serviced_Remark;
      this.Serviced_Url = check.Signature;

      if(check.ServicePhotos != null && check.ServicePhotos.length > 0){
        this.ServicePhotos = check.ServicePhotos;
      }
    }
    else{
      this.Serviced_By = null;
    }
  }
}

