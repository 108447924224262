import { DowntimeDetailsComponent } from './downtime-details/downtime-details.component';
import { ScheduleTracker } from './../../Services/Object_Classes/Machine/Machine';
import { AddPOComponent } from './add-po/add-po.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { ShippingInformationComponent } from './shipping-information/shipping-information.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { MatPaginator, PageEvent, Sort,  MatSelectModule} from '@angular/material';
import { OrdersComponent } from '../raw/PO/orders/orders.component';
import { DateFormatService } from './../../Services/Utilities/date-format.service';
import {Request} from 'httprequest'
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AddCustomerComponent } from './Customer/add-customer/add-customer.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { DetailPOPageComponent } from './detail-po/detail-po-page.component';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { PackingList_PO_List, PackingList, PackingList_PO_Part_List } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AddPackingComponent } from '../invoice/add-packing/add-packing.component';
import { PackingListDB_controller } from 'src/app/Services/DB_Controller/PackingListDB_controller';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { GenerateOldLabelComponent } from '../invoice/generate-old-label/generate-old-label.component';
import { GenerateQRCodeDialogComponent } from '../invoice/generate-qrcode-dialog/generate-qrcode-dialog.component';
import { UpdatePackingComponent } from '../invoice/update-packing/update-packing.component';
import { v4 as uuidv4 } from 'uuid';
import { UploadExcelDialogComponent } from 'src/app/Shared/upload-excel-dialog/upload-excel-dialog.component';
import { RawCheckOutInformationComponent } from './raw-check-out-information/raw-check-out-information.component';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-purchaseOrder',
  templateUrl: './purchaseOrder.component.html',
  styleUrls: ['./purchaseOrder.component.css']
})
export class PurchaseOrderComponent implements OnInit {

  POno: string;
  partNo: string;
  POquantity: number;
  CustomerDB_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);
  email: string;

  matSelectCustomer: Customer[] = [];
  customerlist: Customer[] = [];
  POlist: PurchaseOrder[] = [];
  Staff: Staff[] = [];
  ClonePOList: PurchaseOrder[] = [];
  CloneIncompletePOList: PurchaseOrder[] = [];
  IncompletePOList: PurchaseOrder[] = [];

  //Monitoring
  monitorlength = 0;
  monitorpageSize = 25;
  monitorpageSizeOptions: number[] = [10, 25, 50];
  monitorpageIndex = 0;
  monitoroffset = this.monitorpageSize * this.monitorpageIndex;
  searchmonitor: string;
  sortmonitor = {
    active: '',
    direction: ''
  };
  @ViewChild('topmonitorPaginator', { read: MatPaginator, static: true }) topmonitorPaginator: MatPaginator;
  @ViewChild('bottommonitorPaginator', { read: MatPaginator, static: true }) bottommonitorPaginator: MatPaginator;

  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  //Pagination Customer
  lengthcus = 0;
  pageSizecus  = 10;
  pageSizeOptionscus : number[] = [10, 25, 50];
  pageIndexcus  = 0;
  offsetcus  = this.pageSizecus  * this.pageIndexcus ;
  searchcus : string;
  sorteducus  = {
    active: '',
    direction: ''
  };
  Customer: Customer[] = [];
  CloneCustomer: Customer[] = [];
  @ViewChild('topPaginatorcus', { read: MatPaginator, static: false }) topPaginatorcus : MatPaginator;
  @ViewChild('bottomPaginatorcus', { read: MatPaginator, static: false }) bottomPaginatorcus : MatPaginator;


  //Pagination KIV Request
  lengthJob = 0;
  pageSizeJob  = 10;
  pageSizeOptionsJob : number[] = [10, 25, 50];
  pageIndexJob  = 0;
  offsetJob  = this.pageSizeJob  * this.pageIndexJob ;
  searchJob : string;
  sorteduJob  = {
    active: '',
    direction: ''
  };
  Job: PurchaseOrder[] = [];
  CloneJob: PurchaseOrder[] = [];
  @ViewChild('topPaginatorJob', { read: MatPaginator, static: false }) topPaginatorJob : MatPaginator;
  @ViewChild('bottomPaginatorJob', { read: MatPaginator, static: false }) bottomPaginatorJob : MatPaginator;

  //Pagination Rejected Request
  lengthRejectJob = 0;
  pageSizeRejectJob  = 10;
  pageSizeOptionsRejectJob : number[] = [10, 25, 50];
  pageIndexRejectJob  = 0;
  offsetRejectJob  = this.pageSizeRejectJob  * this.pageIndexRejectJob ;
  searchRejectJob : string;
  sorteduRejectJob  = {
    active: '',
    direction: ''
  };
  RejectJob: PurchaseOrder[] = [];
  CloneRejectJob: PurchaseOrder[] = [];
  @ViewChild('topPaginatorJob', { read: MatPaginator, static: false }) topPaginatorRejectJob : MatPaginator;
  @ViewChild('bottomPaginatorJob', { read: MatPaginator, static: false }) bottomPaginatorRejectJob : MatPaginator;


  PODB_controller: PODB_controller = new PODB_controller(this.db);
  machineDB_controller = new MachineDB_controller(this.db);

  machinelist = []
  role:string;
  acccessRight:any[]=[];
  poStatus: string = "";
  poStatus2: string = "";
  poStaff: string = "";
  poCustomer: string = "";
  disableDelete = true;
  searchControl = new FormControl();

  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private angularFireAuth: AngularFireAuth,
    private excelHelper: ExcelHelperService,
    private firestore: AngularFirestore,
    private userinfoSrv:UserInfoService,
    private toast: ToastrService
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });

    this.userinfoSrv.currentAccessRights.subscribe(access=>{
      this.acccessRight = access
      if(this.userinfoSrv.checkPermission(this.role, 'PO Management',this.acccessRight)){
        this.disableDelete = false;
      }
    });
  }

  check(PO: PurchaseOrder){
    const result = PO.PO_Part_List.find(p=>p.Added_TO_Packing===true)
    if(result ){
      return true;
    }
    else{
      return false;
    }
  }

  ngOnInit() {
    this.setup();
    this.initcus();
    this.initJob();

    this.searchControl.valueChanges.subscribe(() => {
      this.onValueChanged(this.searchControl);
    });
  }

  refresh(){
    this.setup();
    this.initcus();
    this.initJob();
    this.poStaff = "";
    this.poStatus2 = "";
    this.poCustomer = "";
  }
  
  setup(){
    this.spinner.show();
    this.IncompletePOList = [];

    this.PODB_controller.getPOList().then(data => {
      this.POlist = data;

      this.POlist.sort(function(o1,o2){
        return o1.Created_Date ? -1 : o2.Created_Date ? 1 : 0;
      });

      this.length = this.POlist.length;
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      this.filterStatus();
      this.spinner.hide();
    });

    this.PODB_controller.search_PO_withStatusNotCompleted().then(async data => {
      this.IncompletePOList = data;

      await this.IncompletePOList.sort(function(o1,o2){
        return o1.Updated_Date ? -1 : o2.Updated_Date ? 1 : 0;
      });

      this.monitorlength = this.IncompletePOList.length;
      this.CloneIncompletePOList = this.IncompletePOList.slice();
      this.limitmonitorList();

      if(this.poCustomer != ""){
        this.filterCustomer();
      }

      if(this.poStatus2 != ""){
        this.filterStatus2();
      }

      if(this.poStaff != ""){
        this.filterStaff();
      }
    });
    
    this.StaffDB_controller.getStaffList().then(data => {
      this.Staff = data;
      this.spinner.hide();
    });
  }

  initJob(){
    this.PODB_controller.search_PO_withStatusKIV().then(async data=>{
      this.Job = data;
      await this.Job.sort(function(o1,o2){
        return o1.Updated_Date ? -1 : o2.Updated_Date ? 1 : 0;
      });
      this.lengthJob = this.Job.length;
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      this.spinner.hide();
    });

    this.PODB_controller.search_PO_withStatusRejected().then(data=>{
      this.RejectJob = data;
      this.lengthRejectJob = this.RejectJob.length;
      this.CloneRejectJob = this.RejectJob.slice();
      this.limitListRejectJob();
      this.spinner.hide();
    });
  }

  initcus(){
    this.CustomerDB_controller.getCustomerList().then(data => {
      this.Customer = data;
      this.customerlist = data;
      this.lengthcus = this.Customer.length;
      this.CloneCustomer = this.Customer.slice();
      this.matSelectCustomer = this.customerlist.slice();
      this.limitListcus();
      this.spinner.hide();
    });
  }

  deletePO(POnumber) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this PO? This will affect the schedule';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PODB_controller.delete_PO(POnumber, this.machinelist);
        this.setup();
      }
    })

  }

  filterStatus(){
    if(this.search)
      this.DS();
    else
      this.ClonePOList = this.POlist.slice();

    this.ClonePOList =  this.ClonePOList.filter(u =>{
      if((u.Status.includes(this.poStatus)))
        return true;
      return false
    });

    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
  }

  filterStatus2(){
    if(this.poStatus2 == ""){
      this.CloneIncompletePOList = this.IncompletePOList.slice();
    }
    else{
      this.CloneIncompletePOList = this.IncompletePOList.filter(u =>{
        if((u.Status.includes(this.poStatus2)))
          return true;
        return false
      });
    }

    this.monitorlength = this.CloneIncompletePOList.length;
    this.limitmonitorList2();
    this.topmonitorPaginator.firstPage();
    this.bottommonitorPaginator.firstPage();
  }

  filterStaff(){
    if(this.poStaff == ""){
      this.CloneIncompletePOList = this.IncompletePOList.slice();
    }
    else{
      this.CloneIncompletePOList = this.IncompletePOList.filter(u =>{
        if((u.Person_In_Charge.includes(this.poStaff)))
          return true;
        return false
      });
    }

    this.monitorlength = this.CloneIncompletePOList.length;
    this.limitmonitorList2();
    this.topmonitorPaginator.firstPage();
    this.bottommonitorPaginator.firstPage();
  }

  filterCustomer(){
    if(this.poCustomer == ""){
      this.CloneIncompletePOList = this.IncompletePOList.slice();
    }
    else{
      this.CloneIncompletePOList = this.IncompletePOList.filter(u =>{
        if((u.Customer != null) && (u.Customer.includes(this.poCustomer)))
          return true;
        return false
      });
    }

    this.monitorlength = this.CloneIncompletePOList.length;
    this.limitmonitorList2();
    this.topmonitorPaginator.firstPage();
    this.bottommonitorPaginator.firstPage();
  }

  fsmonitor(){
    this.CloneIncompletePOList =  this.IncompletePOList.filter(u =>{
      if((u.Status.includes(this.poStatus)))
        return true;
      return false
    });
  }
  
  fs(){
    this.ClonePOList =  this.ClonePOList.filter(u =>{
      if((u.Status.includes(this.poStatus)))
        return true;
      return false
    });
  }

  DynamicSearchPO(partNum: string): void {
    this.search = partNum;
    this.ClonePOList = this.POlist.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.search.toLowerCase()) || 
      String(u.Customer).toLowerCase().includes(this.search.toLowerCase()));
      return flag
    });
    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage()
    this.bottomPaginator.firstPage()
  }

  DynamicSearchRejectPO(partNum: string): void {
    this.searchRejectJob = partNum;
    this.CloneRejectJob = this.RejectJob.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.searchRejectJob.toLowerCase()) || 
      String(u.Customer).toLowerCase().includes(this.searchRejectJob.toLowerCase()));
      return flag
    });
    this.lengthRejectJob = this.CloneRejectJob.length;
    this.sortRejectJob();
    this.limitListRejectJob();
    this.topPaginatorRejectJob.firstPage()
    this.bottomPaginatorRejectJob.firstPage()
  }

  DynamicSearchKIVPO(partNum: string): void {
    this.searchJob = partNum;
    this.CloneJob = this.Job.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.search.toLowerCase()) || 
      String(u.Customer).toLowerCase().includes(this.search.toLowerCase()));
      return flag
    });
    this.lengthJob = this.CloneJob.length;
    this.sortJob();
    this.limitListJob();
    this.topPaginatorJob.firstPage()
    this.bottomPaginatorJob.firstPage()
  }
  
  DS() {
    this.ClonePOList = this.POlist.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.search.toLowerCase()) || 
      String(u.Customer).toLowerCase().includes(this.search.toLowerCase()));
      return flag
    });
    this.length = this.ClonePOList.length;
  }

  monitorDS() {
    this.CloneIncompletePOList = this.IncompletePOList.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.searchmonitor.toLowerCase()) || 
      String(u.Customer).toLowerCase().includes(this.searchmonitor.toLowerCase()));
      return flag
    });
    this.monitorlength = this.CloneIncompletePOList.length;
  }

  monitorpaginator(pageEvent: PageEvent) {
    this.monitorpageSize = pageEvent.pageSize;
    this.monitorpageIndex = pageEvent.pageIndex;
    this.monitoroffset = this.monitorpageSize * this.monitorpageIndex;

    if (this.topmonitorPaginator.pageIndex < this.monitorpageIndex) {
      this.topmonitorPaginator.nextPage();
    } else if (this.topmonitorPaginator.pageIndex > this.monitorpageIndex) {
      this.topmonitorPaginator.previousPage();
    }

    if (this.bottommonitorPaginator.pageIndex < this.monitorpageIndex) {
      this.bottommonitorPaginator.nextPage();
    } else if (this.bottommonitorPaginator.pageIndex > this.monitorpageIndex) {
      this.bottommonitorPaginator.previousPage();
    }

    if (this.searchmonitor) {
      this.monitorDS();
    } else {
      this.CloneIncompletePOList = this.IncompletePOList.slice();
    }
    
    //this.fsmonitor();
    //this.sortmonitorPOList();
    this.limitmonitorList();
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.ClonePOList = this.POlist.slice();
    }
    
    this.fs();
    this.sortPOList();
    this.limitList();
  }

  limitmonitorList2() {
    this.CloneIncompletePOList = this.CloneIncompletePOList.slice(this.monitoroffset, (this.monitoroffset + this.monitorpageSize));
  }

  limitmonitorList() {
    this.CloneIncompletePOList = this.IncompletePOList.slice(this.monitoroffset, (this.monitoroffset + this.monitorpageSize));
  }

  limitList() {
    this.ClonePOList = this.ClonePOList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortPOList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'PONO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Customer': return this.compare(a.Customer, b.Customer, isAsc);
        case 'AssignedTo': return this.compare(a.Person_In_Charge, b.Person_In_Charge, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePOList = this.POlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      return;
    }

    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'PONO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Customer': return this.compare(a.Customer, b.Customer, isAsc);
        case 'AssignedTo': return this.compare(a.Person_In_Charge, b.Person_In_Charge, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
    this.limitList();
  }

  /*sortJobData(sort: Sort) {
    this.sortedu = sort;
    this.CloneJob = this.POlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePOList = this.POlist.slice();
      this.limitListJob();
      return;
    }

    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sortJob.direction === 'asc';
      switch (this.sortedu.active) {
        case 'PONO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Customer': return this.compare(a.Customer, b.Customer, isAsc);
        case 'AssignedTo': return this.compare(a.Person_In_Charge, b.Person_In_Charge, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
    this.limitListJob();
  }*/

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ViewShippingInformation(part){
    this.dialog.open(ShippingInformationComponent, {
      width: '80%',
      height: '80%',
      data: part
    });
  }

  ViewCheckOut(part){
    this.dialog.open(RawCheckOutInformationComponent, {
      width: '80%',
      height: '80%',
      data: part
    });
  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewOrder(po){
    this.dialog.open(OrdersComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  DynamicSearchRawcus(cusname: string): void {
    this.searchcus = cusname;
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.Customer_Name).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
    this.sortRawListcus();
    this.limitListcus();
    this.topPaginatorcus.firstPage()
    this.bottomPaginatorcus.firstPage()
  }

  DScus() {
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.Customer_Name).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
  }

  paginatorcus(pageEvent: PageEvent) {
    this.pageSizecus = pageEvent.pageSize;
    this.pageIndexcus = pageEvent.pageIndex;
    this.offsetcus = this.pageSizecus * this.pageIndexcus;
    if (this.topPaginatorcus.pageIndex < this.pageIndexcus) {
      this.topPaginatorcus.nextPage();
    } else if (this.topPaginatorcus.pageIndex > this.pageIndexcus) {
      this.topPaginatorcus.previousPage();
    }
    if (this.bottomPaginatorcus.pageIndex < this.pageIndexcus) {
      this.bottomPaginatorcus.nextPage();
    } else if (this.bottomPaginatorcus.pageIndex > this.pageIndexcus) {
      this.bottomPaginatorcus.previousPage();
    }
    if (this.searchcus) {
      this.DScus();
    } else {
      this.CloneCustomer = this.Customer.slice();
    }
    this.sortRawListcus();
    this.limitListcus();
  }

  limitListcus() {
    this.CloneCustomer = this.CloneCustomer.slice(this.offsetcus, (this.offsetcus + this.pageSizecus));
  }

  sortRawListcus() {
    if (!this.sorteducus.active || this.sorteducus.direction === '') {
      return;
    }
    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.Customer_Name, b.Customer_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
  }

  sortDatacus(sort: Sort) {
    this.sorteducus = sort;
    this.CloneCustomer = this.Customer.slice();
    if (this.searchcus) {
      this.DScus();
    }
    if (!sort.active || sort.direction === '' && !this.searchcus) {
      this.CloneCustomer = this.Customer.slice();
      this.limitListcus();
      return;
    }

    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.Customer_Name, b.Customer_Name, isAsc);
        case 'address': return this.compare(a.Address, b.Address, isAsc);
        case 'contact': return this.compare(a.Contact, b.Contact, isAsc);
        case 'email': return this.compare(a.Email, b.Email, isAsc);
        default: return 0;
      }
    });
    this.limitListcus();
  }

  ViewCustomer(Customer: Customer){
    const dialogRef = this.dialog.open(AddCustomerComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: {...Customer}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.update_Customer(result, this.email);
        this.initcus();
        this.setup();
      }
    });
  }

  AddCustomer(){
    const dialogRef = this.dialog.open(AddCustomerComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.add_Customer(result, this.email);
        this.initcus();
        this.setup();
      }
    });
  }
  deletecus(cus : Customer){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this Customer? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.CustomerDB_controller.delete_Customer(cus.Customer_ID);
        this.setup();
      }
    })
  }

  AddPO(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    const position = {
      top: '2%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(AddPOComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    })
  }

  viewDetail(PO){
    const dialogRefupdatePart = this.dialog.open(DetailPOPageComponent, {
      data: PO,
      width: '90%',
      height: '80%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(async result => {
      this.setup();
    });
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();

    for (const po of this.POlist) {
      const info ={
        "PO":po.PO_No || "-",
        "Part List": po.PO_Part_List.map(a=>a.PO_Part_No).join('  |  '),
        "Part List Name": po.PO_Part_List.map(a=>a.PO_Part_Name).join(' |  '),
        "Production Quantity": po.PO_Part_List.map(a=>a.PO_Part_Qty).join(' |  '),
        "PO Quantity": po.PO_Part_List.map(a=>a.POQuantity).join(' |  '),
        "Reference": po.PO_Part_List.map(a=>a.Reference).join(' |  '),
        "POS": po.PO_Part_List.map(a=>a.POS).join(' |  '),
        "Status": po.PO_Part_List.map(a=>a.PO_Status).join(' |  '),
        "Created Date":po.Created_Date
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();

  }

  exportToExcelCustomer() {
    const exportInformation = [];
    this.spinner.show();
    const cuss = this.Customer.sort((a,b)=>{
      return (a.Customer_Name < b.Customer_Name ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const cus of cuss) {
      const info ={
        "Client name":cus.Customer_Name || "-",
        "Address":cus.Address || "-",
        "Contact":cus.Contact || "-",
        "Email":cus.Email || "-",
        "Person In Charge":cus.Person_In_Charge || "-",
        "Created Date":cus.Created_Date || "-",
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client'+new Date().getTime());
    this.spinner.hide();

  }

  msToTime(end,start) {
    let duration = parseFloat(end)-parseFloat(start);
    if(duration > 0){
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hrs = (hours < 10) ? "0" + hours : hours;
      let min = (minutes < 10) ? "0" + minutes : minutes;
      let sec = (seconds < 10) ? "0" + seconds : seconds;
      return hrs + ":" + min + ":" + sec;
    }
    return 0;

  }

  hoursDisplay(hr){
    if(hr > 0){
      let min = hr - parseFloat(hr.toString().split('.')[0])
      min = min * 60
      let sec = min - parseFloat(min.toString().split('.')[0])
      sec = sec * 60 ;
      let hrs1 = (hr < 10) ? "0" + hr.toString().split('.')[0] : hr.toString().split('.')[0];
      let min1 = (min < 10) ? "0" + min.toString().split('.')[0] : min.toString().split('.')[0];
      let sec1 = (sec < 10) ? "0" + sec.toString().split('.')[0] : sec.toString().split('.')[0];
      return hrs1 + ":" + min1 + ":" + sec1;
    }
    return 0;
  }

  DynamicSearchJob(job: string): void {
    this.searchJob = job;
    this.CloneJob = this.Job.filter(u =>
      String(u.PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Customer).toLowerCase().includes(this.searchJob.toLowerCase())
      );
    this.lengthJob = this.CloneJob.length;
    this.sortJob();
    this.limitListJob();
    this.topPaginatorJob.firstPage()
    this.bottomPaginatorJob.firstPage()
  }

  DSJob() {
    this.CloneJob = this.Job.filter(u =>
      String(u.PO_No).toLowerCase().includes(this.searchJob.toLowerCase())
      ||String(u.Customer).toLowerCase().includes(this.searchJob.toLowerCase())
      );
      this.lengthJob = this.CloneJob.length;
  }

  
  DSRejectJob() {
    this.CloneRejectJob = this.RejectJob.filter(u =>
      String(u.PO_No).toLowerCase().includes(this.searchRejectJob.toLowerCase())
      ||String(u.Customer).toLowerCase().includes(this.searchRejectJob.toLowerCase())
      );
      this.lengthRejectJob = this.CloneRejectJob.length;
  }

  paginatorJob(pageEvent: PageEvent) {
    this.pageSizeJob = pageEvent.pageSize;
    this.pageIndexJob = pageEvent.pageIndex;
    this.offsetJob = this.pageSizeJob * this.pageIndexJob;
    if (this.topPaginatorJob.pageIndex < this.pageIndexJob) {
      this.topPaginatorJob.nextPage();
    } else if (this.topPaginatorJob.pageIndex > this.pageIndexJob) {
      this.topPaginatorJob.previousPage();
    }
    if (this.bottomPaginatorJob.pageIndex < this.pageIndexJob) {
      this.bottomPaginatorJob.nextPage();
    } else if (this.bottomPaginatorJob.pageIndex > this.pageIndexJob) {
      this.bottomPaginatorJob.previousPage();
    }
    if (this.searchJob) {
      this.DSJob();
    } else {
      this.CloneJob = this.Job.slice();
    }
    this.sortJob();
    this.limitListJob();
  }

  limitListJob() {
    this.CloneJob = this.CloneJob.slice(this.offsetJob, (this.offsetJob + this.pageSizeJob));
  }

  
  limitListRejectJob() {
    this.CloneRejectJob = this.CloneRejectJob.slice(this.offsetRejectJob, (this.offsetRejectJob + this.pageSizeRejectJob));
  }

  sortRejectJob(){
    if (!this.sorteduRejectJob.active || this.sorteduRejectJob.direction === '') {
      return;
    }
    this.CloneRejectJob = this.CloneRejectJob.sort((a, b) => {
      const isAsc = this.sorteduRejectJob.direction === 'asc';
      switch (this.sorteduRejectJob.active) {
        case 'PONO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Customer': return this.compare(a.Customer, b.Customer, isAsc);
        default: return 0;
      }
    });
  }
  
  sortJob() {
    if (!this.sorteduJob.active || this.sorteduJob.direction === '') {
      return;
    }
    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'PONO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Customer': return this.compare(a.Customer, b.Customer, isAsc);
        default: return 0;
      }
    });
  }

  

  sortDataRejectJob(sort: Sort) {
    this.sorteduRejectJob = sort;
    this.CloneRejectJob = this.Job.slice();
    if (this.searchRejectJob) {
      this.DSRejectJob();
    }
    if (!sort.active || sort.direction === '' && !this.searchRejectJob) {
      this.CloneRejectJob = this.RejectJob.slice();
      this.limitListRejectJob();
      return;
    }

    this.CloneRejectJob = this.CloneRejectJob.sort((a, b) => {
      const isAsc = this.sorteduRejectJob.direction === 'asc';
      switch (this.sorteduRejectJob.active) {
        case 'PONO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Customer': return this.compare(a.Customer, b.Customer, isAsc);
        default: return 0;
      }
    });
    this.limitListRejectJob();
  }

  sortDataJob(sort: Sort) {
    this.sorteduJob = sort;
    this.CloneJob = this.Job.slice();
    if (this.searchJob) {
      this.DSJob();
    }
    if (!sort.active || sort.direction === '' && !this.searchJob) {
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      return;
    }

    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'PONO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Customer': return this.compare(a.Customer, b.Customer, isAsc);
        default: return 0;
      }
    });
    this.limitListJob();
  }

  /*exportToExcelJob() {
    const exportInformation = [];
    this.spinner.show();
    const jo = this.Job.sort((a,b)=>{
      return (Number(a.Machine_Number.match(/(\d+)/g)[0]) - Number((b.Machine_Number.match(/(\d+)/g)[0])));})
    for (const j of jo) {
      const info ={
        "Purchase Order":j.Machine_Schedule_PO_No || "-",
        "Machine":j.Machine_Number || "-",
        "Part No":j.Machine_Schedule_Part_No || "-",
        "Part Name":j.PartName || "-",
        "Cycle Time":j.CycleTime || "-",
        "Start Time":j.DateFormatStart || "-",
        "End Time":j.DateFormatEnd || "-",
        "Presetting Time": this.msToTime(j.DatePresettingEnd,j.DatePresettingStart) || "-",
        "Downtime Time":this.hoursDisplay(j.TotalDownTime) || "-",

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client'+new Date().getTime());
    this.spinner.hide();

  }*/

  view(job:PurchaseOrder){
    this.dialog.open(DowntimeDetailsComponent, {
      width: '80%',
      height: 'auto',
      data: job,
      disableClose:true
    });
  }

  viewDrawing(url){
    window.open(url, '_blank');
  }

  validate(s){
    return typeof(s) === 'string'? true:false;
  }

  /*printDrawing(po: PurchaseOrder){
    var printWindow = window.open('', '', 'height=1109,width=1425');
    printWindow.document.write('<html><head><style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse;} .table th, .table td {padding: 0.70rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;} .col-md-3{position: relative;width: 25%;} .col-md-9{position: relative;width: 75%;} .col-md-7{position: relative;width: 58.333333%;} .col-md-5{position: relative;width: 41.666667%;} .col-md-6{position: relative;width: 50%;} .col-md-4{position: relative;width: 33.333333%;} .col-md-8{position: relative;width: 66.666667%;}</style></head><body>');
    printWindow.document.write('<div class="row"><div class="col-md-3"><br><img src="https://firebasestorage.googleapis.com/v0/b/hl-best.appspot.com/o/logo.png?alt=media&token=150eae00-9a52-454a-a58e-9f791e142053" height="auto" width="80%" style="padding-left: 70%;"/></div><div class="col-md-9"><div style="text-align: center;"><p><span style="font-size: 20px;font-family: sans-serif;font-weight: bold;">HL BEST FORKLIFT SDN. BHD.</span><span>&nbsp;&nbsp;(1000089-T)</span><br><span style="font-size: 14px;font-family: sans-serif;">No. 13A, Lot 23A, Kuala Neutron, Lorong Kuala Neutron,</span><br><span style="font-size: 14px;font-family: sans-serif;">Jalan Pintas Ujana, KM 1, Jalan Tuaran By-Pass,</span><br><span style="font-size: 14px;font-family: sans-serif;">88450 Kota Kinabalu, Sabah.</span><br><span style="font-size: 14px;font-family: sans-serif;">Tel: 088 388 878   Fax: 088 389 887</span><br><span style="font-size: 14px;font-family: sans-serif;">Email: sales@hlbest-forklift.com.my</span><br></p></div></div></div><hr>');
    printWindow.document.write('<div class="row"><div class="col-md-9"><p style="text-align: right"><span style="font-size: 16px;font-family: sans-serif;font-weight: bold;padding-right: 15%;"><u>SERVICE AND REPAIR REPORT</u></span></p></div><div class="col-md-3"><p style="text-align: right"><span style="font-size: 16px;font-family: sans-serif;font-weight: bold;">No.: '+po.PO_No+'</span></p></div></div><p style="text-align: right"><span style="font-size: 16px;font-family: sans-serif;">DATE: </span><span style="font-size: 16px;font-family: sans-serif;"><u>' + this.dateService.convertDateIntoISO8601(po.Serviced_Date) +'</u></span></p><br><p>');
    printWindow.document.write('<span style="font-size: 16px;font-family: sans-serif;">CUSTOMER NAME: </span><span style="font-size: 16px;font-family: sans-serif;"><u>' + po.Customer +'</u></span></p><p><span style="font-size: 16px;font-family: sans-serif;">ADDRESS: </span><span style="font-size: 12px;font-family: sans-serif;"><u>' + po.Delivery_Address + '</u></span></p><div class="row" style="margin-top: 0px"><div class="col-md-4"><p style="margin-top: 0px"><span style="font-size: 16px;font-family: sans-serif;">MACHINE TYPE: </span><span style="font-size: 16px;font-family: sans-serif;"><u>' + po.Serviced_MC_Type + '</u></span></p></div><div class="col-md-4"><p style="margin-top: 0px;text-align:right;"><span style="font-size: 16px;font-family: sans-serif;">MODEL: </span><span style="font-size: 16px;font-family: sans-serif;"><u>'+ po.Serviced_Model + '</u></span></p></div>'); 
    printWindow.document.write('<div class="col-md-4"><p style="margin-top: 0px;text-align:right;"><span style="font-size: 16px;font-family: sans-serif;">SERIAL NO: </span><span style="font-size: 16px;font-family: sans-serif;"><u>' + po.Serviced_Serial_No + '</u></span></p></div></div><div class="row" style="margin-top: 0px;"><div class="col-md-7"><p style="margin-top: 0px;"><span style="font-size: 16px;font-family: sans-serif;">PRODUCTION TIME START: </span><span style="font-size: 12px;font-family: sans-serif;"><u>'+ this.dateService.convertDateTime(po.Quotation_Date) +'</u></span></p></div> <div class="col-md-5"><p style="margin-top: 0px;text-align:right;"><span style="font-size: 16px;font-family: sans-serif;">TIME END: </span><span style="font-size: 12px;font-family: sans-serif;"><u>'+ this.dateService.convertDateTime(po.Serviced_Date) +'</u></span></p></div></div>');
    printWindow.document.write('<p style="text-align: right;margin-top: 0px;"><span style="font-size: 16px;font-family: sans-serif;">QUOTATION NO.: </span><span style="font-size: 16px;font-family: sans-serif;"><u>'+ po.Quotation +'</u></span></p><br><p><span style="font-size: 16px;font-family: sans-serif;">Remark: </span><br><span style="font-size: 16px;font-family: sans-serif;"><u>'+ po.Serviced_Remark + '</u></span></p><br><br><br>');
    printWindow.document.write('<table class="table"><tbody><tr><td style="width: 50%;border-top:0px;text-align: center;font-size: 30;">' + po.Serviced_By.split("@")[0] + '</td><td style="width: 50%;border-top:0px;text-align:center;"><img src="' + po.Signature + '" height="auto" width="30%"/></td></tr><tr><td style="width: 50%;border-top:0px;"><hr></td><td style="width: 50%;border-top:0px;"><hr><p>PIC. Name: ' + po.Pic_Name + ' <br>PIC. IC: '+ po.Pic_IC + ' </p></td></tr></tbody></table></body></html>')
    printWindow.document.close();
    setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 500);
  }*/

  //Pagination
  lengthPL = 0;
  pageSizePL = 10;
  pageSizeOptionsPL: number[] = [10, 25, 50];
  pageIndexPL = 0;
  offsetPL = this.pageSizePL * this.pageIndexPL;
  searchPL: string;
  sorteduPL = {
    active: '',
    direction: ''
  };

  ClonePackingPOList: PackingList_PO_List[] = [];
  ClonePackingList: PackingList[] = [];

  @ViewChild('topPaginatorPL', { read: MatPaginator, static: false }) topPaginatorPL: MatPaginator;
  @ViewChild('bottomPaginatorPL', { read: MatPaginator, static: false }) bottomPaginatorPL: MatPaginator;

  packingFormat: any = /^PKG\d{3}-\d{6}/;
  tempPackingNo: string = "testing";
  packing_controller = new PackingListDB_controller(this.db,this.firestore);
  packingPartList: PackingList_PO_Part_List[] = [];
  packingPOList: PackingList_PO_List[] = [];
  packingList: PackingList[] = [];

  addPacking() {
    this.generatePackingNumber();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.tempPackingNo;
    this.dialog.open(AddPackingComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    });
  }
  generatePackingNumber() {
    if (this.packingList.length > 0) {
      const clone = this.packingList.filter(p => p.PackingList_No.includes('PKG'));
      let result = null
      if (clone.length > 0) {
        result = clone[clone.length - 1].PackingList_No.match(this.packingFormat);
      }
      let mid3entries = "000";
      let last6entries = "000000";
      if (result) {
        let last6digits = parseInt(result[0].substring(7, 13));
        if (last6digits < 999999) {
          last6digits = last6digits + 1;
          if (last6digits < 10) {
            last6entries = "00000" + last6digits.toString();
          }
          else if (last6digits < 100) {
            last6entries = "0000" + last6digits.toString();
          }
          else if (last6digits < 1000) {
            last6entries = "000" + last6digits.toString();
          }
          else if (last6digits < 10000) {
            last6entries = "00" + last6digits.toString();
          }
          else if (last6digits < 100000) {
            last6entries = "0" + last6digits.toString();
          }
          else if (last6digits < 1000000) {
            last6entries = last6digits.toString();
          }

          let mid3digits = parseInt(result[0].substring(3, 6));
          if (last6digits == 999999) {
            if (mid3digits < 999) {
              mid3digits = mid3digits + 1;
              if (mid3digits < 10) {
                mid3entries = "00" + mid3digits.toString();
              }
              else if (mid3digits < 100) {
                mid3entries = "0" + mid3digits.toString();
              }
              else if (mid3digits < 1000) {
                mid3entries = mid3digits.toString();
              }
            }
          }
        }
        this.tempPackingNo = "PKG" + mid3entries + "-" + last6entries;
      }
      else {
        this.tempPackingNo = "PKG000-000000";
      }
    }
    else {
      this.tempPackingNo = "PKG000-000000";
    }
  }

  DynamicSearchPL(searchValue: string): void {
    this.searchPL = searchValue;

    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.searchPL.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.searchPL.toLowerCase()))
          return true;
      }
      return flag
    });


    this.lengthPL = this.ClonePackingList.length;
    this.sortPackingList();
    this.limitPackingList();
    this.topPaginatorPL.firstPage()
    this.bottomPaginatorPL.firstPage()
  }

  DS1() {
    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.searchPL.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.searchPL.toLowerCase()))
          return true;
      }
      return flag
    });

    this.lengthPL = this.ClonePackingList.length;
  }

  paginator1(pageEvent: PageEvent) {
    this.pageSizePL = pageEvent.pageSize;
    this.pageIndexPL = pageEvent.pageIndex;
    this.offsetPL = this.pageSizePL * this.pageIndexPL;
    if (this.topPaginatorPL.pageIndex < this.pageIndexPL) {
      this.topPaginatorPL.nextPage();
    } else if (this.topPaginatorPL.pageIndex > this.pageIndexPL) {
      this.topPaginatorPL.previousPage();
    }
    if (this.bottomPaginatorPL.pageIndex < this.pageIndexPL) {
      this.bottomPaginatorPL.nextPage();
    } else if (this.bottomPaginatorPL.pageIndex > this.pageIndexPL) {
      this.bottomPaginatorPL.previousPage();
    }
    if (this.searchPL) {
      this.DS1();
    } else {
      this.ClonePackingList = this.packingList.slice();
    }
    this.sortPackingList();
    this.limitPackingList();
  }

  limitPackingList() {
    this.ClonePackingList = this.ClonePackingList.slice(this.offsetPL, (this.offsetPL + this.pageSizePL));
  }

  sortPackingList() {
    if (!this.sorteduPL.active || this.sorteduPL.direction === '') {
      return;
    }
    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sorteduPL.direction === 'asc';
      switch (this.sorteduPL.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.customerID, b.customerID, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
  }

  sortPackingData(sort: Sort) {
    this.sorteduPL = sort;
    this.ClonePackingList = this.packingList.slice();
    if (this.searchPL) {
      this.DS1();
    }
    if (!sort.active || sort.direction === '' && !this.searchPL) {
      this.ClonePackingList = this.packingList.slice();
      this.limitPackingList();
      return;
    }

    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sorteduPL.direction === 'asc';
      switch (this.sorteduPL.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.Customer, b.Customer, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
    this.limitPackingList();
  }

  exportToExcelPL() {
    const exportInformation = [];
    this.spinner.show();

    for (const p of this.packingList) {
      const info ={
        "PackingList No":p.PackingList_No || "-",
        "Customer":p.Customer || "-",
        "Destination":p.Destination || "-",
        "Shipping Date":p.Shipping_Date || "-",
        "Created By":p.Created_By,
        "Created Date":p.Created_Date,
        "Purchase Order List":p.PackingList_PO_List.map(a=>a.PO_No +' : '+ a.PackingList_PO_Part_List.map(b=>b.Part_No).join(',')).join('   |   '),

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();

  }

  GenerateLabel() {
    this.dialog.open(GenerateQRCodeDialogComponent, {
      width: '80%',
      height: '80%'
    });

  }

  GenerateOldLabel() {
    this.dialog.open(GenerateOldLabelComponent, {
      width: '80%',
      height: 'auto'
    });

  }

  deletePacking(packing: PackingList) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Delete this Packing?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.packing_controller.delete_PackingList(packing);
        this.setup();
      }
    })

  }

  viewPackingDetail(packingNumber) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      seePacking: packingNumber,
    };
    this.dialog.open(UpdatePackingComponent, dialogConfig).afterClosed().subscribe(result => {
      setTimeout(() => {
        this.setup();
      }, 1000);
    });
  }

  getColorPanel(po:PurchaseOrder, index:any){
    if(po.Status.includes('Checking')){
      return 'running';
    }
    else if(po.Status.includes('Quotation')){
      return 'warning';
    }
    else if(po.Status.includes('Servicing')){
      return 'off';
    }
    else if(po.Status.includes('Invoicing')){
      return 'error';
    }
  }

  UploadCustomer(){
    const dialogRefupdatePart = this.dialog.open(UploadExcelDialogComponent, {
      width: '80%',
      height: '80%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(data => {
      if (data){
        this.spinner.show();

        data.forEach(async data2 => {
          var snapshot = await this.db.database.ref('Customer/' + data2['ACC. CODE']).once('value');
  
          if (!snapshot.exists()) {
            const sup = new Customer();
            sup.Customer_ID = data2['ACC. CODE'] || uuidv4();
            sup.Customer_Name = data2['NAME'] || "-";
            sup.Person_In_Charge = data2['P.I.C'] || "-";
            sup.Address = data2['ADDRESS'] || "";
            sup.Contact = data2['Phone 1'] || "";
            sup.Contact2 = data2['Phone 2'] || "";
            sup.Contact3 = data2['Phone 3'] || "";
            sup.Email = data2['Email'] || "-";
            sup.Email2 = data2['Email 2'] || "-";
            sup.Email3 = data2['Email 3'] || "-";
            this.CustomerDB_controller.add_Customer(sup, this.email);
          }
        });
        
        this.spinner.hide();
        this.setup();
      }
    });
  }

  UploadSO(){
    const dialogRefupdatePart = this.dialog.open(UploadExcelDialogComponent, {
      width: '80%',
      height: '80%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(data => {
      if (data){
        this.spinner.show();

        data.forEach(async data2 => {
          if(data2['Doc. No.'] != null){
            var snapshot = await this.db.database.ref('Purchase Order/' + data2['Doc. No.']).once('value');
  
            if (!snapshot.exists()) {
              const addPOModel = {
                POName : data2['Doc. No.'],
                poNumber: data2['Shipping Info'] || "",
                CusName : data2['Debtor Name'],
                staffName : data2['Agent'],
                parts: []
              };

              await data.forEach(data3 => {
                if(data3['Doc. No.'] == data2['Doc. No.']){
                  var partNo = data3['Detail Description'].substring(0, data3['Detail Description'].indexOf(' '));
                  var partName = '-';
                  
                  if(data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1) != null 
                  && data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1) != '' ){
                    partName = data3['Detail Description'].substring(data3['Detail Description'].indexOf(' ') + 1);
                  }

                  const info = {
                    partNumber: partNo,
                    partName: partName,
                    id: uuidv4(),
                    poquantity: data3['Qty'],
                    startDate: new Date().toISOString(),
                    endDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString()
                  };

                  addPOModel.parts.push(info);
                }
              });

              await this.PODB_controller.addPOWithoutSchedule(addPOModel,this.email);
            }
          }
        });

        this.spinner.hide();
        this.setup();
      }
    });
  }

  async generatereport(cr: string) {
    var res = new Request('POST', 'https://speedjin.com/hlbest/sendreport.php');
    res.addHeader("Access-Control-Allow-Origin", "*");
    res.addHeader("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT");
    res.addHeader("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization");

    res.sendJSON({crNo: cr}, (err, response) => {
      if (err) throw err
      console.log('got response', response)
    });
  }

  // Receive user input and send to search method**
  onKey(value) { 
    debugger;
    let filter = value.toLowerCase();
    return this.Customer.filter(option => option.Customer_Name.toLowerCase().includes(filter));
  }

  onValueChanged(cus) {
    if (! this.customerlist) { return; }
    const search = cus.value;
    this.matSelectCustomer =  this.customerlist.filter(p => p.Customer_Name.toLowerCase().includes(search.toLowerCase()));
  }
}

